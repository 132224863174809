var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "hori-timeline mt-2" },
            [
              _c(
                "carousel",
                {
                  staticClass: "events navs-carousel",
                  attrs: {
                    id: "timeline-carousel",
                    perPageCustom: [
                      [240, 1],
                      [768, 2],
                      [1024, 4],
                    ],
                  },
                },
                [
                  _c("slide", [
                    _c("div", { staticClass: "item event-list active" }, [
                      _c("div", [
                        _c("div", { staticClass: "event-date" }, [
                          _c("h5", { staticClass: "mb-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "uploadProcessStep2.infoCard.point1.title"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "event-down-number" }, [
                          _c("i", {
                            staticClass:
                              "mdi mdi-numeric-1-circle h1 text-warning down-number-icon",
                          }),
                        ]),
                        _c("div", { staticClass: "mt-3 px-3" }, [
                          _c("p", { staticClass: "text-muted" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "uploadProcessStep2.infoCard.point1.description"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("slide", [
                    _c("div", { staticClass: "item event-list active" }, [
                      _c("div", [
                        _c("div", { staticClass: "event-date" }, [
                          _c("h5", { staticClass: "mb-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "uploadProcessStep2.infoCard.point2.title"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "event-down-number" }, [
                          _c("i", {
                            staticClass:
                              "mdi mdi-numeric-2-circle h1 text-warning down-number-icon",
                          }),
                        ]),
                        _c("div", { staticClass: "mt-3 px-3" }, [
                          _c("p", { staticClass: "text-muted" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "uploadProcessStep2.infoCard.point2.description"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("slide", [
                    _c("div", { staticClass: "item event-list active" }, [
                      _c("div", [
                        _c("div", { staticClass: "event-date" }, [
                          _c("h5", { staticClass: "mb-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "uploadProcessStep2.infoCard.point3.title"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "event-down-number" }, [
                          _c("i", {
                            staticClass:
                              "mdi mdi-numeric-3-circle h1 text-warning down-number-icon",
                          }),
                        ]),
                        _c("div", { staticClass: "mt-3 px-3" }, [
                          _c("p", { staticClass: "text-muted" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "uploadProcessStep2.infoCard.point3.description"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("slide", [
                    _c("div", { staticClass: "item event-list active" }, [
                      _c("div", [
                        _c("div", { staticClass: "event-date" }, [
                          _c("h5", { staticClass: "mb-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "uploadProcessStep2.infoCard.point4.title"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "event-down-number" }, [
                          _c("i", {
                            staticClass:
                              "mdi mdi-numeric-4-circle h1 text-warning down-number-icon",
                          }),
                        ]),
                        _c("div", { staticClass: "mt-3 px-3" }, [
                          _c("p", { staticClass: "text-muted" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "uploadProcessStep2.infoCard.point4.description"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-lg-12" },
                [
                  _c(
                    "b-alert",
                    {
                      staticClass: "mt-5 text-center",
                      attrs: { variant: "info" },
                      model: {
                        value: _vm.displayIDInfo,
                        callback: function ($$v) {
                          _vm.displayIDInfo = $$v
                        },
                        expression: "displayIDInfo",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("uploadProcessStep2.infoCard.IdColumnRequired")
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }