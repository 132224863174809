var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.$t("uploadProcessStep2.pageTitle"),
          items: _vm.items,
        },
      }),
      this.isDisplayLoading
        ? _c("div", { staticClass: "row mb-3 mt-3" }, [
            _c(
              "div",
              { staticClass: "col-md-12 text-center" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _c(
            "div",
            [
              _c("uploadInstructionCard"),
              this.selectedColumns.length > 0
                ? _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(
                      this.selectedColumns,
                      function (column, columnIndex) {
                        return _c(
                          "div",
                          { key: columnIndex, staticClass: "col-md-12 mb-3" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "card",
                                style: [
                                  column.isEnebleColumnEditing
                                    ? { background: "#FFFFFF" }
                                    : { background: "#edfaee" },
                                ],
                              },
                              [
                                _c("div", { staticClass: "card-body" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6" },
                                      [
                                        _c(
                                          "b-badge",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                            },
                                            attrs: { variant: "primary" },
                                          },
                                          [
                                            column.columnType ===
                                            _vm.columnTypesEnum
                                              .PROJECTIVE_QUESTION
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "uploadProcessStep2.columnTypes.projectiveQuestion"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            column.columnType ===
                                            _vm.columnTypesEnum.RESPONDENT_ID
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "uploadProcessStep2.columnTypes.dataRecordID"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            column.columnType ===
                                            _vm.columnTypesEnum.TIMESTAMP
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "uploadProcessStep2.columnTypes.timeStamp"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            column.columnType ===
                                            _vm.columnTypesEnum.ITEMBATTERY
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "uploadProcessStep2.columnTypes.itemBattery"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            column.columnType ===
                                            _vm.columnTypesEnum.OPEN_ENDED
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "uploadProcessStep2.columnTypes.openEnded"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        !column.isEnebleColumnEditing
                                          ? _c("i", {
                                              staticClass:
                                                "ml-2 bx bxs-check-circle text-success bx-tada",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "col-md-6" }, [
                                      _c(
                                        "div",
                                        { staticClass: "float-right" },
                                        [
                                          column.columnSaved &&
                                          column.columnType !== "RESPONDENT_ID"
                                            ? _c("i", {
                                                staticClass:
                                                  "bx bxs-message-square-edit text-warning pointer",
                                                staticStyle: {
                                                  "font-size": "25px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editSelectedColumn(
                                                      columnIndex
                                                    )
                                                  },
                                                },
                                              })
                                            : _c("i", {
                                                staticClass:
                                                  "bx bxs-message-square-x text-danger pointer",
                                                staticStyle: {
                                                  "font-size": "25px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeSelectedColumn(
                                                      columnIndex
                                                    )
                                                  },
                                                },
                                              }),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "row mt-3" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "uploadProcessStep2.form.general.selectColumnType.label"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "text-danger" },
                                              [_vm._v("*")]
                                            ),
                                          ]),
                                          column.columnType
                                            ? _c(
                                                "select",
                                                {
                                                  staticClass: "form-control",
                                                  staticStyle: {
                                                    background: "#f5f5f5",
                                                    "-webkit-appearance":
                                                      "none",
                                                    "-moz-appearance": "none",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    { attrs: { selected: "" } },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getTranslationByName(
                                                              column.columnType,
                                                              _vm.columnTypes
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _c("multiselect", {
                                                attrs: {
                                                  options: _vm.columnTypes,
                                                  placeholder: _vm.$t(
                                                    "uploadProcessStep2.form.general.selectColumnType.placeholder"
                                                  ),
                                                  "custom-label":
                                                    _vm.columnTypeOptions,
                                                  "track-by": "name",
                                                  searchable: false,
                                                  label: "name",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.selectColumnType(
                                                      columnIndex
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "singleLabel",
                                                      fn: function ({
                                                        option,
                                                      }) {
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getTranslationByKey(
                                                                  option.name
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    column.columnTypeObject,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      column,
                                                      "columnTypeObject",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "column.columnTypeObject",
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    column.columnType &&
                                    column.columnType ===
                                      _vm.columnTypesEnum.RESPONDENT_ID
                                      ? _c("div", { staticClass: "col-md-8" }, [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-md-8",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "uploadProcessStep2.form.general.selectColumn.label"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                              },
                                                              [_vm._v("*")]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-md-4 text-right",
                                                          },
                                                          [
                                                            column.datasetColumnName &&
                                                            column.isEnebleColumnEditing
                                                              ? _c(
                                                                  "b-badge",
                                                                  {
                                                                    staticClass:
                                                                      "pointer",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "12px",
                                                                      },
                                                                    attrs: {
                                                                      variant:
                                                                        "light",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeSpecifiedDataRecordIDColumn(
                                                                            columnIndex
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "bx bx-sync pointer mr-1",
                                                                    }),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "uploadProcessStep2.form.general.resetButton"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    column.datasetColumnName
                                                      ? _c(
                                                          "select",
                                                          {
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              background:
                                                                "#f5f5f5",
                                                              "-webkit-appearance":
                                                                "none",
                                                              "-moz-appearance":
                                                                "none",
                                                            },
                                                            style: [
                                                              column
                                                                .validationError
                                                                .isDisplayValidationError
                                                                ? {
                                                                    border:
                                                                      "1px solid #f46a6a",
                                                                  }
                                                                : {
                                                                    border:
                                                                      _vm.none,
                                                                  },
                                                            ],
                                                            attrs: {
                                                              disabled:
                                                                column.datasetColumnName,
                                                            },
                                                          },
                                                          [
                                                            _c("option", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  column.datasetColumnName
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _c("multiselect", {
                                                          attrs: {
                                                            options:
                                                              _vm.workingMetaDataArray,
                                                            placeholder: _vm.$t(
                                                              "uploadProcessStep2.form.general.selectColumn.placeholder"
                                                            ),
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectDatasetColumn(
                                                                columnIndex
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              column.datasetColumnName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                column,
                                                                "datasetColumnName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "column.datasetColumnName",
                                                          },
                                                        }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            !column.columnSaved &&
                                            column.datasetColumnName
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-md-6 align-self-center",
                                                  },
                                                  [
                                                    !column.validationInProgress
                                                      ? _c(
                                                          "b-button",
                                                          {
                                                            staticClass: "mt-2",
                                                            attrs: {
                                                              type: "submit",
                                                              variant:
                                                                "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleClickOnSaveColumn(
                                                                  columnIndex
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "uploadProcessStep2.form.general.saveButton"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("i", {
                                                              staticClass:
                                                                "bx bx-check text-success bx-tada",
                                                            }),
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass: "mt-3",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "mr-2 bx bx-loader-circle bx-spin",
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "uploadProcessStep2.form.general.validationInProgress"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]),
                                          column.validationError
                                            .isDisplayValidationError
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                _vm._l(
                                                  column.validationError.errors,
                                                  function (error, errorIndex) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: errorIndex,
                                                        staticClass:
                                                          "col-md-12",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " * " +
                                                                _vm._s(
                                                                  _vm.getTransationByErrorCode(
                                                                    error.validationErrorCode
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          column.validationError
                                            .displayServerError
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "col-md-12",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getTransationByErrorCode(
                                                                  _vm
                                                                    .errorCodesEnum
                                                                    .SERVER_ERROR
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    column.columnType &&
                                    column.columnType ===
                                      _vm.columnTypesEnum.TIMESTAMP
                                      ? _c("div", { staticClass: "col-md-8" }, [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-md-8",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "uploadProcessStep2.form.general.selectColumn.label"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                              },
                                                              [_vm._v("*")]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-md-4 text-right",
                                                          },
                                                          [
                                                            column.datasetColumnName &&
                                                            column.isEnebleColumnEditing
                                                              ? _c(
                                                                  "b-badge",
                                                                  {
                                                                    staticClass:
                                                                      "pointer",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "12px",
                                                                      },
                                                                    attrs: {
                                                                      variant:
                                                                        "light",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.clickRemoveMainTimestampColumn(
                                                                            columnIndex
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "bx bx-sync pointer mr-1",
                                                                    }),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "uploadProcessStep2.form.general.resetButton"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    column.datasetColumnName
                                                      ? _c(
                                                          "select",
                                                          {
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              background:
                                                                "#f5f5f5",
                                                              "-webkit-appearance":
                                                                "none",
                                                              "-moz-appearance":
                                                                "none",
                                                            },
                                                            style: [
                                                              column
                                                                .validationError
                                                                .isDisplayValidationError
                                                                ? {
                                                                    border:
                                                                      "1px solid #f46a6a",
                                                                  }
                                                                : {
                                                                    border:
                                                                      _vm.none,
                                                                  },
                                                            ],
                                                            attrs: {
                                                              disabled:
                                                                column.datasetColumnName,
                                                            },
                                                          },
                                                          [
                                                            _c("option", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  column.datasetColumnName
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _c("multiselect", {
                                                          attrs: {
                                                            options:
                                                              _vm.workingMetaDataArray,
                                                            placeholder: _vm.$t(
                                                              "uploadProcessStep2.form.general.selectColumn.placeholder"
                                                            ),
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectDatasetColumn(
                                                                columnIndex
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              column.datasetColumnName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                column,
                                                                "datasetColumnName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "column.datasetColumnName",
                                                          },
                                                        }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            !column.columnSaved &&
                                            column.datasetColumnName &&
                                            !column.clickedContinueOne
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-md-6 align-self-center",
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass: "mt-2",
                                                        attrs: {
                                                          type: "submit",
                                                          variant: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.clickedContinueOneTimestamp(
                                                              columnIndex
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "uploadProcessStep2.form.general.continueButton"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("i", {
                                                          staticClass:
                                                            "bx bx-right-arrow-alt text-success bx-tada",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]),
                                          column.validationError
                                            .isDisplayValidationError
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                _vm._l(
                                                  column.validationError.errors,
                                                  function (error, errorIndex) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: errorIndex,
                                                        staticClass:
                                                          "col-md-12",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " * " +
                                                                _vm._s(
                                                                  _vm.getTransationByErrorCode(
                                                                    error.validationErrorCode
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    column.columnType &&
                                    column.columnType ===
                                      _vm.columnTypesEnum.PROJECTIVE_QUESTION
                                      ? _c("div", { staticClass: "col-md-8" }, [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-md-8",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "uploadProcessStep2.form.general.selectColumn.label"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                              },
                                                              [_vm._v("*")]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-md-4 text-right",
                                                          },
                                                          [
                                                            column.datasetColumnName &&
                                                            column.isEnebleColumnEditing
                                                              ? _c(
                                                                  "b-badge",
                                                                  {
                                                                    staticClass:
                                                                      "pointer",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "12px",
                                                                      },
                                                                    attrs: {
                                                                      variant:
                                                                        "light",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.clickRemoveVoteColumn(
                                                                            columnIndex
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "bx bx-sync pointer mr-1",
                                                                    }),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "uploadProcessStep2.form.general.resetButton"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    column.datasetColumnName
                                                      ? _c(
                                                          "select",
                                                          {
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              background:
                                                                "#f5f5f5",
                                                              "-webkit-appearance":
                                                                "none",
                                                              "-moz-appearance":
                                                                "none",
                                                            },
                                                            style: [
                                                              column
                                                                .validationError
                                                                .isDisplayValidationError
                                                                ? {
                                                                    border:
                                                                      "1px solid #f46a6a",
                                                                  }
                                                                : {
                                                                    border:
                                                                      _vm.none,
                                                                  },
                                                            ],
                                                            attrs: {
                                                              disabled:
                                                                column.datasetColumnName,
                                                            },
                                                          },
                                                          [
                                                            _c("option", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  column.datasetColumnName
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _c("multiselect", {
                                                          attrs: {
                                                            options:
                                                              _vm.workingMetaDataArray,
                                                            placeholder: _vm.$t(
                                                              "uploadProcessStep2.form.general.selectColumn.placeholder"
                                                            ),
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectDatasetColumn(
                                                                columnIndex
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              column.datasetColumnName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                column,
                                                                "datasetColumnName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "column.datasetColumnName",
                                                          },
                                                        }),
                                                  ],
                                                  1
                                                ),
                                                column.validationError
                                                  .isDisplayValidationError
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      _vm._l(
                                                        column.validationError
                                                          .errors,
                                                        function (
                                                          error,
                                                          errorIndex
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: errorIndex,
                                                              staticClass:
                                                                "col-md-12",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-danger",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " * " +
                                                                      _vm._s(
                                                                        _vm.getTransationByErrorCode(
                                                                          error.validationErrorCode
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            !column.columnSaved &&
                                            column.datasetColumnName &&
                                            !column.clickedContinueOne
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-md-6 align-self-center",
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass: "mt-2",
                                                        attrs: {
                                                          type: "submit",
                                                          variant: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.clickedContinueOneQuestion(
                                                              columnIndex
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "uploadProcessStep2.form.general.continueButton"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("i", {
                                                          staticClass:
                                                            "bx bx-right-arrow-alt text-success bx-tada",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    column.columnType &&
                                    column.columnType ===
                                      _vm.columnTypesEnum.OPEN_ENDED
                                      ? _c(
                                          "div",
                                          { staticClass: "col-md-4 mt-3 ml-3" },
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  disabled:
                                                    !column.isEnebleColumnEditing,
                                                  name: "check-button",
                                                  switch: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.isEnableWrongTopicCheck,
                                                  callback: function ($$v) {
                                                    _vm.isEnableWrongTopicCheck =
                                                      $$v
                                                  },
                                                  expression:
                                                    "isEnableWrongTopicCheck",
                                                },
                                              },
                                              [
                                                _c("label", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-600",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "uploadProcessStep2.form.OES.wrongTopic.checkbox.label"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _c("i", {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover",
                                                          value:
                                                            _vm.propoverMethodWrongTopicEnable,
                                                          expression:
                                                            "propoverMethodWrongTopicEnable",
                                                          modifiers: {
                                                            hover: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "ml-2 bx bxs-info-circle text-info",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    }),
                                                  ]),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                attrs: {
                                                  disabled:
                                                    !column.isEnebleColumnEditing,
                                                  name: "check-button",
                                                  switch: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.enableDuplicateChecks,
                                                  callback: function ($$v) {
                                                    _vm.enableDuplicateChecks =
                                                      $$v
                                                  },
                                                  expression:
                                                    "enableDuplicateChecks",
                                                },
                                              },
                                              [
                                                _c("label", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-600",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "uploadProcessStep2.form.OES.duplicateChecks.checkBox"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _c("i", {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover",
                                                          value:
                                                            _vm.popoverMethodDuplicateChecks,
                                                          expression:
                                                            "popoverMethodDuplicateChecks",
                                                          modifiers: {
                                                            hover: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "ml-2 bx bxs-info-circle text-info",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    }),
                                                  ]),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  column.columnType ===
                                  _vm.columnTypesEnum.OPEN_ENDED
                                    ? _c("div", { staticClass: "mt-3" }, [
                                        _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-md-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        { staticClass: "row" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-md-8",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "uploadProcessStep2.form.OES.additionalOpenEndedColumn.label"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-danger",
                                                                },
                                                                [_vm._v("*")]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-md-4 text-right",
                                                            },
                                                            [
                                                              column.datasetColumnName &&
                                                              column.isEnebleColumnEditing
                                                                ? _c(
                                                                    "b-badge",
                                                                    {
                                                                      staticClass:
                                                                        "pointer",
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                      attrs: {
                                                                        variant:
                                                                          "light",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.clickRemoveMainOpenEndedColumn(
                                                                              columnIndex
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "bx bx-sync pointer mr-1",
                                                                      }),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "uploadProcessStep2.form.general.resetButton"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      column.datasetColumnName
                                                        ? _c(
                                                            "select",
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              staticStyle: {
                                                                background:
                                                                  "#f5f5f5",
                                                                "-webkit-appearance":
                                                                  "none",
                                                                "-moz-appearance":
                                                                  "none",
                                                              },
                                                              style: [
                                                                column
                                                                  .validationError
                                                                  .isDisplayValidationError
                                                                  ? {
                                                                      border:
                                                                        "1px solid #f46a6a",
                                                                    }
                                                                  : {
                                                                      border:
                                                                        _vm.none,
                                                                    },
                                                              ],
                                                              attrs: {
                                                                disabled:
                                                                  column.datasetColumnName,
                                                              },
                                                            },
                                                            [
                                                              _c("option", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    column.datasetColumnName
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _c("multiselect", {
                                                            attrs: {
                                                              options:
                                                                _vm.workingMetaDataArray,
                                                              placeholder:
                                                                _vm.$t(
                                                                  "uploadProcessStep2.form.general.selectColumn.placeholder"
                                                                ),
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectDatasetColumn(
                                                                  columnIndex
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                column.datasetColumnName,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    column,
                                                                    "datasetColumnName",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "column.datasetColumnName",
                                                            },
                                                          }),
                                                      column.validationError
                                                        .isDisplayValidationError
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                            },
                                                            _vm._l(
                                                              column
                                                                .validationError
                                                                .errors,
                                                              function (
                                                                error,
                                                                errorIndex
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: errorIndex,
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " * " +
                                                                            _vm._s(
                                                                              _vm.getTransationByErrorCode(
                                                                                error.validationErrorCode
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-4" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "uploadProcessStep2.form.OES.questionTitle.label"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _c("span", [
                                                        _c("i", {
                                                          directives: [
                                                            {
                                                              name: "b-popover",
                                                              rawName:
                                                                "v-b-popover.hover",
                                                              value:
                                                                _vm.propoverMethodOESQuestion,
                                                              expression:
                                                                "propoverMethodOESQuestion",
                                                              modifiers: {
                                                                hover: true,
                                                              },
                                                            },
                                                          ],
                                                          staticClass:
                                                            "ml-2 bx bxs-info-circle text-info",
                                                          staticStyle: {
                                                            cursor: "pointer",
                                                          },
                                                        }),
                                                      ]),
                                                      _c("span", [
                                                        column.questionTitle
                                                          ? _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "badge position-absolute ml-2",
                                                                class: {
                                                                  "bg-light":
                                                                    column
                                                                      .questionTitle
                                                                      .length !==
                                                                    _vm.oesQuestionTitleCharLimit,
                                                                  "bg-danger":
                                                                    column
                                                                      .questionTitle
                                                                      .length ===
                                                                    _vm.oesQuestionTitleCharLimit,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      column
                                                                        .questionTitle
                                                                        .length
                                                                    ) +
                                                                    " / " +
                                                                    _vm._s(
                                                                      _vm.oesQuestionTitleCharLimit
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]),
                                                    ]),
                                                    column.isEnebleColumnEditing
                                                      ? _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                column.questionTitle,
                                                              expression:
                                                                "column.questionTitle",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          class: {
                                                            "is-invalid":
                                                              column.questionTitle &&
                                                              column
                                                                .questionTitle
                                                                .length >
                                                                _vm.oesQuestionTitleCharLimit,
                                                          },
                                                          attrs: {
                                                            id: "questionTitle",
                                                            name: "questionTitle",
                                                            placeholder: _vm.$t(
                                                              "uploadProcessStep2.form.OES.questionTitle.placeholder"
                                                            ),
                                                          },
                                                          domProps: {
                                                            value:
                                                              column.questionTitle,
                                                          },
                                                          on: {
                                                            input: [
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  column,
                                                                  "questionTitle",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                              _vm.checkOESQuestionTitles,
                                                            ],
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    !column.isEnebleColumnEditing
                                                      ? _c(
                                                          "select",
                                                          {
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              background:
                                                                "#f5f5f5",
                                                              "-webkit-appearance":
                                                                "none",
                                                              "-moz-appearance":
                                                                "none",
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                column.questionTitle,
                                                            },
                                                          },
                                                          [
                                                            _c("option", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  column.questionTitle
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-4" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-md-8",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "uploadProcessStep2.form.OES.wrongTopic.keywords.label"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("span", [
                                                              _c("i", {
                                                                directives: [
                                                                  {
                                                                    name: "b-popover",
                                                                    rawName:
                                                                      "v-b-popover.hover",
                                                                    value:
                                                                      _vm.propoverMethodOESQKeywords,
                                                                    expression:
                                                                      "propoverMethodOESQKeywords",
                                                                    modifiers: {
                                                                      hover: true,
                                                                    },
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "ml-1 bx bxs-info-circle text-info",
                                                                staticStyle: {
                                                                  cursor:
                                                                    "pointer",
                                                                },
                                                              }),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-md-4 text-right",
                                                          },
                                                          [
                                                            column.keywords
                                                              .length > 0 &&
                                                            column.isEnebleColumnEditing
                                                              ? _c(
                                                                  "b-badge",
                                                                  {
                                                                    staticClass:
                                                                      "pointer",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "12px",
                                                                      },
                                                                    attrs: {
                                                                      variant:
                                                                        "light",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.clickResetKeywordsForMainOpenEndedColumn(
                                                                            columnIndex
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "bx bx-sync pointer mr-1",
                                                                    }),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "uploadProcessStep2.form.general.resetButton"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c("vue-tags-input", {
                                                      staticStyle: {
                                                        "max-width": "none",
                                                      },
                                                      style: [
                                                        !column.isEnebleColumnEditing ||
                                                        !_vm.isEnableWrongTopicCheck
                                                          ? {
                                                              background:
                                                                "#f5f5f5",
                                                            }
                                                          : {
                                                              background:
                                                                "#ffffff",
                                                            },
                                                      ],
                                                      attrs: {
                                                        disabled:
                                                          !column.isEnebleColumnEditing ||
                                                          !_vm.isEnableWrongTopicCheck,
                                                        tags: column.keywords,
                                                        placeholder: _vm.$t(
                                                          "uploadProcessStep2.form.OES.wrongTopic.keywords.placeholder"
                                                        ),
                                                        "max-tags": 15,
                                                      },
                                                      on: {
                                                        "tags-changed": (
                                                          newTags
                                                        ) =>
                                                          _vm.keywordsGetChangeForFirstOES(
                                                            newTags,
                                                            columnIndex
                                                          ),
                                                      },
                                                      model: {
                                                        value:
                                                          column.keywordsTag,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            column,
                                                            "keywordsTag",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "column.keywordsTag",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-4" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "uploadProcessStep2.form.OES.selectExpectedLanguage.label"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("span", [
                                                        _c("i", {
                                                          directives: [
                                                            {
                                                              name: "b-popover",
                                                              rawName:
                                                                "v-b-popover.hover",
                                                              value:
                                                                _vm.propoverMethodOESExpectedLanguages,
                                                              expression:
                                                                "\n                              propoverMethodOESExpectedLanguages\n                            ",
                                                              modifiers: {
                                                                hover: true,
                                                              },
                                                            },
                                                          ],
                                                          staticClass:
                                                            "ml-2 bx bxs-info-circle text-info",
                                                          staticStyle: {
                                                            cursor: "pointer",
                                                          },
                                                        }),
                                                      ]),
                                                    ]),
                                                    !column.isEnebleColumnEditing
                                                      ? _c("multiselect", {
                                                          attrs: {
                                                            multiple: true,
                                                            options:
                                                              _vm.allLanguages,
                                                            placeholder: _vm.$t(
                                                              "uploadProcessStep2.form.OES.selectExpectedLanguage.placeholder"
                                                            ),
                                                            "track-by": "code",
                                                            searchable: true,
                                                            disabled: true,
                                                            label: "code",
                                                            "custom-label":
                                                              _vm.customLabelForLanguage,
                                                            "show-labels": false,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "singleLabel",
                                                                fn: function ({
                                                                  option,
                                                                }) {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          option.code
                                                                        ) +
                                                                        " - " +
                                                                        _vm._s(
                                                                          option.name
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "option",
                                                                fn: function ({
                                                                  option,
                                                                }) {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          option.code
                                                                        ) +
                                                                        " - " +
                                                                        _vm._s(
                                                                          option.name
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value:
                                                              column.languagesToCheck,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                column,
                                                                "languagesToCheck",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "column.languagesToCheck",
                                                          },
                                                        })
                                                      : _c("multiselect", {
                                                          attrs: {
                                                            multiple: true,
                                                            options:
                                                              _vm.allLanguages,
                                                            placeholder: _vm.$t(
                                                              "uploadProcessStep2.form.OES.selectExpectedLanguage.placeholder"
                                                            ),
                                                            "track-by": "code",
                                                            searchable: true,
                                                            label: "code",
                                                            "custom-label":
                                                              _vm.customLabelForLanguage,
                                                            "show-labels": false,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "singleLabel",
                                                                fn: function ({
                                                                  option,
                                                                }) {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          option.code
                                                                        ) +
                                                                        " - " +
                                                                        _vm._s(
                                                                          option.name
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "option",
                                                                fn: function ({
                                                                  option,
                                                                }) {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          option.code
                                                                        ) +
                                                                        " - " +
                                                                        _vm._s(
                                                                          option.name
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value:
                                                              column.languagesToCheck,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                column,
                                                                "languagesToCheck",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "column.languagesToCheck",
                                                          },
                                                        }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            column.datasetColumnName &&
                                            !column.clickedContinueOne
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-md-12 text-left",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass: "mt-4",
                                                            attrs: {
                                                              type: "submit",
                                                              variant:
                                                                "primary",
                                                              disabled:
                                                                !column.questionTitle ||
                                                                !_vm.OESAllQuestionTitlesLengthValid,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.clickedContinueOneOpenEnded(
                                                                  columnIndex
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "uploadProcessStep2.form.general.continueButton"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("i", {
                                                              staticClass:
                                                                "bx bx-right-arrow-alt text-success bx-tada",
                                                            }),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]),
                                        (column.isEnebleColumnEditing &&
                                          column.clickedContinueOne) ||
                                        (!column.isEnebleColumnEditing &&
                                          column.openEndedList.length > 0)
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row mb-3" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-md-12",
                                                      },
                                                      [
                                                        _c("hr"),
                                                        _c("label", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "uploadProcessStep2.form.OES.additionalOpenEndedColumn.title"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._l(
                                                  column.openEndedList,
                                                  function (
                                                    openEndedColumn,
                                                    openEndedIndex
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: openEndedIndex,
                                                        staticClass: "p-3 mb-4",
                                                        staticStyle: {
                                                          border:
                                                            "1px solid #e3e3e3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row mb-2",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-md-4",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "row",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-md-8",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "uploadProcessStep2.form.OES.additionalOpenEndedColumn.label"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "text-danger",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "*"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-md-4 text-right",
                                                                          },
                                                                          [
                                                                            openEndedColumn.datasetColumnName &&
                                                                            column.isEnebleColumnEditing
                                                                              ? _c(
                                                                                  "b-badge",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pointer",
                                                                                    staticStyle:
                                                                                      {
                                                                                        "font-size":
                                                                                          "12px",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        variant:
                                                                                          "light",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.clickRemoveAdditionalOpenEndedColumn(
                                                                                            columnIndex,
                                                                                            openEndedIndex
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "i",
                                                                                      {
                                                                                        staticClass:
                                                                                          "bx bx-sync pointer mr-1",
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "uploadProcessStep2.form.general.resetButton"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    openEndedColumn.datasetColumnName
                                                                      ? _c(
                                                                          "select",
                                                                          {
                                                                            staticClass:
                                                                              "form-control",
                                                                            staticStyle:
                                                                              {
                                                                                background:
                                                                                  "#f5f5f5",
                                                                                "-webkit-appearance":
                                                                                  "none",
                                                                                "-moz-appearance":
                                                                                  "none",
                                                                              },
                                                                            style:
                                                                              [
                                                                                openEndedColumn
                                                                                  .validationError
                                                                                  .isDisplayValidationError
                                                                                  ? {
                                                                                      border:
                                                                                        "1px solid #f46a6a",
                                                                                    }
                                                                                  : {
                                                                                      border:
                                                                                        _vm.none,
                                                                                    },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  openEndedColumn.datasetColumnName,
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "option",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      openEndedColumn.datasetColumnName
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "multiselect",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                options:
                                                                                  _vm.workingMetaDataArray,
                                                                                placeholder:
                                                                                  _vm.$t(
                                                                                    "uploadProcessStep2.form.OES.additionalOpenEndedColumn.placeholder"
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.selectAdditionalOpenEndedColumn(
                                                                                    columnIndex,
                                                                                    openEndedIndex
                                                                                  )
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  openEndedColumn.datasetColumnName,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      openEndedColumn,
                                                                                      "datasetColumnName",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "openEndedColumn.datasetColumnName",
                                                                              },
                                                                          }
                                                                        ),
                                                                    openEndedColumn
                                                                      .validationError
                                                                      .isDisplayValidationError
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "mt-2",
                                                                          },
                                                                          _vm._l(
                                                                            openEndedColumn
                                                                              .validationError
                                                                              .errors,
                                                                            function (
                                                                              error,
                                                                              errorIndex
                                                                            ) {
                                                                              return _c(
                                                                                "div",
                                                                                {
                                                                                  key: errorIndex,
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-danger",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " * " +
                                                                                          _vm._s(
                                                                                            _vm.getTransationByErrorCode(
                                                                                              error.validationErrorCode
                                                                                            )
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-md-8 text-right",
                                                              },
                                                              [
                                                                column.isEnebleColumnEditing
                                                                  ? _c("i", {
                                                                      staticClass:
                                                                        "bx bxs-x-square text-danger pointer",
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "25px",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeAdditionalOpenEndedColumn(
                                                                              columnIndex,
                                                                              openEndedIndex
                                                                            )
                                                                          },
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-md-4",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "uploadProcessStep2.form.OES.questionTitle.label"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "text-danger",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "*"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "b-popover",
                                                                                      rawName:
                                                                                        "v-b-popover.hover.html",
                                                                                      value:
                                                                                        _vm.propoverMethodOESQuestion,
                                                                                      expression:
                                                                                        "\n                                propoverMethodOESQuestion\n                              ",
                                                                                      modifiers:
                                                                                        {
                                                                                          hover: true,
                                                                                          html: true,
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "ml-2 bx bxs-info-circle text-info",
                                                                                staticStyle:
                                                                                  {
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            openEndedColumn.questionTitle
                                                                              ? _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "badge position-absolute ml-2",
                                                                                    class:
                                                                                      {
                                                                                        "bg-light":
                                                                                          openEndedColumn
                                                                                            .questionTitle
                                                                                            .length !==
                                                                                          _vm.oesQuestionTitleCharLimit,
                                                                                        "bg-danger":
                                                                                          openEndedColumn
                                                                                            .questionTitle
                                                                                            .length ===
                                                                                          _vm.oesQuestionTitleCharLimit,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          openEndedColumn
                                                                                            .questionTitle
                                                                                            .length
                                                                                        ) +
                                                                                        " / " +
                                                                                        _vm._s(
                                                                                          _vm.oesQuestionTitleCharLimit
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                openEndedColumn.questionTitle,
                                                                              expression:
                                                                                "openEndedColumn.questionTitle",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        class: {
                                                                          "is-invalid":
                                                                            openEndedColumn.questionTitle &&
                                                                            openEndedColumn
                                                                              .questionTitle
                                                                              .length >
                                                                              _vm.oesQuestionTitleCharLimit,
                                                                        },
                                                                        attrs: {
                                                                          id: "questionTitle",
                                                                          disabled:
                                                                            !column.isEnebleColumnEditing,
                                                                          name: "questionTitle",
                                                                          placeholder:
                                                                            _vm.$t(
                                                                              "uploadProcessStep2.form.OES.questionTitle.placeholder"
                                                                            ),
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              openEndedColumn.questionTitle,
                                                                          },
                                                                        on: {
                                                                          input:
                                                                            [
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.$set(
                                                                                  openEndedColumn,
                                                                                  "questionTitle",
                                                                                  $event
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              },
                                                                              _vm.checkOESQuestionTitles,
                                                                            ],
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-md-4",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "row",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-md-9",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "font-weight-600",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "uploadProcessStep2.form.OES.wrongTopic.keywords.label"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "b-popover",
                                                                                          rawName:
                                                                                            "v-b-popover.hover",
                                                                                          value:
                                                                                            _vm.propoverMethodOESQKeywords,
                                                                                          expression:
                                                                                            "propoverMethodOESQKeywords",
                                                                                          modifiers:
                                                                                            {
                                                                                              hover: true,
                                                                                            },
                                                                                        },
                                                                                      ],
                                                                                    staticClass:
                                                                                      "ml-1 bx bxs-info-circle text-info",
                                                                                    staticStyle:
                                                                                      {
                                                                                        cursor:
                                                                                          "pointer",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-md-3 text-right",
                                                                          },
                                                                          [
                                                                            openEndedColumn
                                                                              .keywords
                                                                              .length >
                                                                              0 &&
                                                                            column.isEnebleColumnEditing
                                                                              ? _c(
                                                                                  "b-badge",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pointer",
                                                                                    staticStyle:
                                                                                      {
                                                                                        "font-size":
                                                                                          "12px",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        variant:
                                                                                          "light",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.clickResetKeywordsForAdditionalOpenEndedColumn(
                                                                                            columnIndex,
                                                                                            openEndedIndex
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "i",
                                                                                      {
                                                                                        staticClass:
                                                                                          "bx bx-sync pointer mr-1",
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "uploadProcessStep2.form.general.resetButton"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "vue-tags-input",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "max-width":
                                                                              "none",
                                                                          },
                                                                        style: [
                                                                          !column.isEnebleColumnEditing ||
                                                                          !_vm.isEnableWrongTopicCheck
                                                                            ? {
                                                                                background:
                                                                                  "#f5f5f5",
                                                                              }
                                                                            : {
                                                                                background:
                                                                                  "#ffffff",
                                                                              },
                                                                        ],
                                                                        attrs: {
                                                                          disabled:
                                                                            !column.isEnebleColumnEditing ||
                                                                            !_vm.isEnableWrongTopicCheck,
                                                                          tags: openEndedColumn.keywords,
                                                                          placeholder:
                                                                            _vm.$t(
                                                                              "uploadProcessStep2.form.OES.wrongTopic.keywords.placeholder"
                                                                            ),
                                                                          "max-tags": 15,
                                                                        },
                                                                        on: {
                                                                          "tags-changed":
                                                                            (
                                                                              newTags
                                                                            ) =>
                                                                              _vm.keywordsGetChangeOtherOES(
                                                                                newTags,
                                                                                columnIndex,
                                                                                openEndedIndex
                                                                              ),
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            openEndedColumn.keywordsTag,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                openEndedColumn,
                                                                                "keywordsTag",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "openEndedColumn.keywordsTag",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-md-4",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "uploadProcessStep2.form.OES.selectExpectedLanguage.label"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "b-popover",
                                                                                      rawName:
                                                                                        "v-b-popover.hover",
                                                                                      value:
                                                                                        _vm.propoverMethodOESExpectedLanguages,
                                                                                      expression:
                                                                                        "\n                                propoverMethodOESExpectedLanguages\n                              ",
                                                                                      modifiers:
                                                                                        {
                                                                                          hover: true,
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "ml-2 bx bxs-info-circle text-info",
                                                                                staticStyle:
                                                                                  {
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    !column.isEnebleColumnEditing
                                                                      ? _c(
                                                                          "multiselect",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                multiple: true,
                                                                                options:
                                                                                  _vm.allLanguages,
                                                                                placeholder:
                                                                                  _vm.$t(
                                                                                    "uploadProcessStep2.form.general.selectColumnType.placeholder"
                                                                                  ),
                                                                                "track-by":
                                                                                  "code",
                                                                                searchable: true,
                                                                                disabled: true,
                                                                                label:
                                                                                  "code",
                                                                                "custom-label":
                                                                                  _vm.customLabelForLanguage,
                                                                                "show-labels": false,
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "singleLabel",
                                                                                    fn: function ({
                                                                                      option,
                                                                                    }) {
                                                                                      return [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              option.code
                                                                                            ) +
                                                                                            " - " +
                                                                                            _vm._s(
                                                                                              option.name
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    key: "option",
                                                                                    fn: function ({
                                                                                      option,
                                                                                    }) {
                                                                                      return [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              option.code
                                                                                            ) +
                                                                                            " - " +
                                                                                            _vm._s(
                                                                                              option.name
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  openEndedColumn.languagesToCheck,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      openEndedColumn,
                                                                                      "languagesToCheck",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "openEndedColumn.languagesToCheck",
                                                                              },
                                                                          }
                                                                        )
                                                                      : _c(
                                                                          "multiselect",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                multiple: true,
                                                                                options:
                                                                                  _vm.allLanguages,
                                                                                placeholder:
                                                                                  _vm.$t(
                                                                                    "uploadProcessStep2.form.general.selectColumnType.placeholder"
                                                                                  ),
                                                                                "track-by":
                                                                                  "code",
                                                                                searchable: true,
                                                                                label:
                                                                                  "code",
                                                                                "custom-label":
                                                                                  _vm.customLabelForLanguage,
                                                                                "show-labels": false,
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "singleLabel",
                                                                                    fn: function ({
                                                                                      option,
                                                                                    }) {
                                                                                      return [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              option.code
                                                                                            ) +
                                                                                            " - " +
                                                                                            _vm._s(
                                                                                              option.name
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    key: "option",
                                                                                    fn: function ({
                                                                                      option,
                                                                                    }) {
                                                                                      return [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              option.code
                                                                                            ) +
                                                                                            " - " +
                                                                                            _vm._s(
                                                                                              option.name
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  openEndedColumn.languagesToCheck,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      openEndedColumn,
                                                                                      "languagesToCheck",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "openEndedColumn.languagesToCheck",
                                                                              },
                                                                          }
                                                                        ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row mt-3" },
                                                  [
                                                    column.isEnebleColumnEditing &&
                                                    !_vm.maxOESQuestionsReached
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-md-4",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "p-3 mt-2 pointer",
                                                                staticStyle: {
                                                                  border:
                                                                    "1px solid #f5f5f5",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.clickAddNewOpenEndedColumn(
                                                                        columnIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "bx bx-plus-medical bx-tada",
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "uploadProcessStep2.form.OES.additionalOpenEndedColumn.button"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        column.clickedContinueOne
                                          ? _c("div", { staticClass: "row" }, [
                                              !column.columnSaved
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "col-md-12",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-items-center",
                                                        },
                                                        [
                                                          !column.validationInProgress
                                                            ? _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "mt-3",
                                                                  attrs: {
                                                                    type: "submit",
                                                                    variant:
                                                                      "primary",
                                                                    disabled:
                                                                      !_vm.OESAllQuestionTitlesThere ||
                                                                      !_vm.OESAllQuestionTitlesLengthValid ||
                                                                      !_vm.OESAllQuestionSpecifedColumns,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleClickOnSaveColumn(
                                                                          columnIndex
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "uploadProcessStep2.form.general.saveButton"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "bx bx-check text-success bx-tada",
                                                                    }),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mt-3",
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "mr-2 bx bx-loader-circle bx-spin",
                                                                  }),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "uploadProcessStep2.form.general.validationInProgress"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                          column.validationError
                                                            .displayServerError
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-danger mt-3 ml-3",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getTransationByErrorCode(
                                                                          _vm
                                                                            .errorCodesEnum
                                                                            .SERVER_ERROR
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  column.columnType &&
                                  column.columnType ===
                                    _vm.columnTypesEnum.ITEMBATTERY
                                    ? _c("div", [
                                        _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "row mt-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-md-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "uploadProcessStep2.form.IBS.selectStartColumn.label"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                      ]),
                                                      column.datasetColumnName
                                                        ? _c(
                                                            "select",
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              staticStyle: {
                                                                background:
                                                                  "#f5f5f5",
                                                                "-webkit-appearance":
                                                                  "none",
                                                                "-moz-appearance":
                                                                  "none",
                                                              },
                                                              style: [
                                                                column
                                                                  .validationError
                                                                  .isDisplayValidationError
                                                                  ? {
                                                                      border:
                                                                        "1px solid #f46a6a",
                                                                    }
                                                                  : {
                                                                      border:
                                                                        _vm.none,
                                                                    },
                                                              ],
                                                              attrs: {
                                                                disabled:
                                                                  column.datasetColumnName,
                                                              },
                                                            },
                                                            [
                                                              _c("option", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    column.datasetColumnName
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _c("multiselect", {
                                                            attrs: {
                                                              options:
                                                                _vm.workingMetaDataArray,
                                                              placeholder:
                                                                _vm.$t(
                                                                  "uploadProcessStep2.form.general.selectColumn.placeholder"
                                                                ),
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectDatasetColumn(
                                                                  columnIndex
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                column.datasetColumnName,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    column,
                                                                    "datasetColumnName",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "column.datasetColumnName",
                                                            },
                                                          }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "uploadProcessStep2.form.IBS.selectEndColumn.label"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                      ]),
                                                      column.itemBatteryEndRangeName
                                                        ? _c(
                                                            "select",
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              staticStyle: {
                                                                background:
                                                                  "#f5f5f5",
                                                                "-webkit-appearance":
                                                                  "none",
                                                                "-moz-appearance":
                                                                  "none",
                                                              },
                                                              style: [
                                                                column
                                                                  .validationError
                                                                  .isDisplayValidationError
                                                                  ? {
                                                                      border:
                                                                        "1px solid #f46a6a",
                                                                    }
                                                                  : {
                                                                      border:
                                                                        _vm.none,
                                                                    },
                                                              ],
                                                              attrs: {
                                                                disabled:
                                                                  column.itemBatteryEndRangeName,
                                                              },
                                                            },
                                                            [
                                                              _c("option", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    column.itemBatteryEndRangeName
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _c("multiselect", {
                                                            attrs: {
                                                              options:
                                                                _vm.workingMetaDataArray,
                                                              "allow-empty": false,
                                                              placeholder:
                                                                _vm.$t(
                                                                  "uploadProcessStep2.form.IBS.selectEndColumn.placeholder"
                                                                ),
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectDatasetColumn(
                                                                  columnIndex
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                column.itemBatteryEndRangeName,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    column,
                                                                    "itemBatteryEndRangeName",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "column.itemBatteryEndRangeName",
                                                            },
                                                          }),
                                                      column.endRangeError
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#f46a6a",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "uploadProcessStep2.form.IBS.selectEndColumn.error"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-3" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "custom-control custom-checkbox pt-2 mt-4",
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !column.isEnebleColumnEditing,
                                                            name: "check-button",
                                                            switch: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.enablePatternCheckMain,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.enablePatternCheckMain =
                                                                $$v
                                                            },
                                                            expression:
                                                              "enablePatternCheckMain",
                                                          },
                                                        },
                                                        [
                                                          _c("label", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-600",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "uploadProcessStep2.form.IBS.patternCheck.checkBox"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _c("i", {
                                                                directives: [
                                                                  {
                                                                    name: "b-popover",
                                                                    rawName:
                                                                      "v-b-popover.hover",
                                                                    value:
                                                                      _vm.popoverMethodPatternChecks,
                                                                    expression:
                                                                      "popoverMethodPatternChecks",
                                                                    modifiers: {
                                                                      hover: true,
                                                                    },
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "ml-2 bx bxs-info-circle text-info",
                                                                staticStyle: {
                                                                  cursor:
                                                                    "pointer",
                                                                },
                                                              }),
                                                            ]),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              column.datasetColumnName &&
                                              column.itemBatteryEndRangeName &&
                                              column.isEnebleColumnEditing
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-md-1 text-left",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "bx bxs-x-square text-danger pointer pt-4 mt-2",
                                                            staticStyle: {
                                                              "font-size":
                                                                "25px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.clickRemoveMainIB(
                                                                  columnIndex
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              column.datasetColumnName &&
                                              column.itemBatteryEndRangeName &&
                                              !column.clickedContinueOne
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-md-4 text-left",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group",
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mt-4",
                                                              attrs: {
                                                                type: "submit",
                                                                variant:
                                                                  "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.clickedContinueOneItemBattery(
                                                                      columnIndex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "uploadProcessStep2.form.general.continueButton"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("i", {
                                                                staticClass:
                                                                  "bx bx-right-arrow-alt text-success bx-tada",
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          column.validationError
                                            .isDisplayValidationError
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                _vm._l(
                                                  column.validationError.errors,
                                                  function (error, errorIndex) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: errorIndex,
                                                        staticClass:
                                                          "col-md-12",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " * " +
                                                                _vm._s(
                                                                  _vm.getTransationByErrorCode(
                                                                    error.validationErrorCode
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]),
                                        (column.clickedContinueOne &&
                                          column.isEnebleColumnEditing) ||
                                        (!column.isEnebleColumnEditing &&
                                          column.itemBatteryRanges.length > 0)
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "col-md-12",
                                                    },
                                                    [
                                                      _c("hr"),
                                                      _c("label", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "uploadProcessStep2.form.IBS.additionalItemRange.title"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _vm._l(
                                                    column.itemBatteryRanges,
                                                    function (
                                                      IBcolumn,
                                                      IBIndex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: IBIndex,
                                                          staticClass:
                                                            "col-md-12",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-md-4",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "uploadProcessStep2.form.IBS.selectStartColumn.label"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      IBcolumn.rangeStartName
                                                                        ? _c(
                                                                            "select",
                                                                            {
                                                                              staticClass:
                                                                                "form-control",
                                                                              staticStyle:
                                                                                {
                                                                                  background:
                                                                                    "#f5f5f5",
                                                                                  "-webkit-appearance":
                                                                                    "none",
                                                                                  "-moz-appearance":
                                                                                    "none",
                                                                                },
                                                                              style:
                                                                                [
                                                                                  IBcolumn
                                                                                    .validationError
                                                                                    .isDisplayValidationError
                                                                                    ? {
                                                                                        border:
                                                                                          "1px solid #f46a6a",
                                                                                      }
                                                                                    : {
                                                                                        border:
                                                                                          _vm.none,
                                                                                      },
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    IBcolumn.rangeStartName,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "option",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      IBcolumn.rangeStartName
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "multiselect",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  options:
                                                                                    _vm.workingMetaDataArray,
                                                                                  placeholder:
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.IBS.selectStartColumn.placeholder"
                                                                                    ),
                                                                                },
                                                                              on: {
                                                                                input:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.selectAdditionalIBRange(
                                                                                      columnIndex,
                                                                                      IBIndex,
                                                                                      true
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    IBcolumn.rangeStartName,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        IBcolumn,
                                                                                        "rangeStartName",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "IBcolumn.rangeStartName",
                                                                                },
                                                                            }
                                                                          ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-md-4",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "uploadProcessStep2.form.IBS.selectEndColumn.label"
                                                                                )
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      IBcolumn.rangeEndName
                                                                        ? _c(
                                                                            "select",
                                                                            {
                                                                              staticClass:
                                                                                "form-control",
                                                                              staticStyle:
                                                                                {
                                                                                  background:
                                                                                    "#f5f5f5",
                                                                                  "-webkit-appearance":
                                                                                    "none",
                                                                                  "-moz-appearance":
                                                                                    "none",
                                                                                },
                                                                              style:
                                                                                [
                                                                                  IBcolumn
                                                                                    .validationError
                                                                                    .isDisplayValidationError
                                                                                    ? {
                                                                                        border:
                                                                                          "1px solid #f46a6a",
                                                                                      }
                                                                                    : {
                                                                                        border:
                                                                                          _vm.none,
                                                                                      },
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    IBcolumn.rangeEndName,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "option",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      IBcolumn.rangeEndName
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "multiselect",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  options:
                                                                                    _vm.workingMetaDataArray,
                                                                                  placeholder:
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.IBS.selectEndColumn.placeholder"
                                                                                    ),
                                                                                },
                                                                              on: {
                                                                                input:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.selectAdditionalIBRange(
                                                                                      columnIndex,
                                                                                      IBIndex,
                                                                                      false
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    IBcolumn.rangeEndName,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        IBcolumn,
                                                                                        "rangeEndName",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "IBcolumn.rangeEndName",
                                                                                },
                                                                            }
                                                                          ),
                                                                      IBcolumn.endRangeError
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  color:
                                                                                    "#f46a6a",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.IBS.selectEndColumn.error"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-md-3",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "custom-control custom-checkbox pt-2 mt-4",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-checkbox",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    !column.isEnebleColumnEditing,
                                                                                  name: "check-button",
                                                                                  switch:
                                                                                    "",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .enablePatternCheckAdditional[
                                                                                      IBIndex
                                                                                    ],
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.enablePatternCheckAdditional,
                                                                                        IBIndex,
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "enablePatternCheckAdditional[IBIndex]",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "label",
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "font-weight-600",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "uploadProcessStep2.form.IBS.patternCheck.checkBox"
                                                                                            )
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _c(
                                                                                        "i",
                                                                                        {
                                                                                          directives:
                                                                                            [
                                                                                              {
                                                                                                name: "b-popover",
                                                                                                rawName:
                                                                                                  "v-b-popover.hover",
                                                                                                value:
                                                                                                  _vm.popoverMethodPatternChecks,
                                                                                                expression:
                                                                                                  "popoverMethodPatternChecks",
                                                                                                modifiers:
                                                                                                  {
                                                                                                    hover: true,
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                          staticClass:
                                                                                            "ml-2 bx bxs-info-circle text-info",
                                                                                          staticStyle:
                                                                                            {
                                                                                              cursor:
                                                                                                "pointer",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  column.isEnebleColumnEditing
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-md-1",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "bx bxs-x-square text-danger pointer pt-4 mt-2",
                                                                              staticStyle:
                                                                                {
                                                                                  "font-size":
                                                                                    "25px",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.removeAdditionalIBRangeFull(
                                                                                      columnIndex,
                                                                                      IBIndex
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              IBcolumn
                                                                .validationError
                                                                .isDisplayValidationError
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "row mt-3",
                                                                    },
                                                                    _vm._l(
                                                                      IBcolumn
                                                                        .validationError
                                                                        .errors,
                                                                      function (
                                                                        error,
                                                                        errorIndex
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key: errorIndex,
                                                                            staticClass:
                                                                              "col-md-12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "text-danger",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " * " +
                                                                                    _vm._s(
                                                                                      _vm.getTransationByErrorCode(
                                                                                        error.validationErrorCode
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  column.isEnebleColumnEditing
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-8",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "p-3 mt-2 pointer",
                                                              staticStyle: {
                                                                border:
                                                                  "1px solid #f5f5f5",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.clickAddAdditionalRange(
                                                                      columnIndex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "bx bx-plus-medical bx-tada",
                                                              }),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "uploadProcessStep2.form.IBS.additionalItemRange.button"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  column.isEnebleColumnEditing &&
                                                  !column.columnSaved
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-12",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-items-center",
                                                            },
                                                            [
                                                              !column.validationInProgress
                                                                ? _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "mt-3",
                                                                      attrs: {
                                                                        type: "submit",
                                                                        variant:
                                                                          "primary",
                                                                        disabled:
                                                                          !column.datasetColumnName ||
                                                                          !column.itemBatteryEndRangeName,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleClickOnSaveColumn(
                                                                              columnIndex
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "uploadProcessStep2.form.general.saveButton"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "bx bx-check text-success bx-tada",
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "mt-3",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "mr-2 bx bx-loader-circle bx-spin",
                                                                      }),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "uploadProcessStep2.form.general.validationInProgress"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                              column
                                                                .validationError
                                                                .displayServerError
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-danger mt-3 ml-3",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.getTransationByErrorCode(
                                                                              _vm
                                                                                .errorCodesEnum
                                                                                .SERVER_ERROR
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  column.columnType &&
                                  column.columnType ===
                                    _vm.columnTypesEnum.TIMESTAMP &&
                                  column.clickedContinueOne
                                    ? _c("div", [
                                        column.isEnebleColumnEditing ||
                                        (!column.isEnebleColumnEditing &&
                                          column.timeStampsList.length > 0)
                                          ? _c(
                                              "div",
                                              { staticClass: "row mt-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-12" },
                                                  [
                                                    _c("hr"),
                                                    _c("label", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "uploadProcessStep2.form.TS.additionalTimeColumn.title"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._l(
                                                  column.timeStampsList,
                                                  function (
                                                    timeStampColumn,
                                                    timeStampIndex
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: timeStampIndex,
                                                        staticClass:
                                                          "col-md-4 mb-3",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group",
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "uploadProcessStep2.form.TS.additionalTimeColumn.label"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              column.isEnebleColumnEditing
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          cursor:
                                                                            "pointer",
                                                                          "font-size":
                                                                            "15px",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeAdditionalTimeStampColumn(
                                                                              columnIndex,
                                                                              timeStampIndex
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "bx bxs-x-circle text-danger",
                                                                      }),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]),
                                                            timeStampColumn.datasetColumnName
                                                              ? _c(
                                                                  "select",
                                                                  {
                                                                    staticClass:
                                                                      "form-control",
                                                                    staticStyle:
                                                                      {
                                                                        background:
                                                                          "#f5f5f5",
                                                                        "-webkit-appearance":
                                                                          "none",
                                                                        "-moz-appearance":
                                                                          "none",
                                                                      },
                                                                    style: [
                                                                      timeStampColumn
                                                                        .validationError
                                                                        .isDisplayValidationError
                                                                        ? {
                                                                            border:
                                                                              "1px solid #f46a6a",
                                                                          }
                                                                        : {
                                                                            border:
                                                                              _vm.none,
                                                                          },
                                                                    ],
                                                                    attrs: {
                                                                      disabled:
                                                                        timeStampColumn.datasetColumnName,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              timeStampColumn.datasetColumnName
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "multiselect",
                                                                  {
                                                                    attrs: {
                                                                      options:
                                                                        _vm.workingMetaDataArray,
                                                                      placeholder:
                                                                        _vm.$t(
                                                                          "uploadProcessStep2.form.TS.additionalTimeColumn.placeholder"
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.selectAdditionalTimeStampColumn(
                                                                            columnIndex,
                                                                            timeStampIndex
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        timeStampColumn.datasetColumnName,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            timeStampColumn,
                                                                            "datasetColumnName",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "timeStampColumn.datasetColumnName",
                                                                    },
                                                                  }
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                        timeStampColumn
                                                          .validationError
                                                          .isDisplayValidationError
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row mt-3",
                                                              },
                                                              _vm._l(
                                                                timeStampColumn
                                                                  .validationError
                                                                  .errors,
                                                                function (
                                                                  error,
                                                                  errorIndex
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: errorIndex,
                                                                      staticClass:
                                                                        "col-md-12",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "text-danger",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " * " +
                                                                              _vm._s(
                                                                                _vm.getTransationByErrorCode(
                                                                                  error.validationErrorCode
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                column.isEnebleColumnEditing
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-md-4",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "p-3 mt-3 pointer",
                                                            staticStyle: {
                                                              border:
                                                                "1px solid #f5f5f5",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.clickAddNewTimeStampColumn(
                                                                  columnIndex
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bx bx-plus-medical bx-tada",
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "uploadProcessStep2.form.TS.additionalTimeColumn.button"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        column.isEnebleColumnEditing ||
                                        (!column.isEnebleColumnEditing &&
                                          column.groupSelector.length > 0)
                                          ? _c(
                                              "div",
                                              { staticClass: "row mt-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-12" },
                                                  [
                                                    _c("hr"),
                                                    _c("label", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "uploadProcessStep2.form.TS.groupSelector.title"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _c("p", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "uploadProcessStep2.form.TS.groupSelector.description"
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._l(
                                                  column.groupSelector,
                                                  function (
                                                    groupSelectorColumn,
                                                    groupSelectorIndex
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: groupSelectorIndex,
                                                        staticClass: "col-md-4",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group",
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "uploadProcessStep2.form.TS.groupSelector.label"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              column.isEnebleColumnEditing
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          cursor:
                                                                            "pointer",
                                                                          "font-size":
                                                                            "15px",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeGroupSelectorColumn(
                                                                              columnIndex,
                                                                              groupSelectorIndex
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "bx bxs-x-circle text-danger",
                                                                      }),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]),
                                                            groupSelectorColumn.datasetColumnName
                                                              ? _c(
                                                                  "select",
                                                                  {
                                                                    staticClass:
                                                                      "form-control",
                                                                    staticStyle:
                                                                      {
                                                                        background:
                                                                          "#f5f5f5",
                                                                        "-webkit-appearance":
                                                                          "none",
                                                                        "-moz-appearance":
                                                                          "none",
                                                                      },
                                                                    style: [
                                                                      groupSelectorColumn
                                                                        .validationError
                                                                        .isDisplayValidationError
                                                                        ? {
                                                                            border:
                                                                              "1px solid #f46a6a",
                                                                          }
                                                                        : {
                                                                            border:
                                                                              _vm.none,
                                                                          },
                                                                    ],
                                                                    attrs: {
                                                                      disabled:
                                                                        groupSelectorColumn.datasetColumnName,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              groupSelectorColumn.datasetColumnName
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "multiselect",
                                                                  {
                                                                    attrs: {
                                                                      options:
                                                                        _vm.workingMetaDataArray,
                                                                      placeholder:
                                                                        _vm.$t(
                                                                          "uploadProcessStep2.form.TS.groupSelector.placeholder"
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.selectGroupSelectorColumn(
                                                                            columnIndex,
                                                                            groupSelectorIndex
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        groupSelectorColumn.datasetColumnName,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            groupSelectorColumn,
                                                                            "datasetColumnName",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "groupSelectorColumn.datasetColumnName",
                                                                    },
                                                                  }
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                        groupSelectorColumn &&
                                                        groupSelectorColumn
                                                          .validationError
                                                          .isDisplayValidationError
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row mt-3",
                                                              },
                                                              _vm._l(
                                                                groupSelectorColumn
                                                                  .validationError
                                                                  .errors,
                                                                function (
                                                                  error,
                                                                  errorIndex
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: errorIndex,
                                                                      staticClass:
                                                                        "col-md-12",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "text-danger",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " * " +
                                                                              _vm._s(
                                                                                _vm.getTransationByErrorCode(
                                                                                  error.validationErrorCode
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-4" },
                                                  [
                                                    column.isEnebleColumnEditing &&
                                                    column.groupSelector
                                                      .length === 0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "p-3 mt-2 pointer",
                                                            staticStyle: {
                                                              border:
                                                                "1px solid #f5f5f5",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.clickAddGroupSelectionColumn(
                                                                  columnIndex
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bx bx-plus-medical bx-tada",
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "uploadProcessStep2.form.TS.groupSelector.addGroupButton"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _c("div", { staticClass: "row" }, [
                                          column.isEnebleColumnEditing &&
                                          !column.columnSaved
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "col-md-12 mt-3",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center",
                                                    },
                                                    [
                                                      !column.validationInProgress
                                                        ? _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mt-3",
                                                              attrs: {
                                                                type: "submit",
                                                                variant:
                                                                  "primary",
                                                                disabled:
                                                                  !column.datasetColumnName,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleClickOnSaveColumn(
                                                                      columnIndex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "uploadProcessStep2.form.general.saveButton"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "bx bx-check text-success bx-tada",
                                                                }),
                                                              ]),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mt-3",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "mr-2 bx bx-loader-circle bx-spin",
                                                              }),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "uploadProcessStep2.form.general.validationInProgress"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                      column.validationError
                                                        .displayServerError
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-danger mt-3 ml-3",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getTransationByErrorCode(
                                                                      _vm
                                                                        .errorCodesEnum
                                                                        .SERVER_ERROR
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  column.columnType &&
                                  column.columnType ===
                                    _vm.columnTypesEnum.PROJECTIVE_QUESTION &&
                                  column.clickedContinueOne &&
                                  column.isAllAnswersGet &&
                                  !column.isAnswerListHavingAnError
                                    ? _c("div", [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-12" },
                                            [
                                              _c("hr"),
                                              _c("label", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "uploadProcessStep2.form.PQ.answerOptions.title"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "row mt-3" },
                                          [
                                            _vm._l(
                                              column.answersList,
                                              function (answer, answerIndex) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: answerIndex,
                                                    staticClass:
                                                      "col-md-4 mb-3",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "row pr-2",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-md-5",
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "uploadProcessStep2.form.PQ.answerOptions.answerLabel"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    column
                                                                      .answersList[
                                                                      answerIndex
                                                                    ],
                                                                  expression:
                                                                    "column.answersList[answerIndex]",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              staticStyle: {
                                                                background:
                                                                  "#f5f5f5",
                                                              },
                                                              attrs: {
                                                                id: "answer",
                                                                type: "number",
                                                                name: "answer",
                                                                disabled: true,
                                                              },
                                                              domProps: {
                                                                value:
                                                                  column
                                                                    .answersList[
                                                                    answerIndex
                                                                  ],
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      column.answersList,
                                                                      answerIndex,
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-md-7",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-group",
                                                              },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-md-6",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "uploadProcessStep2.form.PQ.answerOptions.prediction.label"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-md-6 text-right",
                                                                      },
                                                                      [
                                                                        column
                                                                          .predictionsList[
                                                                          answerIndex
                                                                        ]
                                                                          .datasetColumnName &&
                                                                        column.isEnebleColumnEditing
                                                                          ? _c(
                                                                              "b-badge",
                                                                              {
                                                                                staticClass:
                                                                                  "pointer",
                                                                                staticStyle:
                                                                                  {
                                                                                    "font-size":
                                                                                      "12px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    variant:
                                                                                      "light",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editSelectedPrediction(
                                                                                        columnIndex,
                                                                                        answerIndex
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "bx bx-sync pointer mr-1",
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "uploadProcessStep2.form.general.resetButton"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                column
                                                                  .predictionsList[
                                                                  answerIndex
                                                                ]
                                                                  .datasetColumnName
                                                                  ? _c(
                                                                      "select",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                column
                                                                                  .predictionsList[
                                                                                  answerIndex
                                                                                ]
                                                                                  .datasetColumnName,
                                                                              expression:
                                                                                "\n                            column.predictionsList[answerIndex]\n                              .datasetColumnName\n                          ",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        staticStyle:
                                                                          {
                                                                            background:
                                                                              "#f5f5f5",
                                                                            "-webkit-appearance":
                                                                              "none",
                                                                            "-moz-appearance":
                                                                              "none",
                                                                          },
                                                                        style: [
                                                                          column
                                                                            .predictionsList[
                                                                            answerIndex
                                                                          ]
                                                                            .validationError
                                                                            .isDisplayValidationError
                                                                            ? {
                                                                                border:
                                                                                  "1px solid #f46a6a",
                                                                              }
                                                                            : {
                                                                                border:
                                                                                  _vm.none,
                                                                              },
                                                                        ],
                                                                        attrs: {
                                                                          disabled:
                                                                            column
                                                                              .predictionsList[
                                                                              answerIndex
                                                                            ]
                                                                              .datasetColumnName,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              var $$selectedVal =
                                                                                Array.prototype.filter
                                                                                  .call(
                                                                                    $event
                                                                                      .target
                                                                                      .options,
                                                                                    function (
                                                                                      o
                                                                                    ) {
                                                                                      return o.selected
                                                                                    }
                                                                                  )
                                                                                  .map(
                                                                                    function (
                                                                                      o
                                                                                    ) {
                                                                                      var val =
                                                                                        "_value" in
                                                                                        o
                                                                                          ? o._value
                                                                                          : o.value
                                                                                      return val
                                                                                    }
                                                                                  )
                                                                              _vm.$set(
                                                                                column
                                                                                  .predictionsList[
                                                                                  answerIndex
                                                                                ],
                                                                                "datasetColumnName",
                                                                                $event
                                                                                  .target
                                                                                  .multiple
                                                                                  ? $$selectedVal
                                                                                  : $$selectedVal[0]
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "option",
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  column
                                                                                    .predictionsList[
                                                                                    answerIndex
                                                                                  ]
                                                                                    .datasetColumnName
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "multiselect",
                                                                      {
                                                                        attrs: {
                                                                          options:
                                                                            _vm.workingMetaDataArray,
                                                                          placeholder:
                                                                            _vm.$t(
                                                                              "uploadProcessStep2.form.PQ.answerOptions.prediction.placeholder"
                                                                            ),
                                                                          disabled:
                                                                            !column.isEnebleColumnEditing,
                                                                        },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.selectPredictionForAnswer(
                                                                                columnIndex,
                                                                                answerIndex
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            column
                                                                              .predictionsList[
                                                                              answerIndex
                                                                            ]
                                                                              .datasetColumnName,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                column
                                                                                  .predictionsList[
                                                                                  answerIndex
                                                                                ],
                                                                                "datasetColumnName",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "\n                            column.predictionsList[answerIndex]\n                              .datasetColumnName\n                          ",
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    column.predictionsList[
                                                      answerIndex
                                                    ].validationError
                                                      .isDisplayValidationError
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          _vm._l(
                                                            column
                                                              .predictionsList[
                                                              answerIndex
                                                            ].validationError
                                                              .errors,
                                                            function (
                                                              error,
                                                              errorIndex
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: errorIndex,
                                                                  staticClass:
                                                                    "col-md-12",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-danger",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " * " +
                                                                          _vm._s(
                                                                            _vm.getTransationByErrorCode(
                                                                              error.validationErrorCode
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            column.isEnebleColumnEditing
                                              ? _c(
                                                  "div",
                                                  { staticClass: "col-md-4" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "p-3 mt-3 pointer",
                                                        staticStyle: {
                                                          border:
                                                            "1px solid #f5f5f5",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.clickAddNewAnswerOption(
                                                              columnIndex
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bx bx-plus-medical bx-tada",
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "uploadProcessStep2.form.PQ.answerOptions.addNewAnswerButton"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                        column.isAllAnswersGet &&
                                        !column.clickedContinueTwo &&
                                        column.savedPredictionCount > 1
                                          ? _c(
                                              "div",
                                              { staticClass: "mt-3" },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mt-2",
                                                    attrs: {
                                                      type: "submit",
                                                      variant: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleClickOnContinuePhaseTwo(
                                                          columnIndex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "uploadProcessStep2.form.general.continueButton"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("i", {
                                                      staticClass:
                                                        "bx bx-right-arrow-alt text-success bx-tada",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        column.clickedContinueTwo
                                          ? _c("hr")
                                          : _vm._e(),
                                        column.columnType ===
                                          "PROJECTIVE_QUESTION" &&
                                        column.clickedContinueTwo
                                          ? _c("div", { staticClass: "mt-4" }, [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "uploadProcessStep2.form.PQ.selectQualitySocre.title"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    { staticClass: "row mt-3" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-12",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-md-6",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "custom-control custom-checkbox",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  column
                                                                                    .qualityScores
                                                                                    .PS,
                                                                                expression:
                                                                                  "column.qualityScores.PS",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "custom-control-input",
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                !column.isEnebleColumnEditing,
                                                                              type: "checkbox",
                                                                              id:
                                                                                "PSQualityScore" +
                                                                                column.datasetColumnIndex,
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              checked:
                                                                                Array.isArray(
                                                                                  column
                                                                                    .qualityScores
                                                                                    .PS
                                                                                )
                                                                                  ? _vm._i(
                                                                                      column
                                                                                        .qualityScores
                                                                                        .PS,
                                                                                      null
                                                                                    ) >
                                                                                    -1
                                                                                  : column
                                                                                      .qualityScores
                                                                                      .PS,
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                var $$a =
                                                                                    column
                                                                                      .qualityScores
                                                                                      .PS,
                                                                                  $$el =
                                                                                    $event.target,
                                                                                  $$c =
                                                                                    $$el.checked
                                                                                      ? true
                                                                                      : false
                                                                                if (
                                                                                  Array.isArray(
                                                                                    $$a
                                                                                  )
                                                                                ) {
                                                                                  var $$v =
                                                                                      null,
                                                                                    $$i =
                                                                                      _vm._i(
                                                                                        $$a,
                                                                                        $$v
                                                                                      )
                                                                                  if (
                                                                                    $$el.checked
                                                                                  ) {
                                                                                    $$i <
                                                                                      0 &&
                                                                                      _vm.$set(
                                                                                        column.qualityScores,
                                                                                        "PS",
                                                                                        $$a.concat(
                                                                                          [
                                                                                            $$v,
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                  } else {
                                                                                    $$i >
                                                                                      -1 &&
                                                                                      _vm.$set(
                                                                                        column.qualityScores,
                                                                                        "PS",
                                                                                        $$a
                                                                                          .slice(
                                                                                            0,
                                                                                            $$i
                                                                                          )
                                                                                          .concat(
                                                                                            $$a.slice(
                                                                                              $$i +
                                                                                                1
                                                                                            )
                                                                                          )
                                                                                      )
                                                                                  }
                                                                                } else {
                                                                                  _vm.$set(
                                                                                    column.qualityScores,
                                                                                    "PS",
                                                                                    $$c
                                                                                  )
                                                                                }
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "custom-control-label",
                                                                          attrs:
                                                                            {
                                                                              for:
                                                                                "PSQualityScore" +
                                                                                column.datasetColumnIndex,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "font-weight-normal",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.PQ.selectQualitySocre.PS.title"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "bx bx-info-circle text-info ml-1 pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleClickOnPSInfo()
                                                                            },
                                                                        },
                                                                      }),
                                                                    ]
                                                                  ),
                                                                  _vm.showPSDescription
                                                                    ? _c(
                                                                        "ul",
                                                                        {
                                                                          staticClass:
                                                                            "mt-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "li",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.PQ.selectQualitySocre.PS.description1"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "li",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.PQ.selectQualitySocre.PS.description2"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-md-6",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "custom-control custom-checkbox",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  column
                                                                                    .qualityScores
                                                                                    .CS,
                                                                                expression:
                                                                                  "column.qualityScores.CS",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "custom-control-input",
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                !column.isEnebleColumnEditing,
                                                                              type: "checkbox",
                                                                              id:
                                                                                "CSQualityScore" +
                                                                                column.datasetColumnIndex,
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              checked:
                                                                                Array.isArray(
                                                                                  column
                                                                                    .qualityScores
                                                                                    .CS
                                                                                )
                                                                                  ? _vm._i(
                                                                                      column
                                                                                        .qualityScores
                                                                                        .CS,
                                                                                      null
                                                                                    ) >
                                                                                    -1
                                                                                  : column
                                                                                      .qualityScores
                                                                                      .CS,
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                var $$a =
                                                                                    column
                                                                                      .qualityScores
                                                                                      .CS,
                                                                                  $$el =
                                                                                    $event.target,
                                                                                  $$c =
                                                                                    $$el.checked
                                                                                      ? true
                                                                                      : false
                                                                                if (
                                                                                  Array.isArray(
                                                                                    $$a
                                                                                  )
                                                                                ) {
                                                                                  var $$v =
                                                                                      null,
                                                                                    $$i =
                                                                                      _vm._i(
                                                                                        $$a,
                                                                                        $$v
                                                                                      )
                                                                                  if (
                                                                                    $$el.checked
                                                                                  ) {
                                                                                    $$i <
                                                                                      0 &&
                                                                                      _vm.$set(
                                                                                        column.qualityScores,
                                                                                        "CS",
                                                                                        $$a.concat(
                                                                                          [
                                                                                            $$v,
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                  } else {
                                                                                    $$i >
                                                                                      -1 &&
                                                                                      _vm.$set(
                                                                                        column.qualityScores,
                                                                                        "CS",
                                                                                        $$a
                                                                                          .slice(
                                                                                            0,
                                                                                            $$i
                                                                                          )
                                                                                          .concat(
                                                                                            $$a.slice(
                                                                                              $$i +
                                                                                                1
                                                                                            )
                                                                                          )
                                                                                      )
                                                                                  }
                                                                                } else {
                                                                                  _vm.$set(
                                                                                    column.qualityScores,
                                                                                    "CS",
                                                                                    $$c
                                                                                  )
                                                                                }
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "custom-control-label",
                                                                          attrs:
                                                                            {
                                                                              for:
                                                                                "CSQualityScore" +
                                                                                column.datasetColumnIndex,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "font-weight-normal",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.PQ.selectQualitySocre.CS.title"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "bx bx-info-circle text-info ml-1 pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleClickOnCSInfo()
                                                                            },
                                                                        },
                                                                      }),
                                                                    ]
                                                                  ),
                                                                  _vm.showCSDescription
                                                                    ? _c(
                                                                        "ul",
                                                                        {
                                                                          staticClass:
                                                                            "mt-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "li",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.PQ.selectQualitySocre.CS.description1"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "li",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.PQ.selectQualitySocre.CS.description2"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-12 mt-3",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-md-6",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "custom-control custom-checkbox",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  column
                                                                                    .qualityScores
                                                                                    .IS,
                                                                                expression:
                                                                                  "column.qualityScores.IS",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "custom-control-input",
                                                                          attrs:
                                                                            {
                                                                              type: "checkbox",
                                                                              id:
                                                                                "ISQualityScore" +
                                                                                column.datasetColumnIndex,
                                                                              disabled:
                                                                                !column.isEnebleColumnEditing ||
                                                                                column
                                                                                  .qualityScores
                                                                                  .SDS,
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              checked:
                                                                                Array.isArray(
                                                                                  column
                                                                                    .qualityScores
                                                                                    .IS
                                                                                )
                                                                                  ? _vm._i(
                                                                                      column
                                                                                        .qualityScores
                                                                                        .IS,
                                                                                      null
                                                                                    ) >
                                                                                    -1
                                                                                  : column
                                                                                      .qualityScores
                                                                                      .IS,
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                var $$a =
                                                                                    column
                                                                                      .qualityScores
                                                                                      .IS,
                                                                                  $$el =
                                                                                    $event.target,
                                                                                  $$c =
                                                                                    $$el.checked
                                                                                      ? true
                                                                                      : false
                                                                                if (
                                                                                  Array.isArray(
                                                                                    $$a
                                                                                  )
                                                                                ) {
                                                                                  var $$v =
                                                                                      null,
                                                                                    $$i =
                                                                                      _vm._i(
                                                                                        $$a,
                                                                                        $$v
                                                                                      )
                                                                                  if (
                                                                                    $$el.checked
                                                                                  ) {
                                                                                    $$i <
                                                                                      0 &&
                                                                                      _vm.$set(
                                                                                        column.qualityScores,
                                                                                        "IS",
                                                                                        $$a.concat(
                                                                                          [
                                                                                            $$v,
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                  } else {
                                                                                    $$i >
                                                                                      -1 &&
                                                                                      _vm.$set(
                                                                                        column.qualityScores,
                                                                                        "IS",
                                                                                        $$a
                                                                                          .slice(
                                                                                            0,
                                                                                            $$i
                                                                                          )
                                                                                          .concat(
                                                                                            $$a.slice(
                                                                                              $$i +
                                                                                                1
                                                                                            )
                                                                                          )
                                                                                      )
                                                                                  }
                                                                                } else {
                                                                                  _vm.$set(
                                                                                    column.qualityScores,
                                                                                    "IS",
                                                                                    $$c
                                                                                  )
                                                                                }
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "custom-control-label",
                                                                          attrs:
                                                                            {
                                                                              for:
                                                                                "ISQualityScore" +
                                                                                column.datasetColumnIndex,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "font-weight-normal",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.PQ.selectQualitySocre.IS.title"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "bx bx-info-circle text-info ml-1",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleClickOnISInfo()
                                                                            },
                                                                        },
                                                                      }),
                                                                    ]
                                                                  ),
                                                                  _vm.showISDescription
                                                                    ? _c(
                                                                        "ul",
                                                                        {
                                                                          staticClass:
                                                                            "mt-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "li",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.PQ.selectQualitySocre.IS.description1"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "li",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.PQ.selectQualitySocre.IS.description2"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "li",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.PQ.selectQualitySocre.IS.description3"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-md-6",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "custom-control custom-checkbox",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  column
                                                                                    .qualityScores
                                                                                    .SDS,
                                                                                expression:
                                                                                  "column.qualityScores.SDS",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "custom-control-input",
                                                                          attrs:
                                                                            {
                                                                              type: "checkbox",
                                                                              id:
                                                                                "SDSQualityScore" +
                                                                                column.datasetColumnIndex,
                                                                              disabled:
                                                                                !column.isEnebleColumnEditing ||
                                                                                column
                                                                                  .qualityScores
                                                                                  .IS,
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              checked:
                                                                                Array.isArray(
                                                                                  column
                                                                                    .qualityScores
                                                                                    .SDS
                                                                                )
                                                                                  ? _vm._i(
                                                                                      column
                                                                                        .qualityScores
                                                                                        .SDS,
                                                                                      null
                                                                                    ) >
                                                                                    -1
                                                                                  : column
                                                                                      .qualityScores
                                                                                      .SDS,
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                var $$a =
                                                                                    column
                                                                                      .qualityScores
                                                                                      .SDS,
                                                                                  $$el =
                                                                                    $event.target,
                                                                                  $$c =
                                                                                    $$el.checked
                                                                                      ? true
                                                                                      : false
                                                                                if (
                                                                                  Array.isArray(
                                                                                    $$a
                                                                                  )
                                                                                ) {
                                                                                  var $$v =
                                                                                      null,
                                                                                    $$i =
                                                                                      _vm._i(
                                                                                        $$a,
                                                                                        $$v
                                                                                      )
                                                                                  if (
                                                                                    $$el.checked
                                                                                  ) {
                                                                                    $$i <
                                                                                      0 &&
                                                                                      _vm.$set(
                                                                                        column.qualityScores,
                                                                                        "SDS",
                                                                                        $$a.concat(
                                                                                          [
                                                                                            $$v,
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                  } else {
                                                                                    $$i >
                                                                                      -1 &&
                                                                                      _vm.$set(
                                                                                        column.qualityScores,
                                                                                        "SDS",
                                                                                        $$a
                                                                                          .slice(
                                                                                            0,
                                                                                            $$i
                                                                                          )
                                                                                          .concat(
                                                                                            $$a.slice(
                                                                                              $$i +
                                                                                                1
                                                                                            )
                                                                                          )
                                                                                      )
                                                                                  }
                                                                                } else {
                                                                                  _vm.$set(
                                                                                    column.qualityScores,
                                                                                    "SDS",
                                                                                    $$c
                                                                                  )
                                                                                }
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "custom-control-label",
                                                                          attrs:
                                                                            {
                                                                              for:
                                                                                "SDSQualityScore" +
                                                                                column.datasetColumnIndex,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "font-weight-normal",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.PQ.selectQualitySocre.SDS.title"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "bx bx-info-circle text-info ml-1 pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleClickOnSDSInfo()
                                                                            },
                                                                        },
                                                                      }),
                                                                    ]
                                                                  ),
                                                                  _vm.showSDSDescription
                                                                    ? _c(
                                                                        "ul",
                                                                        {
                                                                          staticClass:
                                                                            "mt-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "li",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.PQ.selectQualitySocre.SDS.description1"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "li",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.PQ.selectQualitySocre.SDS.description2"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "li",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "uploadProcessStep2.form.PQ.selectQualitySocre.SDS.description3"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        column.columnType ===
                                          "PROJECTIVE_QUESTION" &&
                                        column.clickedContinueTwo &&
                                        (column.qualityScores.IS ||
                                          column.qualityScores.PS ||
                                          column.qualityScores.SDS ||
                                          column.qualityScores.CS)
                                          ? _c("div", { staticClass: "row" }, [
                                              !column.columnSaved
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-md-12 mt-3",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-items-center",
                                                        },
                                                        [
                                                          !column.validationInProgress
                                                            ? _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "mt-3",
                                                                  attrs: {
                                                                    type: "submit",
                                                                    variant:
                                                                      "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleClickOnSaveColumn(
                                                                          columnIndex
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "uploadProcessStep2.form.general.saveButton"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "bx bx-check text-success bx-tada",
                                                                    }),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mt-3",
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "mr-2 bx bx-loader-circle bx-spin",
                                                                  }),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "uploadProcessStep2.form.general.validationInProgress"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                          column.validationError
                                                            .displayServerError
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-danger mt-3 ml-3",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getTransationByErrorCode(
                                                                          _vm
                                                                            .errorCodesEnum
                                                                            .SERVER_ERROR
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _c("div", { staticClass: "row justify-content-center mb-5" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-block p-3",
                        attrs: {
                          variant: "success",
                          disabled: _vm.disableAddNewColumnButton,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.addNewColumnButton()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "uploadProcessStep2.form.general.addColumnButton"
                              )
                            ) +
                            " "
                        ),
                        _c("i", {
                          staticClass: "bx bx-plus-medical bx-tada ml-1",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "fixed-bottom static-buttons",
                  class: [_vm.isExpanded ? "expanded" : "collapsed"],
                },
                [
                  _c("div", { staticClass: "card-body col-md-12 row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-2 text-left" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              variant: "primary",
                              disabled: _vm.disableAddNewColumnButton,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clickedBackButton()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "mdi mdi-chevron-left" }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "uploadProcessStep2.form.general.backButton"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-md-8" }),
                    _c(
                      "div",
                      { staticClass: "col-md-2 text-right" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              variant: "primary",
                              disabled:
                                _vm.disableNextStepButton ||
                                _vm.disableAddNewColumnButton,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.preConditionForNextButton(true)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "uploadProcessStep2.form.general.nextStepButton"
                                  )
                                ) +
                                " "
                            ),
                            _c("i", { staticClass: "mdi mdi-chevron-right" }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }