<script>
import AuthService from "@/api/services/auth.service.js";
import UploadService from "@/api/services/upload.service.js";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import ColumnTypes from "@/shared/enums/columnTypes.js";
import { OES_QUESTION_TITLE_CHAR_LIMIT, MAX_OES_QUESTIONS } from "@/shared/util/const.js";
import { EventBus } from "@/shared/util/event-bus.js";
import Languages from "@/shared/util/languages.json";
import VueTagsInput from "@johmun/vue-tags-input";
import Multiselect from "vue-multiselect";
import CertificateLevelService from "../../api/services/certificateLevel.service";
import {
createWorkingMetaDataArray,
findIndexFromArray,
findIndexFromMetaDataArray,
removeExcelSupportPrefix,
sortMetaDataArray
} from "./upload-help-functions";
import {
newColumnObject,
newdataPointMetadata,
newGroupSelectorObject,
newItemBatteryRangeObject,
newOpenEndObject,
newPredictionObject,
newTimeStampObject
} from "./upload-help-objects";
import uploadInstructionCard from "./widgets/upload-instructions.vue";
import DataImportService from "@/api/services/dataImport.service.js"
import ResponseStatus from "@/shared/enums/responseStatus"
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";
import { ERROR_CODES } from "@/shared/util/errorCodes.js";

/**
 * Upload Dataset step 2
 */
export default {
  page: {
    title: "Upload Datafile",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    uploadInstructionCard,
    Multiselect,
    VueTagsInput
  },
  data() {
    return {
      isExpanded: true,
      columnTypesEnum: ColumnTypes,
      errorCodesEnum: ERROR_CODES,
      columnTypes: [
        {
          name: "uploadProcessStep2.columnTypes.dataRecordID",
          value: ColumnTypes.RESPONDENT_ID,
          $isDisabled: false
        },
        {
          name: "uploadProcessStep2.columnTypes.timeStamp",
          value: ColumnTypes.TIMESTAMP,
          $isDisabled: false
        },
        {
          name: "uploadProcessStep2.columnTypes.openEnded",
          value: ColumnTypes.OPEN_ENDED,
          $isDisabled: false
        },
        {
          name: "uploadProcessStep2.columnTypes.itemBattery",
          value: ColumnTypes.ITEMBATTERY,
          $isDisabled: false
        },
        {
          name: "uploadProcessStep2.columnTypes.projectiveQuestion",
          value: ColumnTypes.PROJECTIVE_QUESTION,
          $isDisabled: false
        }
      ],
      refereanceMetaDataArrya: [],
      workingMetaDataArray: [],
      allCertificateLevels: [],
      duplicateWorkingMetaDataArray: [],
      datafileInputObject: null,
      loggedInUser: null,
      allLanguages: Languages,
      /********* operational Variables ********/
      selectedColumns: [],
      numberOfDataPointsAlreadySelected: 0,
      disableAddNewColumnButton: false,
      disableNextStepButton: true,
      showISDescription: false,
      showPSDescription: false,
      showSDSDescription: false,
      showCSDescription: false,
      isRecordIdInclude: false,
      numItemBatteries: 0,
      tempColumnName: null,
      isDisplayLoading: false,
      OESAllQuestionTitlesThere: true,
      OESAllQuestionTitlesLengthValid: true,
      OESAllQuestionSpecifedColumns: true,
      maxOESQuestionsReached: false,
      oesQuestionTitleCharLimit: OES_QUESTION_TITLE_CHAR_LIMIT,
      itembatteryIndexForIndentifer: 0,
      isEnableWrongTopicCheck: false,
      enableDuplicateChecks: true,
      enablePatternCheckMain: false,
      enablePatternCheckAdditional: []
    };
  },
  async mounted() {
    // activate Loading
    this.isDisplayLoading = true;

    // getLoggedInUser
    this.loggedInUser = await AuthService.getLoggedInUser();

    //add certificate level related logics
    const unsortedDbCertificateLevels =
      await CertificateLevelService.getAllCertificateLevels();
    this.allCertificateLevels = unsortedDbCertificateLevels.sort(
      (levelA, levelB) => {
        return levelA.order - levelB.order;
      }
    );

    // get Items from localstorage and set to local variable
    const selectedColumnsFromLocalStorage = JSON.parse(
      localStorage.getItem("selectedColumns")
    );
    const workingMetaDataArrayFromLocalStorage = JSON.parse(
      localStorage.getItem("workingMetaDataArray")
    );

    const datafileInputObjectFromLocalStorage = JSON.parse(
      localStorage.getItem("datafileInputObject")
    );

    const enableWrongTopicFromLocalStorage = JSON.parse(
      localStorage.getItem("isEnableWrongTopicCheckInUpload")
    );
    this.enableDuplicateChecks = JSON.parse(localStorage.getItem("enableDuplicateChecksInUpload")) ?? true;
    this.enablePatternCheckMain = JSON.parse(localStorage.getItem("enablePatternCheckMain")) ?? false;
    this.enablePatternCheckAdditional = JSON.parse(localStorage.getItem("enablePatternCheckAdditional")) ?? [];
    if (enableWrongTopicFromLocalStorage) {
        this.isEnableWrongTopicCheck = enableWrongTopicFromLocalStorage;
      }

    if (
      selectedColumnsFromLocalStorage &&
      selectedColumnsFromLocalStorage.length > 0
    ) {
      this.selectedColumns = [...selectedColumnsFromLocalStorage];

      for (let i = 0; i < this.selectedColumns.length; i++) {
        if (this.selectedColumns[i].columnType === ColumnTypes.RESPONDENT_ID) {
          this.isRecordIdInclude = true;
          for (let j = 0; j < this.columnTypes.length; j++) {
            if (this.columnTypes[j].value === ColumnTypes.RESPONDENT_ID) {
              this.columnTypes[j].$isDisabled = true;
            }
          }
        }

        if (this.selectedColumns[i].columnType === ColumnTypes.FILTER) {
          this.numberOfFiltersAlreadySelected += 1;
          if (
            this.maximumNumberOfFilters === this.numberOfFiltersAlreadySelected
          ) {
            for (let j = 0; j < this.columnTypes.length; j++) {
              if (this.columnTypes[j].value === ColumnTypes.FILTER) {
                this.columnTypes[j].$isDisabled = true;
              }
            }
          }
        }
        if (this.selectedColumns[i].columnType === ColumnTypes.PROJECTIVE_QUESTION) {
          this.numberOfDataPointsAlreadySelected += 1;
        }
        if (this.selectedColumns[i].columnType === ColumnTypes.TIMESTAMP) {
          this.numberOfDataPointsAlreadySelected += 1;

          for (let j = 0; j < this.columnTypes.length; j++) {
            if (this.columnTypes[j].value === ColumnTypes.TIMESTAMP) {
              this.columnTypes[j].$isDisabled = true;
            }
          }
        }
        if (this.selectedColumns[i].columnType === ColumnTypes.OPEN_ENDED) {
          this.numberOfDataPointsAlreadySelected += 1;

          for (let j = 0; j < this.columnTypes.length; j++) {
            if (this.columnTypes[j].value === ColumnTypes.OPEN_ENDED) {
              this.columnTypes[j].$isDisabled = true;
            }
          }
        }
        if (this.selectedColumns[i].columnType === ColumnTypes.ITEMBATTERY) {
          this.numberOfDataPointsAlreadySelected += 1;

          for (let j = 0; j < this.columnTypes.length; j++) {
            if (this.columnTypes[j].value === ColumnTypes.ITEMBATTERY) {
              this.columnTypes[j].$isDisabled = true;
            }
          }
        }
      }

      if (
        this.numberOfDataPointsAlreadySelected > 0 &&
        this.isRecordIdInclude
      ) {
        this.disableNextStepButton = false;
      }
    }

    if (workingMetaDataArrayFromLocalStorage) {
      this.workingMetaDataArray = [...workingMetaDataArrayFromLocalStorage];
    }

    if (datafileInputObjectFromLocalStorage) {
      this.datafileInputObject = datafileInputObjectFromLocalStorage;
    }

    // Disable Loading
    this.isDisplayLoading = false;
  },
  async created() {
    //check whatever settings saved on navbar on page load
    const navBarSetting = window.localStorage.getItem("navBarSetting");
    if (navBarSetting) this.isExpanded = navBarSetting == "expandNav";

    EventBus.$on("sidebar-toggle-action", async () => {
      this.isExpanded = !this.isExpanded;
    });

    // get meta data array which stored in localstorage
    const datafileMetaDataArray = JSON.parse(
      localStorage.getItem("datafileMetaDataArray")
    );

    const workingMetaDataArrayFromLocalStorage = JSON.parse(
      localStorage.getItem("workingMetaDataArray")
    );

    const datafileInputObjectFromLocalStorage = JSON.parse(
      localStorage.getItem("datafileInputObject")
    );

    if (datafileMetaDataArray) {
      this.refereanceMetaDataArrya = datafileMetaDataArray;
    }

    if (!workingMetaDataArrayFromLocalStorage) {
      // create working Meta Data Arrya
      this.workingMetaDataArray = await createWorkingMetaDataArray(
        this.refereanceMetaDataArrya
      );
    }

    // create Duplicate Working MetaData Array
    this.duplicateWorkingMetaDataArray = await createWorkingMetaDataArray(
      this.refereanceMetaDataArrya
    );

    if (datafileInputObjectFromLocalStorage) {
      this.datafileInputObject = datafileInputObjectFromLocalStorage;
    }
  },
  computed: {
    items() {
      const items = [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: this.$t("uploadProcessStep2.pageTitle"),
          active: true
        }
      ];
      return items;
    },
    propoverMethodOESQuestion() {
      return {
        html: true,
        content: () =>
          this.$i18n.t(
            "uploadProcessStep2.form.OES.questionDescription.point1"
          )
      };
    },
    propoverMethodOESExpectedLanguages() {
      return {
        html: true,
        content: () =>
          "<ul> <li>" +
          this.$i18n.t(
            "uploadProcessStep2.form.OES.selectExpectedLanguage.description.point1"
          ) +
          "</li> <br/> <li>" +
          this.$i18n.t(
            "uploadProcessStep2.form.OES.selectExpectedLanguage.description.point2"
          ) +
          "</li> <br/> <li>" +
          this.$i18n.t(
            "uploadProcessStep2.form.OES.selectExpectedLanguage.description.point3"
          ) + "</li> </ul>"
      };
    },
    propoverMethodOESQKeywords() {
      return {
        html: true,
        content: () =>
          "<ul> <li>" +
          this.$i18n.t(
            "uploadProcessStep2.form.OES.wrongTopic.keywords.description.point1"
          ) +
          "</li> <br/> <li>" +
          this.$i18n.t(
            "uploadProcessStep2.form.OES.wrongTopic.keywords.description.point2"
          ) +
          "</li> <br/> <li>" +
          this.$i18n.t(
            "uploadProcessStep2.form.OES.wrongTopic.keywords.description.point3"
          ) +
          "</li></ul>"
      };
    },
    propoverMethodWrongTopicEnable() {
      return {
        html: true,
        content: () =>
          "<ul> <li>" +
          this.$i18n.t(
            "uploadProcessStep2.form.OES.wrongTopic.checkbox.point1"
          ) +
          "</li> <br/> <li>" +
          this.$i18n.t(
            "uploadProcessStep2.form.OES.wrongTopic.checkbox.point2"
          ) +
          "</li> <br/> <li>" +
          this.$i18n.t(
            "uploadProcessStep2.form.OES.wrongTopic.checkbox.point3"
          ) +
          "</li> </ul>"
      };
    },
    popoverMethodDuplicateChecks() {
      return {
        html: true,
        content: () =>
          "<ul> <li>" +
            this.$i18n.t(
              "uploadProcessStep2.form.OES.duplicateChecks.description.text1"
            ) +
          "</li> <br/> <li>" +
            this.$i18n.t(
              "uploadProcessStep2.form.OES.duplicateChecks.description.text2"
            ) +
          "</li> </ul>"
      };
    },
    popoverMethodPatternChecks() {
      return {
        html: true,
        content: () =>
          "<ul> <li>" +
            this.$i18n.t(
              "uploadProcessStep2.form.IBS.patternCheck.description.text1"
            ) +
          "</li> <br/> <li>" +
            this.$i18n.t(
              "uploadProcessStep2.form.IBS.patternCheck.description.text2"
            ) +
          "</li> </ul>"
      };
    }
  },
  methods: {
    addNewColumnButton() {
      // meka Add New Column Button disable
      this.disableAddNewColumnButton = true;
      // create object to pass selectedColumns Arrya
      const columnObject = new newColumnObject();
      // push created object to selectedColumns Arrya
      this.selectedColumns.push(columnObject);
    },
    deleteColumnRange(start, numElements) {
      this.workingMetaDataArray.splice(start, numElements);
    },
    addColumnRange(elements) {
      this.workingMetaDataArray.push(...elements);
      let getSortedMetaDataArrayFromFunction = sortMetaDataArray(
        this.workingMetaDataArray
      );
      this.workingMetaDataArray = [...getSortedMetaDataArrayFromFunction];
    },
    selectDatasetColumn(index) {
      const selectedColumn = this.selectedColumns[index];

      // after user select specific column from dropdown
      // 1. set value to the datasetColumnIndex
      const datasetColumnIndex = findIndexFromArray(
        selectedColumn.datasetColumnName,
        this.refereanceMetaDataArrya
      );
      selectedColumn.datasetColumnIndex = datasetColumnIndex;
      selectedColumn.renamedColumnName =
        this.selectedColumns[index].datasetColumnName;

      if (
        selectedColumn.columnType === "ITEMBATTERY" &&
        selectedColumn.itemBatteryEndRangeName
      ) {
        // If it goes to here, it means we are selecting item battery end range
        const itemBatteryEndRangeIndex = this.workingMetaDataArray.indexOf(
          selectedColumn.itemBatteryEndRangeName
        );

        //reset the error
        selectedColumn.endRangeError = false;

        const startRangeIndex = findIndexFromArray(
          selectedColumn.datasetColumnName,
          this.refereanceMetaDataArrya
        );

        const endRangeIndex = findIndexFromArray(
          selectedColumn.itemBatteryEndRangeName,
          this.refereanceMetaDataArrya
        );

        const numItems = endRangeIndex - startRangeIndex + 1;

        // item battery should have atleast 7 items
        if (numItems >= 7) {
          const itemBatterySecondElementIndex =
            itemBatteryEndRangeIndex - numItems + 2;

          const entireColumnRange = [];
          for (let i = startRangeIndex; i <= endRangeIndex; i++) {
            entireColumnRange.push(this.duplicateWorkingMetaDataArray[i]);
          }

          selectedColumn.itemBatteryColumnNames.push(...entireColumnRange);
          this.deleteColumnRange(itemBatterySecondElementIndex, numItems - 1);
          selectedColumn.itemBatteryEndRangeIndex = endRangeIndex;
        } else {
          selectedColumn.itemBatteryEndRangeName = null;
          selectedColumn.endRangeError = true;
        }
      } else {
        //set temp column name to remove it later
        this.tempColumnName = selectedColumn.datasetColumnName;
        this.removeValueFromDropdown();
      }

      if(selectedColumn.columnType === ColumnTypes.OPEN_ENDED) {
        this.checkOESQuestionTitles()
      }
    },
    selectColumnType(index) {
      // set value to columnType by using columnTypObject
      const column = this.selectedColumns[index];
      column.columnType = column.columnTypeObject.value;
      if (column.columnType === "ITEMBATTERY") this.numItemBatteries += 1;
    },
    async handleClickOnSaveColumn(index) {
      this.selectedColumns[index].validationInProgress = true
      this.selectedColumns[index].validationError.displayServerError = false;
      this.selectedColumns[index].columnSavedCount += 1;

      // initial validation input
      const validationInput = {
        datafileKey: this.datafileInputObject.datafileKey,
        headerRow: this.datafileInputObject.metadata.headerRow,
        dataRecordStartRow: this.datafileInputObject.metadata.dataRecordStartRow,
        sheetName: this.datafileInputObject.metadata.sheetName,
        missingValues: this.datafileInputObject.metadata.missingValues,
        dataPointType: null,
        dataRecordIDColumnIndex: null,
        itemBatteryRanges: [],
        projectiveQuestionVoteColunIndex: null,
        projectiveQuestionPredictionColumns: [],
        timeDurationColumnIndexes: [],
        openEndedColumnIndexes: [],
        groupingColumnIndex: null
      }

      // if saved column type is RESPONDENT_ID
      if (
        this.selectedColumns[index].columnType === ColumnTypes.RESPONDENT_ID
      ) {
        this.isRecordIdInclude = true;
        for (let i = 0; i < this.columnTypes.length; i++) {
          if (this.columnTypes[i].value === ColumnTypes.RESPONDENT_ID) {
            this.columnTypes[i].$isDisabled = true;
          }
        }

        // set values for validate input
        validationInput.dataPointType = this.selectedColumns[index].columnType
        validationInput.dataRecordIDColumnIndex = this.selectedColumns[index].datasetColumnIndex
      }

      // if saved column type is QUESTION
      if (this.selectedColumns[index].columnType === ColumnTypes.PROJECTIVE_QUESTION) {
        if (this.selectedColumns[index].columnSavedCount === 1) {
          this.numberOfDataPointsAlreadySelected += 1;
        }

        // set values for validate input
        validationInput.dataPointType = "PROJECTIVE_QUESTION"
        validationInput.projectiveQuestionVoteColunIndex = this.selectedColumns[index].datasetColumnIndex
        for(let i=0; i < this.selectedColumns[index].predictionsList.length; i++) {
          let projectiveQuestionPredictionsColumn = {
            answer: String(this.selectedColumns[index].predictionsList[i].answer),
            columnIndex: this.selectedColumns[index].predictionsList[i].datasetColumnIndex
          }
          validationInput.projectiveQuestionPredictionColumns.push(projectiveQuestionPredictionsColumn)
        }
      }

      // if saved column type is TIME_STAMP
      if (this.selectedColumns[index].columnType === ColumnTypes.TIMESTAMP) {
        if (this.selectedColumns[index].columnSavedCount === 1) {
          this.numberOfDataPointsAlreadySelected += 1;
        }

        for (let i = 0; i < this.columnTypes.length; i++) {
          if (this.columnTypes[i].value === ColumnTypes.TIMESTAMP) {
            this.columnTypes[i].$isDisabled = true;
          }
        }

        // set values for validate input
        validationInput.dataPointType = this.selectedColumns[index].columnType
        validationInput.timeDurationColumnIndexes.push(this.selectedColumns[index].datasetColumnIndex) 

        // remove empty additional Time Stamps
        let supportTimeStampArray = [];
        for (
          let i = 0;
          i < this.selectedColumns[index].timeStampsList.length;
          i++
        ) {
          if (this.selectedColumns[index].timeStampsList[i].datasetColumnName) {
            supportTimeStampArray.push(
              this.selectedColumns[index].timeStampsList[i]
            );

            // set values for validate input
            validationInput.timeDurationColumnIndexes.push(this.selectedColumns[index].timeStampsList[i].datasetColumnIndex)
          }
        }
        this.selectedColumns[index].timeStampsList = [...supportTimeStampArray];

        // remove empty group Selectors
        let supportGroupSelectorArray = [];
        for (
          let j = 0;
          j < this.selectedColumns[index].groupSelector.length;
          j++
        ) {
          if (this.selectedColumns[index].groupSelector[j].datasetColumnName) {
            supportGroupSelectorArray.push(
              this.selectedColumns[index].groupSelector[j]
            );

            // set values for validate input
            validationInput.groupingColumnIndex = this.selectedColumns[index].groupSelector[j].datasetColumnIndex
          }
        }
        this.selectedColumns[index].groupSelector = [
          ...supportGroupSelectorArray
        ];
      }

      // if saved column type is ITEMBATTERY
      if (this.selectedColumns[index].columnType === ColumnTypes.ITEMBATTERY) {
        this.numberOfDataPointsAlreadySelected += 1;
        this.selectedColumns[index].itemBatteryIndex = this.numItemBatteries;

        for (let i = 0; i < this.columnTypes.length; i++) {
          if (this.columnTypes[i].value === ColumnTypes.ITEMBATTERY) {
            this.columnTypes[i].$isDisabled = true;
          }
        }

        // set values for validate input
        validationInput.dataPointType = this.selectedColumns[index].columnType
        validationInput.itemBatteryRanges = [ {rangeStart: this.selectedColumns[index].datasetColumnIndex, rangeEnd: this.selectedColumns[index].itemBatteryEndRangeIndex} ]

        for(let i=0; i< this.selectedColumns[index].itemBatteryRanges.length; i++) {
          validationInput.itemBatteryRanges.push({
            rangeStart:this.selectedColumns[index].itemBatteryRanges[i].rangeStartIndex, 
            rangeEnd:this.selectedColumns[index].itemBatteryRanges[i].rangeEndIndex
          })
        }
      }

      // if saved column type is OPEN ENDED
      if (this.selectedColumns[index].columnType === ColumnTypes.OPEN_ENDED) {
        if (this.selectedColumns[index].columnSavedCount === 1) {
          this.numberOfDataPointsAlreadySelected += 1;
        }

        // set values for validate input
        validationInput.dataPointType = this.selectedColumns[index].columnType
        validationInput.openEndedColumnIndexes.push(this.selectedColumns[index].datasetColumnIndex)

        for (let i = 0; i < this.columnTypes.length; i++) {
          if (this.columnTypes[i].value === ColumnTypes.OPEN_ENDED) {
            this.columnTypes[i].$isDisabled = true;
          }
        }

        // remove empty additional Open Ended
        let supportOpenEnded = [];
        for (
          let i = 0;
          i < this.selectedColumns[index].openEndedList.length;
          i++
        ) {
          if (this.selectedColumns[index].openEndedList[i].datasetColumnName) {
            supportOpenEnded.push(this.selectedColumns[index].openEndedList[i]);
            validationInput.openEndedColumnIndexes.push(this.selectedColumns[index].openEndedList[i].datasetColumnIndex)
          }
        }
        this.selectedColumns[index].openEndedList = [...supportOpenEnded];
      }

      // run validation
      if(this.selectedColumns[index].columnType === ColumnTypes.ITEMBATTERY) {
        await this.validateIBSUserInput(validationInput,index)
      } else if(this.selectedColumns[index].columnType === ColumnTypes.RESPONDENT_ID) {
        await this.validateDataRecordIDUserInput(validationInput,index)
      } else if(this.selectedColumns[index].columnType === ColumnTypes.TIMESTAMP) {
        await this.validateTimestampsInputs(validationInput,index)
      } else if(this.selectedColumns[index].columnType === ColumnTypes.OPEN_ENDED) {
        await this.validateOpenEndedInputs(validationInput,index)
      } else if(this.selectedColumns[index].columnType === ColumnTypes.PROJECTIVE_QUESTION) {
        await this.validateProjectiveQuestionInputs(validationInput,index)
      }else {
        this.selectedColumns[index].columnSaved = true;
        this.selectedColumns[index].isEnebleColumnEditing = false;
        this.selectedColumns[index].clickedContinueOne = true;
        this.disableAddNewColumnButton = false;
        this.selectedColumns[index].validationInProgress = false
        this.selectedColumns[index].isDisplayValidationError = false
      }

      if (
        this.numberOfDataPointsAlreadySelected > 0 &&
        this.isRecordIdInclude
      ) {
        this.disableNextStepButton = false;
      }

    },
    handleClickOnContinuePhaseTwo(index) {
      // 1. make clickedContinueOne true
      this.selectedColumns[index].clickedContinueTwo = true;
    },
    removeValueFromDropdown() {
      // remove slected Column from WorkingMetaDataArray
      let getIndexToDeleteFromWorkingMetaDataArray;
      getIndexToDeleteFromWorkingMetaDataArray =
        this.workingMetaDataArray.indexOf(this.tempColumnName);
      this.workingMetaDataArray.splice(
        getIndexToDeleteFromWorkingMetaDataArray,
        1
      );
      this.tempColumnName = null; //used for editing puposes
    },
    async clickedContinueOneQuestion(index) {
      //remove the selected value fromn working metadata
      this.selectedColumns[index].clickedContinueOne = true;
      this.selectedColumns[index].isAllAnswersGet = false;
      this.selectedColumns[index].numberOfAnswers = 0;
      this.selectedColumns[index].answersList = null;
      this.selectedColumns[index].predictionsList = [];
      await this.findAnswers(index);
      this.selectedColumns[index].isAllAnswersGet = true;
    },
    clickedContinueOneTimestamp(index) {
      //remove the selected value fromn working metadata
      this.selectedColumns[index].clickedContinueOne = true;
    },
    clickedContinueOneOpenEnded(index) {
      //remove the selected value fromn working metadata
      this.selectedColumns[index].clickedContinueOne = true;
    },
    clickedContinueOneItemBattery(index) {
      //remove the selected value fromn working metadata
      this.selectedColumns[index].clickedContinueOne = true;
    },
    clickAddNewTimeStampColumn(index) {
      this.selectedColumns[index].timeStampsList.push(new newTimeStampObject());
    },
    clickAddNewOpenEndedColumn(index) {
      let newOESObjectToPush = new newOpenEndObject();
      newOESObjectToPush.languagesToCheck =
        this.selectedColumns[index].languagesToCheck;
      newOESObjectToPush.keywords =
        this.selectedColumns[index].keywords;
        newOESObjectToPush.keywordsTag =
        this.selectedColumns[index].keywordsTag;
      this.selectedColumns[index].openEndedList.push(newOESObjectToPush);

      this.checkOESQuestionTitles();
      if (this.selectedColumns[index].openEndedList.length + 1 >= MAX_OES_QUESTIONS) {
        this.maxOESQuestionsReached = true;
      }
    },
    clickAddAdditionalRange(index) {
      this.selectedColumns[index].itemBatteryRanges.push(
        new newItemBatteryRangeObject()
      );
      this.enablePatternCheckAdditional.push(false);
    },
    selectAdditionalTimeStampColumn(columnIndex, timeStampIndex) {
      // after user select specific column from dropdown
      // 1. set value to the datasetColumnIndex
      // 2. remove slected Column from WorkingMetaDataArray
      const datasetColumnIndex = findIndexFromArray(
        this.selectedColumns[columnIndex].timeStampsList[timeStampIndex]
          .datasetColumnName,
        this.refereanceMetaDataArrya
      );
      this.selectedColumns[columnIndex].timeStampsList[
        timeStampIndex
      ].datasetColumnIndex = datasetColumnIndex;

      let getIndexToDeleteFromWorkingMetaDataArray =
        this.workingMetaDataArray.indexOf(
          this.selectedColumns[columnIndex].timeStampsList[timeStampIndex]
            .datasetColumnName
        );
      this.workingMetaDataArray.splice(
        getIndexToDeleteFromWorkingMetaDataArray,
        1
      );
    },
    removeAdditionalTimeStampColumn(columnIndex, timeStampIndex) {
      if (
        this.selectedColumns[columnIndex].timeStampsList[timeStampIndex]
          .datasetColumnName &&
        !this.workingMetaDataArray.includes(
          this.selectedColumns[columnIndex].timeStampsList[timeStampIndex]
            .datasetColumnName
        )
      ) {
        this.workingMetaDataArray.push(
          this.selectedColumns[columnIndex].timeStampsList[timeStampIndex]
            .datasetColumnName
        );
      }

      let getSortedMetaDataArrayFromFunction = sortMetaDataArray(
        this.workingMetaDataArray
      );
      this.workingMetaDataArray = [...getSortedMetaDataArrayFromFunction];
      this.selectedColumns[columnIndex].timeStampsList.splice(
        timeStampIndex,
        1
      );
    },
    //OES related
    selectAdditionalOpenEndedColumn(columnIndex, openEndedIndex) {
      // after user select specific column from dropdown
      // 1. set value to the datasetColumnIndex
      // 2. remove slected Column from WorkingMetaDataArray
      const datasetColumnIndex = findIndexFromArray(
        this.selectedColumns[columnIndex].openEndedList[openEndedIndex]
          .datasetColumnName,
        this.refereanceMetaDataArrya
      );
      this.selectedColumns[columnIndex].openEndedList[
        openEndedIndex
      ].datasetColumnIndex = datasetColumnIndex;

      let getIndexToDeleteFromWorkingMetaDataArray =
        this.workingMetaDataArray.indexOf(
          this.selectedColumns[columnIndex].openEndedList[openEndedIndex]
            .datasetColumnName
        );
      this.workingMetaDataArray.splice(
        getIndexToDeleteFromWorkingMetaDataArray,
        1
      );

      this.checkOESQuestionTitles()
    },
    removeAdditionalOpenEndedColumn(columnIndex, openEndedIndex) {
      if (
        this.selectedColumns[columnIndex].openEndedList[openEndedIndex]
          .datasetColumnName &&
        !this.workingMetaDataArray.includes(
          this.selectedColumns[columnIndex].openEndedList[openEndedIndex]
            .datasetColumnName
        )
      ) {
        this.workingMetaDataArray.push(
          this.selectedColumns[columnIndex].openEndedList[openEndedIndex]
            .datasetColumnName
        );
      }

      let getSortedMetaDataArrayFromFunction = sortMetaDataArray(
        this.workingMetaDataArray
      );
      this.workingMetaDataArray = [...getSortedMetaDataArrayFromFunction];
      this.selectedColumns[columnIndex].openEndedList.splice(openEndedIndex, 1);
      this.checkOESQuestionTitles();
      if (this.selectedColumns[columnIndex].openEndedList.length + 1 <= MAX_OES_QUESTIONS) {
        this.maxOESQuestionsReached = false;
      }
    },
    checkOESQuestionTitles() {
      this.OESAllQuestionTitlesThere = true;
      this.OESAllQuestionTitlesLengthValid = true;
      this.OESAllQuestionSpecifedColumns = true;
      for (const column of this.selectedColumns) {
        if (column.columnType === ColumnTypes.OPEN_ENDED) {
          if (!column.datasetColumnName) this.OESAllQuestionSpecifedColumns = false;
          if (!column.questionTitle) this.OESAllQuestionTitlesThere = false;
          if (column.questionTitle?.length > this.oesQuestionTitleCharLimit)
            this.OESAllQuestionTitlesLengthValid = false;
          for (const el of column.openEndedList) {
            if (!el.datasetColumnName) this.OESAllQuestionSpecifedColumns = false;
            if (!el.questionTitle) this.OESAllQuestionTitlesThere = false;
            if (el.questionTitle?.length > this.oesQuestionTitleCharLimit)
              this.OESAllQuestionTitlesLengthValid = false;
          }
        }
      }
    },
    selectAdditionalIBRange(columnIndex, IBIndex, isStartIndex) {
      const selectedCol = this.selectedColumns[columnIndex];
      if (isStartIndex) {
        const startIndex = findIndexFromArray(
          selectedCol.itemBatteryRanges[IBIndex].rangeStartName,
          this.refereanceMetaDataArrya
        );
        selectedCol.itemBatteryRanges[IBIndex].rangeStartIndex = startIndex;
        const indexToDelete = this.workingMetaDataArray.indexOf(
          selectedCol.itemBatteryRanges[IBIndex].rangeStartName
        );
        this.workingMetaDataArray.splice(indexToDelete, 1);
      } else {
        // This logic is for end index
        selectedCol.itemBatteryRanges[IBIndex].endRangeError = false;

        const startIndex = findIndexFromArray(
          selectedCol.itemBatteryRanges[IBIndex].rangeStartName,
          this.refereanceMetaDataArrya
        );
        const endIndex = findIndexFromArray(
          selectedCol.itemBatteryRanges[IBIndex].rangeEndName,
          this.refereanceMetaDataArrya
        );
        const numItems = endIndex - startIndex + 1;

        if (numItems >= 7) {
          selectedCol.itemBatteryRanges[IBIndex].rangeEndIndex = endIndex;
          let entireColumnRange = [];
          for (
            let i = selectedCol.itemBatteryRanges[IBIndex].rangeStartIndex;
            i <= selectedCol.itemBatteryRanges[IBIndex].rangeEndIndex;
            i++
          ) {
            entireColumnRange.push(this.duplicateWorkingMetaDataArray[i]);
          }
          selectedCol.itemBatteryRanges[IBIndex].columnNames.push(
            ...entireColumnRange
          );
          const itemBatteryEndRangeIndex = this.workingMetaDataArray.indexOf(
            selectedCol.itemBatteryRanges[IBIndex].rangeEndName
          );

          const itemBatterySecondElementIndex =
            itemBatteryEndRangeIndex - numItems + 2;
          this.deleteColumnRange(itemBatterySecondElementIndex, numItems - 1);
        } else {
          selectedCol.itemBatteryRanges[IBIndex].rangeEndName = null;
          selectedCol.itemBatteryRanges[IBIndex].endRangeError = true;
        }
      }
    },
    removeAdditionalIBRange(columnIndex, IBIndex, isStartIndex) {
      const selectedCol = this.selectedColumns[columnIndex];
      if (isStartIndex) {
        this.workingMetaDataArray.push(
          selectedCol.itemBatteryRanges[IBIndex].rangeStartName
        );
        selectedCol.itemBatteryRanges[IBIndex].rangeStartIndex = null;
        selectedCol.itemBatteryRanges[IBIndex].rangeStartName = null;
      } else {
        this.workingMetaDataArray.push(
          selectedCol.itemBatteryRanges[IBIndex].rangeEndName
        );
        selectedCol.itemBatteryRanges[IBIndex].rangeEndIndex = null;
        selectedCol.itemBatteryRanges[IBIndex].rangeEndName = null;
      }
      let getSortedMetaDataArrayFromFunction = sortMetaDataArray(
        this.workingMetaDataArray
      );
      this.workingMetaDataArray = [...getSortedMetaDataArrayFromFunction];
    },
    removeAdditionalIBRangeFull(columnIndex, IBIndex) {
      this.removeAdditionalIBRange(columnIndex, IBIndex, true);
      this.removeAdditionalIBRange(columnIndex, IBIndex, false);
      for (
        let i = 0;
        i <
        this.selectedColumns[columnIndex].itemBatteryRanges[IBIndex].columnNames
          .length;
        i++
      ) {
        const colName =
          this.selectedColumns[columnIndex].itemBatteryRanges[IBIndex]
            .columnNames[i];
        if (!this.workingMetaDataArray.includes(colName))
          this.workingMetaDataArray.push(colName);
      }
      this.selectedColumns[columnIndex].itemBatteryRanges.splice(IBIndex, 1);
      let getSortedMetaDataArrayFromFunction = sortMetaDataArray(
        this.workingMetaDataArray
      );
      this.workingMetaDataArray = [...getSortedMetaDataArrayFromFunction];
    },
    clickAddGroupSelectionColumn(index) {
      this.selectedColumns[index].groupSelector.push(
        new newGroupSelectorObject()
      );
    },
    selectGroupSelectorColumn(columnIndex, groupSelectorIndex) {
      // after user select specific column from dropdown
      // 1. set value to the datasetColumnIndex
      // 2. remove slected Column from WorkingMetaDataArray

      const datasetColumnIndex = findIndexFromArray(
        this.selectedColumns[columnIndex].groupSelector[groupSelectorIndex]
          .datasetColumnName,
        this.refereanceMetaDataArrya
      );
      this.selectedColumns[columnIndex].groupSelector[
        groupSelectorIndex
      ].datasetColumnIndex = datasetColumnIndex;

      let getIndexToDeleteFromWorkingMetaDataArray =
        this.workingMetaDataArray.indexOf(
          this.selectedColumns[columnIndex].groupSelector[groupSelectorIndex]
            .datasetColumnName
        );
      this.workingMetaDataArray.splice(
        getIndexToDeleteFromWorkingMetaDataArray,
        1
      );
    },
    removeGroupSelectorColumn(columnIndex, groupSelectorIndex) {
      if (
        this.selectedColumns[columnIndex].groupSelector[groupSelectorIndex]
          .datasetColumnName &&
        !this.workingMetaDataArray.includes(
          this.selectedColumns[columnIndex].groupSelector[groupSelectorIndex]
            .datasetColumnName
        )
      ) {
        this.workingMetaDataArray.push(
          this.selectedColumns[columnIndex].groupSelector[groupSelectorIndex]
            .datasetColumnName
        );
      }

      let getSortedMetaDataArrayFromFunction = sortMetaDataArray(
        this.workingMetaDataArray
      );
      this.workingMetaDataArray = [...getSortedMetaDataArrayFromFunction];
      this.selectedColumns[columnIndex].groupSelector.splice(
        groupSelectorIndex,
        1
      );
    },
    async findAnswers(index) {
      // get Answer options from Datafile through getAllAnswerOptions Query
      const datafileInputObject = JSON.parse(
        localStorage.getItem("datafileInputObject")
      );

      // Answer options from this query will be sorted numerically, ascending
      let answerOptionsFromQuery = await UploadService.getAllAnswerOptions(
        datafileInputObject.datafileKey,
        this.selectedColumns[index].datasetColumnIndex,
        Number(datafileInputObject.metadata.dataRecordStartRow),
        datafileInputObject.metadata.sheetName
      );

      if(answerOptionsFromQuery.length > 0) {
        let detectTextInAnswerColumn = answerOptionsFromQuery.some(isNaN);
 
        if(detectTextInAnswerColumn) {
          this.selectedColumns[index].isAnswerListHavingAnError = true;
          this.selectedColumns[index].validationError.isDisplayValidationError = true;
          this.selectedColumns[index].validationError.errors.push(
            {
              validationErrorCode: ERROR_CODES.VOTE_NON_NUMERIC,
              validationErrorMessage: getErrorMessageByErrorCode(ERROR_CODES.VOTE_NON_NUMERIC)
            }
          )
        } else {
          this.selectedColumns[index].numberOfAnswers = answerOptionsFromQuery.length;

          const newAnswerOptions = [];

          // answer index starting from 1
          let aI = 1;
          for (const answer of answerOptionsFromQuery) {
            if (!Number.isNaN(Number(answer))) {
              // Success case (answer should be number)
              newAnswerOptions.push(Number(answer));
              aI = Number(answer);
            } else {
              // Failure case
              newAnswerOptions.push(aI + 1);
              aI++;
            }
          }

          this.selectedColumns[index].answersList = newAnswerOptions;
          this.selectedColumns[index].isAllAnswersGet = true;

          // set data for prediction List
          for (let i = 0; i < newAnswerOptions.length; i++) {
            let predictionObject = new newPredictionObject();
            predictionObject.predictionQuestionReferenceIndex =
              this.selectedColumns[index].datasetColumnIndex;
            predictionObject.predictionQuestionReferenceTitle =
              this.selectedColumns[index].datasetColumnName;
            predictionObject.answer = newAnswerOptions[i];
            this.selectedColumns[index].predictionsList.push(predictionObject);
          }

          if (newAnswerOptions.length < this.selectedColumns[index].numberOfPredictions) {
            // Find Missing answer
            let missingAnswers = [];
            for (let i = 1; i <= this.selectedColumns[index].numberOfPredictions; i++ ) {
              if (newAnswerOptions.indexOf(i) === -1) {
                missingAnswers.push(i);
              }
            }

            // create prediction from missing answer
            if (missingAnswers.length > 0) {
              for (let i = 0; i < missingAnswers.length; i++) {
                let predictionObject = new newPredictionObject();
                predictionObject.predictionQuestionReferenceIndex =
                  this.selectedColumns[index].datasetColumnIndex;
                predictionObject.predictionQuestionReferenceTitle =
                  this.selectedColumns[index].datasetColumnName;
                predictionObject.answer = missingAnswers[i];
                this.selectedColumns[index].answersList.push(missingAnswers[i]);
                this.selectedColumns[index].predictionsList.push(
                  predictionObject
                );
              }
            }
          }
        }
      } else {
        this.selectedColumns[index].isAnswerListHavingAnError = true;
        this.selectedColumns[index].validationError.isDisplayValidationError = true;
        this.selectedColumns[index].validationError.errors.push(
          {
              validationErrorCode: ERROR_CODES.VOTE_COLUMN_EMPTY,
              validationErrorMessage: getErrorMessageByErrorCode(ERROR_CODES.VOTE_COLUMN_EMPTY)
            }
        )
      }
    },
    clickAddNewAnswerOption(index) {
      let num =
        Number(
          this.selectedColumns[index].answersList[
            this.selectedColumns[index].answersList.length - 1
          ]
        ) + 1;
      if (Number.isNaN(num))
        num = this.selectedColumns[index].answersList.length + 1;
      this.selectedColumns[index].answersList.push(num);

      let predictionObject = new newPredictionObject();
      predictionObject.predictionQuestionReferenceIndex =
        this.selectedColumns[index].datasetColumnIndex;
      predictionObject.predictionQuestionReferenceTitle =
        this.selectedColumns[index].datasetColumnName;
      predictionObject.answer =
        this.selectedColumns[index].answersList.length + 1;
      this.selectedColumns[index].predictionsList.push(predictionObject);
    },
    editSelectedColumn(index) {
      this.disableAddNewColumnButton = true;
      this.selectedColumns[index].isEnebleColumnEditing = true;
      this.selectedColumns[index].columnSaved = false;
    },
    selectPredictionForAnswer(index, answerIndex) {
      // 1. get Index of selected prediction column form datafile and assign values
      // 2. remove column from metaDataArray

      const predictionColumnName =
        this.selectedColumns[index].predictionsList[answerIndex]
          .datasetColumnName;
      const predictionColumnIndex = findIndexFromArray(
        predictionColumnName,
        this.refereanceMetaDataArrya
      );
      this.selectedColumns[index].predictionsList[
        answerIndex
      ].datasetColumnIndex = predictionColumnIndex;

      let getIndexToDeleteFromMetaDataArray = findIndexFromMetaDataArray(
        this.selectedColumns[index].predictionsList[answerIndex]
          .datasetColumnName,
        this.workingMetaDataArray
      );
      this.workingMetaDataArray.splice(getIndexToDeleteFromMetaDataArray, 1);

      this.selectedColumns[index].savedPredictionCount += 1;
    },
    editSelectedPrediction(index, answerIndex) {
      if (
        this.selectedColumns[index].predictionsList[answerIndex]
          .datasetColumnName &&
        !this.workingMetaDataArray.includes(
          this.selectedColumns[index].predictionsList[answerIndex]
            .datasetColumnName
        )
      ) {
        this.workingMetaDataArray.push(
          this.selectedColumns[index].predictionsList[answerIndex]
            .datasetColumnName
        );
      }

      let getSortedMetaDataArrayFromFunction = sortMetaDataArray(
        this.workingMetaDataArray
      );
      this.workingMetaDataArray = [...getSortedMetaDataArrayFromFunction];

      this.selectedColumns[index].predictionsList[
        answerIndex
      ].datasetColumnName = null;
      this.selectedColumns[index].predictionsList[
        answerIndex
      ].datasetColumnIndex = null;

      this.selectedColumns[index].predictionsList[answerIndex].validationError.isDisplayValidationError = false
      this.selectedColumns[index].predictionsList[answerIndex].validationError.errors = []

      this.selectedColumns[index].savedPredictionCount -= 1;
    },
    async removeSelectedColumn(index) {
      //when we remove one while editing multiple other columns add new button should not show untile they are uneditabe
      this.selectedColumns[index].isEnebleColumnEditing = false;
      const foundOtherEditableColumns = this.selectedColumns.find(
        ({ isEnebleColumnEditing }) => isEnebleColumnEditing === true
      );

      if (!foundOtherEditableColumns) this.disableAddNewColumnButton = false;

      if (
        this.selectedColumns[index].datasetColumnName &&
        !this.workingMetaDataArray.includes(
          this.selectedColumns[index].datasetColumnName
        )
      ) {
        this.workingMetaDataArray.push(
          this.selectedColumns[index].datasetColumnName
        );
      }

      if (
        this.selectedColumns[index].columnType === ColumnTypes.RESPONDENT_ID
      ) {
        if (this.selectedColumns[index].columnSaved) {
          this.isRecordIdInclude = false;
          for (let i = 0; i < this.columnTypes.length; i++) {
            if (this.columnTypes[i].value === ColumnTypes.RESPONDENT_ID) {
              this.columnTypes[i].$isDisabled = false;
            }
          }
        }
      }

      if (this.selectedColumns[index].columnType === ColumnTypes.PROJECTIVE_QUESTION) {
        if (this.selectedColumns[index].columnSavedCount > 0) {
          this.numberOfDataPointsAlreadySelected -= 1;
        }

        if (this.selectedColumns[index].savedPredictionCount > 0) {
          for (
            let i = 0;
            i < this.selectedColumns[index].predictionsList.length;
            i++
          ) {
            if (
              this.selectedColumns[index].predictionsList[i]
                .datasetColumnName &&
              !this.workingMetaDataArray.includes(
                this.selectedColumns[index].predictionsList[i].datasetColumnName
              )
            ) {
              this.workingMetaDataArray.push(
                this.selectedColumns[index].predictionsList[i].datasetColumnName
              );
            }
          }

          for (let i = 0; i < this.columnTypes.length; i++) {
            if (
              this.columnTypes[i].value === ColumnTypes.PROJECTIVE_QUESTION &&
              this.columnTypes[i].$isDisabled
            ) {
              this.columnTypes[i].$isDisabled = false;
            }
          }
        }
      }

      if (this.selectedColumns[index].columnType === ColumnTypes.TIMESTAMP) {
        if (this.selectedColumns[index].columnSavedCount > 0) {
          this.numberOfDataPointsAlreadySelected -= 1;
        }

        if (this.selectedColumns[index].timeStampsList.length > 0) {
          for (
            let i = 0;
            i < this.selectedColumns[index].timeStampsList.length;
            i++
          ) {
            if (
              this.selectedColumns[index].timeStampsList[i].datasetColumnName &&
              !this.workingMetaDataArray.includes(
                this.selectedColumns[index].timeStampsList[i].datasetColumnName
              )
            ) {
              this.workingMetaDataArray.push(
                this.selectedColumns[index].timeStampsList[i].datasetColumnName
              );
            }
          }
        }

        if (this.selectedColumns[index].groupSelector.length > 0) {
          for (
            let j = 0;
            j < this.selectedColumns[index].groupSelector.length;
            j++
          ) {
            if (
              this.selectedColumns[index].groupSelector[j].datasetColumnName &&
              !this.workingMetaDataArray.includes(
                this.selectedColumns[index].groupSelector[j].datasetColumnName
              )
            ) {
              this.workingMetaDataArray.push(
                this.selectedColumns[index].groupSelector[j].datasetColumnName
              );
            }
          }
        }

        for (let i = 0; i < this.columnTypes.length; i++) {
          if (this.columnTypes[i].value === ColumnTypes.TIMESTAMP) {
            this.columnTypes[i].$isDisabled = false;
          }
        }
      }

      if (this.selectedColumns[index].columnType === ColumnTypes.ITEMBATTERY) {
        for (
          let i = 1;
          i < this.selectedColumns[index].itemBatteryColumnNames.length;
          i++
        ) {
          const colName = this.selectedColumns[index].itemBatteryColumnNames[i];
          if (!this.workingMetaDataArray.includes(String(colName))) {
            this.workingMetaDataArray.push(String(colName));
          }
        }

        if (this.selectedColumns[index].itemBatteryRanges.length > 0) {
          for (
            let j = 0;
            j < this.selectedColumns[index].itemBatteryRanges.length;
            j++
          ) {
            for (
              let k = 0;
              k <
              this.selectedColumns[index].itemBatteryRanges[j].columnNames
                .length;
              k++
            ) {
              const additionalColName =
                this.selectedColumns[index].itemBatteryRanges[j].columnNames[k];
              if (
                !this.workingMetaDataArray.includes(String(additionalColName))
              ) {
                this.workingMetaDataArray.push(String(additionalColName));
              }
            }
          }
        }

        for (let i = 0; i < this.columnTypes.length; i++) {
          if (this.columnTypes[i].value === ColumnTypes.ITEMBATTERY) {
            this.columnTypes[i].$isDisabled = false;
          }
        }

        this.numItemBatteries -= 1;
      }

      if (this.selectedColumns[index].columnType === ColumnTypes.OPEN_ENDED) {
        if (this.selectedColumns[index].columnSavedCount > 0) {
          this.numberOfDataPointsAlreadySelected -= 1;
        }

        if (this.selectedColumns[index].openEndedList.length > 0) {
          for (
            let i = 0;
            i < this.selectedColumns[index].openEndedList.length;
            i++
          ) {
            if (
              this.selectedColumns[index].openEndedList[i].datasetColumnName &&
              !this.workingMetaDataArray.includes(
                this.selectedColumns[index].openEndedList[i].datasetColumnName
              )
            ) {
              this.workingMetaDataArray.push(
                this.selectedColumns[index].openEndedList[i].datasetColumnName
              );
            }
          }
        }

        for (let i = 0; i < this.columnTypes.length; i++) {
          if (this.columnTypes[i].value === ColumnTypes.OPEN_ENDED) {
            this.columnTypes[i].$isDisabled = false;
          }
        }
        // Reset max oes questions reached
        this.maxOESQuestionsReached = false;    
      }

      let getSortedMetaDataArrayFromFunction = sortMetaDataArray(
        this.workingMetaDataArray
      );
      this.workingMetaDataArray = [...getSortedMetaDataArrayFromFunction];
      this.selectedColumns.splice(index, 1);

      if (
        this.numberOfDataPointsAlreadySelected === 0 ||
        !this.isRecordIdInclude
      ) {
        this.disableNextStepButton = true;
      }
    },
    getTranslationByKey(key) {
      return this.$t(key);
    },
    getTranslationByName(value, object) {
      for (var columnType in object) {
        if (object[columnType].value === value) {
          return this.$t(object[columnType].name);
        }
      }
    },
    columnTypeOptions({ name }) {
      // used for mutislect option display with translations
      return this.$t(name);
    },
    clickedBackButton() {
      window.localStorage.removeItem("workingMetaDataArray");
      window.localStorage.removeItem("selectedColumns");

      localStorage.setItem(
        "workingMetaDataArray",
        JSON.stringify(this.workingMetaDataArray)
      );
      localStorage.setItem(
        "selectedColumns",
        JSON.stringify(this.selectedColumns)
      );

      localStorage.setItem("isEnableWrongTopicCheckInUpload", JSON.stringify(this.isEnableWrongTopicCheck));
      localStorage.setItem("enableDuplicateChecksInUpload", JSON.stringify(this.enableDuplicateChecks));
      localStorage.setItem("enablePatternCheckMain", JSON.stringify(this.enablePatternCheckMain));
      localStorage.setItem("enablePatternCheckAdditional", JSON.stringify(this.enablePatternCheckAdditional));

      this.$router.push("/upload-dataset/step1");
    },
    manipulateData() {
      this.datafileInputObject.metadata.dataPointMetadata = [];
      this.datafileInputObject.metadata.groupSelectors = [];
      this.itembatteryIndexForIndentifer = 0;

      for (const column of this.selectedColumns) {
        if (column.columnType === "PROJECTIVE_QUESTION") {
          let dataPointMetadata = new newdataPointMetadata();

          // 1. set values to datapoint dataPointIdentifier
          dataPointMetadata.dataPointIdentifier = removeExcelSupportPrefix(
            column.renamedColumnName
          );

          // 2. set values to datapoint questionColumn
          dataPointMetadata.dataPointColumn = column.datasetColumnIndex;

          // 3. set values to datapoint predictionColumns
          for (const prediction of column.predictionsList) {
            dataPointMetadata.predictionColumns.push({
              columnIndex: prediction.datasetColumnIndex,
              answer: prediction.answer
            });
          }

          // 4. set datapoint column Type
          dataPointMetadata.dataPointType = "PROJECTIVE_QUESTION";

          // 5. set algorithm
          dataPointMetadata.algorithms = column.qualityScores;

          this.datafileInputObject.metadata.dataPointMetadata.push(
            dataPointMetadata
          );
        }

        if (column.columnType === "RESPONDENT_ID") {
          this.datafileInputObject.metadata.dataRecordIdColumn =
            column.datasetColumnIndex;
        }

        if (column.columnType === "TIMESTAMP") {
          let dataPointMetadata = new newdataPointMetadata();

          // 1. set values to datapoint dataPointIdentifier
          dataPointMetadata.dataPointIdentifier = removeExcelSupportPrefix(
            column.datasetColumnName
          );

          // 2. set values to datapoint questionColumn
          dataPointMetadata.dataPointColumn = column.datasetColumnIndex;

          // 3. set datapoint column Type
          dataPointMetadata.dataPointType = "TIMESTAMP";

          // 4. set algorithm
          dataPointMetadata.algorithms.TS = true;

          this.datafileInputObject.metadata.dataPointMetadata.push(
            dataPointMetadata
          );

          // 5. Set Group Selector
          if (column.groupSelector[0]) {
            this.datafileInputObject.metadata.groupSelectors.push({
              columnName: removeExcelSupportPrefix(
                column.groupSelector[0].datasetColumnName
              ),
              columnType: column.groupSelector[0].columnType,
              columnIndex: column.groupSelector[0].datasetColumnIndex
            });
          }
          // 6. do the same above for all additional data points
          if (column.timeStampsList.length > 0) {
            column.timeStampsList.forEach((timeStamp) => {
              let dataPointMetadataAdditional = new newdataPointMetadata();

              // 1. set values to datapoint dataPointIdentifier
              dataPointMetadataAdditional.dataPointIdentifier =
                removeExcelSupportPrefix(timeStamp.datasetColumnName);

              // 2. set values to datapoint questionColumn
              dataPointMetadataAdditional.dataPointColumn =
                timeStamp.datasetColumnIndex;

              // 3. set datapoint column Type
              dataPointMetadataAdditional.dataPointType = "TIMESTAMP";

              // 4. set algorithm
              dataPointMetadataAdditional.algorithms.TS = true;

              this.datafileInputObject.metadata.dataPointMetadata.push(
                dataPointMetadataAdditional
              );
            });
          }
        }

        if (column.columnType === "OPEN_ENDED") {
          // set contect check 
          if (this.isEnableWrongTopicCheck) column.isEnableWrongTopicCheck = true;

          let dataPointMetadata = new newdataPointMetadata();

          // 1. set values to datapoint dataPointIdentifier
          dataPointMetadata.dataPointIdentifier = removeExcelSupportPrefix(
            column.datasetColumnName
          );
          dataPointMetadata.questionTitle = column.questionTitle;

          // 2. set values to datapoint questionColumn
          dataPointMetadata.dataPointColumn = column.datasetColumnIndex;

          // 3. set datapoint column Type
          dataPointMetadata.dataPointType = "OPEN_ENDED";

          // 4. set algorithm
          dataPointMetadata.algorithms.OES = true;

          // 5. Add Language Top Check
          dataPointMetadata.languagesToCheck = [];
          if (column.languagesToCheck) {
            for (let i = 0; i < column.languagesToCheck.length; i++) {
              dataPointMetadata.languagesToCheck.push(
                column.languagesToCheck[i].code
              );
            }
          }

          // 6. Set Wrong Topic check and keywords
          if(this.isEnableWrongTopicCheck) {
            dataPointMetadata.isEnableWrongTopicCheck =  true
            if(column.keywords.length > 0) {
              for (let i = 0; i < column.keywords.length; i++) {
              dataPointMetadata.keywords.push(
                column.keywords[i].text
              );
            }
            }
          }
          if (this.enableDuplicateChecks) {
            dataPointMetadata.enableDuplicateChecks = true;
          }

          this.datafileInputObject.metadata.dataPointMetadata.push(
            dataPointMetadata
          );

          // 7. do the same above for all additional data points
          if (column.openEndedList.length > 0) {
            column.openEndedList.forEach((openEnd) => {
              let dataPointMetadataAdditional = new newdataPointMetadata();

              // 1. set values to datapoint dataPointIdentifier
              dataPointMetadataAdditional.dataPointIdentifier =
                removeExcelSupportPrefix(openEnd.datasetColumnName);
              dataPointMetadataAdditional.questionTitle = openEnd.questionTitle;

              // 2. set values to datapoint questionColumn
              dataPointMetadataAdditional.dataPointColumn =
                openEnd.datasetColumnIndex;

              // 3. set datapoint column Type
              dataPointMetadataAdditional.dataPointType = "OPEN_ENDED";

              // 4. set algorithm
              dataPointMetadataAdditional.algorithms.OES = true;

              // 5. Add Language Top Check
              dataPointMetadataAdditional.languagesToCheck = [];
              
              if(openEnd.languagesToCheck){
                for (let i = 0; i < openEnd.languagesToCheck.length; i++) {
                  dataPointMetadataAdditional.languagesToCheck.push(
                    openEnd.languagesToCheck[i].code
                  );
                }
              }

              // 6. Set Wrong Topic check and keywords
              if(this.isEnableWrongTopicCheck) {
                dataPointMetadataAdditional.isEnableWrongTopicCheck =  true
                if(openEnd.keywords.length > 0) {
                  for (let i = 0; i < openEnd.keywords.length; i++) {
                    dataPointMetadataAdditional.keywords.push(
                      openEnd.keywords[i].text
                  );
                }
                }
              }

              this.datafileInputObject.metadata.dataPointMetadata.push(
                dataPointMetadataAdditional
              );
            });
          }
        }

        if (column.columnType === "ITEMBATTERY") {
          // first Item battery is not within column.itemBatteryRanges, but treated separately
          this.itembatteryIndexForIndentifer += 1;
          let dataPointMetadata = new newdataPointMetadata();
          if (this.enablePatternCheckMain) dataPointMetadata.enablePatternCheck = true;
          // 1. set values to datapoint dataPointIdentifier
          dataPointMetadata.dataPointIdentifier =
            "Item Battery " + this.itembatteryIndexForIndentifer;

          // 2. set values to datapoint for IBS
          dataPointMetadata.startCol = column.datasetColumnIndex;
          dataPointMetadata.endCol = column.itemBatteryEndRangeIndex;

          // 3. set datapoint column Type
          dataPointMetadata.dataPointType = "ITEMBATTERY";

          // 4. set algorithm
          dataPointMetadata.algorithms.IBS = true;

          this.datafileInputObject.metadata.dataPointMetadata.push(
            dataPointMetadata
          );
          for (let rangeIndex = 0; rangeIndex < column.itemBatteryRanges.length; rangeIndex++) {
            const IBrange = column.itemBatteryRanges[rangeIndex];
            //send as seperate datapoint metadata objects to the BE
            this.itembatteryIndexForIndentifer += 1;
            dataPointMetadata = new newdataPointMetadata();
            if (this.enablePatternCheckAdditional[rangeIndex]) dataPointMetadata.enablePatternCheck = true;
            // 1. set values to datapoint dataPointIdentifier
            dataPointMetadata.dataPointIdentifier =
              "Item Battery " + this.itembatteryIndexForIndentifer;

            // 2. set values to datapoint for IBS
            dataPointMetadata.startCol = IBrange.rangeStartIndex;
            dataPointMetadata.endCol = IBrange.rangeEndIndex;

            // 3. set datapoint column Type
            dataPointMetadata.dataPointType = "ITEMBATTERY";

            // 4. set algorithm
            dataPointMetadata.algorithms.IBS = true;

            this.datafileInputObject.metadata.dataPointMetadata.push(
              dataPointMetadata
            );
          }
        }
      }

      
    },
    async preConditionForNextButton(shouldProceed = false) {
      await this.manipulateData();

      // REQUIREMENT FOR CERTIFICATE LEVEL
      let availableQualityScoresCount = {
        IS: 0,
        PS: 0,
        SDS: 0,
        TS: 0,
        IBS: 0,
        CS: 0,
        OES: 0
      };

      const dataPoints = this.datafileInputObject.metadata.dataPointMetadata;

      for (const datapoint of dataPoints) {
        if (datapoint.algorithms.IS) availableQualityScoresCount.IS += 1;
        if (datapoint.algorithms.PS) availableQualityScoresCount.PS += 1;
        if (datapoint.algorithms.SDS) availableQualityScoresCount.SDS += 1;
        if (datapoint.algorithms.TS) availableQualityScoresCount.TS += 1;
        if (datapoint.algorithms.CS) availableQualityScoresCount.CS += 1;
        if (datapoint.algorithms.OES) availableQualityScoresCount.OES += 1;
        if (datapoint.algorithms.IBS) {
          availableQualityScoresCount.IBS += 1;
        }
      }

      //Step 1: Decide the matching Level
      let eligibleCertificateLevel = null;

      this.allCertificateLevels.forEach((level) => {
        let isLevelMatching = true;
        Object.keys(level.qualityScores).forEach((key) => {
          if (!(availableQualityScoresCount[key] >= level.qualityScores[key])) {
            isLevelMatching = false;
          }
        });
        if (isLevelMatching) {
          eligibleCertificateLevel = level;
        } else {
          return;
        }
      });


      if (shouldProceed) {
        if (eligibleCertificateLevel) {
          this.datafileInputObject.eligibleCertificateLevel =
            eligibleCertificateLevel.name;
        } else {
          this.datafileInputObject.eligibleCertificateLevel = null;
        }
        this.clickedNextStepButton();
      }
    },
    clickedNextStepButton() {
      window.localStorage.removeItem("workingMetaDataArray");
      window.localStorage.removeItem("selectedColumns");
      window.localStorage.removeItem("datafileInputObject");

      this.datafileInputObject.numberOfQuestions =
        this.numberOfDataPointsAlreadySelected;
      this.datafileInputObject.numberOfFilterQuestions =
        this.numberOfFiltersAlreadySelected;
      this.datafileInputObject.isRecordIdInclude = this.isRecordIdInclude;
      this.datafileInputObject.isEnableWrongTopicCheck = this.isEnableWrongTopicCheck;
      this.datafileInputObject.enableDuplicateChecks = this.enableDuplicateChecks;
      this.datafileInputObject.enablePatternCheckMain = this.enablePatternCheckMain;
      this.datafileInputObject.enablePatternCheckAdditional = this.enablePatternCheckAdditional;

      localStorage.setItem(
        "datafileInputObject",
        JSON.stringify(this.datafileInputObject)
      );

      localStorage.setItem(
        "workingMetaDataArray",
        JSON.stringify(this.workingMetaDataArray)
      );

      localStorage.setItem(
        "selectedColumns",
        JSON.stringify(this.selectedColumns)
      );

      localStorage.setItem("isEnableWrongTopicCheckInUpload", JSON.stringify(this.isEnableWrongTopicCheck));
      localStorage.setItem("enableDuplicateChecksInUpload", JSON.stringify(this.enableDuplicateChecks));
      localStorage.setItem("enablePatternCheckMain", JSON.stringify(this.enablePatternCheckMain));
      localStorage.setItem("enablePatternCheckAdditional", JSON.stringify(this.enablePatternCheckAdditional));      

      this.$router.push("/upload-dataset/step3");
    },
    handleClickOnSDSInfo() {
      this.showSDSDescription
        ? (this.showSDSDescription = false)
        : (this.showSDSDescription = true);
    },
    handleClickOnPSInfo() {
      this.showPSDescription
        ? (this.showPSDescription = false)
        : (this.showPSDescription = true);
    },
    handleClickOnISInfo() {
      this.showISDescription
        ? (this.showISDescription = false)
        : (this.showISDescription = true);
    },
    handleClickOnCSInfo() {
      this.showCSDescription
        ? (this.showCSDescription = false)
        : (this.showCSDescription = true);
    },
    customLabelForLanguage({ code, name }) {
      return `${code} – ${name}`;
    },
    keywordsGetChangeOtherOES(newTags, columnIndex, openEndedIndex) {
      this.selectedColumns[columnIndex].openEndedList[openEndedIndex].keywords = []
      for (let i = 0; i < newTags.length; i++) {
        newTags[i].style = "color: #fff; background-color: #00a181;";

        this.selectedColumns[columnIndex].openEndedList[openEndedIndex].keywords.push(newTags[i]);
      }
    },
    keywordsGetChangeForFirstOES(newTags, columnIndex, ) {
      this.selectedColumns[columnIndex].keywords = []
      for (let i = 0; i < newTags.length; i++) {
        newTags[i].style = "color: #fff; background-color: #00a181;";

        this.selectedColumns[columnIndex].keywords.push(newTags[i]);
      }
    },
    clickRemoveMainIB(index) {
      // 1. Reset Selected Column Variables
      this.selectedColumns[index].datasetColumnName = null
      this.selectedColumns[index].datasetColumnIndex = null
      this.selectedColumns[index].itemBatteryEndRangeName = null
      this.selectedColumns[index].itemBatteryEndRangeIndex = null
      this.selectedColumns[index].endRangeError = false
      this.selectedColumns[index].validationError.isDisplayValidationError = false
      this.selectedColumns[index].validationError.errors = []
      


      // 2. Push main IB columsn back to workingMetaDataArray
      for (let i = 0;i < this.selectedColumns[index].itemBatteryColumnNames.length; i++) {
        const colName = this.selectedColumns[index].itemBatteryColumnNames[i]
        if (!this.workingMetaDataArray.includes(colName))
          this.workingMetaDataArray.push(this.selectedColumns[index].itemBatteryColumnNames[i]);
      }
      let getSortedMetaDataArrayFromFunction = sortMetaDataArray(
        this.workingMetaDataArray
      );
      this.workingMetaDataArray = [...getSortedMetaDataArrayFromFunction];

      // 3. Reset itemBatteryColumnNames Variables
      this.selectedColumns[index].itemBatteryColumnNames = []
    },
    async validateIBSUserInput(validationInput, columnIndex) {
      const selectionValidity = await DataImportService.validateUploadStep2Selections(validationInput)
      if(selectionValidity.responseStatus === ResponseStatus.ERROR || selectionValidity.serverError) {
        if (selectionValidity.serverError) {
          this.selectedColumns[columnIndex].validationError.displayServerError = true;
        }
        // revert column saved count if its an error
        this.selectedColumns[columnIndex].columnSavedCount -= 1;

        // reset Main Item Battery data point errors
        this.selectedColumns[columnIndex].validationError.errors = []

        // reset Additional Item Battery data points errors
        if(this.selectedColumns[columnIndex].itemBatteryRanges.length > 0) {
          this.selectedColumns[columnIndex].itemBatteryRanges.forEach(dataPoint => {
            dataPoint.validationError.errors = []
          });
        }

        // assign errors into correct Item Battery Data Point
        for(let i=0; i < selectionValidity.errors?.length; i++) {
          if(selectionValidity.errors[i].dataPoint === 0) {
            this.selectedColumns[columnIndex].validationError.isDisplayValidationError = true
            this.selectedColumns[columnIndex].validationError.errors.push(
              {
                validationErrorCode: selectionValidity.errors[i].errorCode,
                validationErrorMessage: getErrorMessageByErrorCode(selectionValidity.errors[i].errorCode)
              }
            )
          } else {
            this.selectedColumns[columnIndex].itemBatteryRanges[selectionValidity.errors[i].dataPoint-1].validationError.isDisplayValidationError = true
            this.selectedColumns[columnIndex].itemBatteryRanges[selectionValidity.errors[i].dataPoint-1].validationError.errors.push(
              {
                validationErrorCode: selectionValidity.errors[i].errorCode,
                validationErrorMessage: getErrorMessageByErrorCode(selectionValidity.errors[i].errorCode)
              }
            )
          }
        }
        
        this.selectedColumns[columnIndex].validationInProgress = false;
      }  
      
      if(selectionValidity.responseStatus === ResponseStatus.SUCCESS) {
        this.selectedColumns[columnIndex].columnSavedCount += 1;
        this.selectedColumns[columnIndex].columnSaved = true;
        this.selectedColumns[columnIndex].isEnebleColumnEditing = false;
        this.selectedColumns[columnIndex].clickedContinueOne = true;
        this.disableAddNewColumnButton = false;
        this.selectedColumns[columnIndex].validationInProgress = false
      }
    },
    removeSpecifiedDataRecordIDColumn(index) {
      // 1. Push columns back to workingMetaDataArray 
      if (
        this.selectedColumns[index].datasetColumnName &&
        !this.workingMetaDataArray.includes(
          this.selectedColumns[index].datasetColumnName
        )
      ) {
        this.workingMetaDataArray.push(
          this.selectedColumns[index].datasetColumnName
        );
      }

      let getSortedMetaDataArrayFromFunction = sortMetaDataArray(
        this.workingMetaDataArray
      );
      this.workingMetaDataArray = [...getSortedMetaDataArrayFromFunction];

      // 2. Reset Selected Column Variables
      this.selectedColumns[index].datasetColumnName = null
      this.selectedColumns[index].datasetColumnIndex = null
      this.selectedColumns[index].validationError.isDisplayValidationError = false
      this.selectedColumns[index].validationError.errors = []
    },
    async validateDataRecordIDUserInput(validationInput, columnIndex) {
      const selectionValidity = await DataImportService.validateUploadStep2Selections(validationInput)

      if(selectionValidity.responseStatus === ResponseStatus.ERROR || selectionValidity.serverError) {
        if (selectionValidity.serverError) {
          this.selectedColumns[columnIndex].validationError.displayServerError = true;
        }
        // revert column saved count if its an error
        this.selectedColumns[columnIndex].columnSavedCount -= 1;

        // reset data point errors
        this.selectedColumns[columnIndex].validationError.errors = []

        // assign errors into correct Item Battery Data Point
        for(let i=0; i < selectionValidity.errors?.length; i++) {
          this.selectedColumns[columnIndex].validationError.isDisplayValidationError = true
          this.selectedColumns[columnIndex].validationError.errors.push(
            {
              validationErrorCode: selectionValidity.errors[i].errorCode,
              validationErrorMessage: getErrorMessageByErrorCode(selectionValidity.errors[i].errorCode)
            }
          )
        }
        
        this.selectedColumns[columnIndex].validationInProgress = false;

        // reset columnType array
        for (let i = 0; i < this.columnTypes.length; i++) {
          if (this.columnTypes[i].value === ColumnTypes.RESPONDENT_ID) {
            this.columnTypes[i].$isDisabled = false;
          }
        }
      }
      
      if(selectionValidity.responseStatus === ResponseStatus.SUCCESS) {
        this.selectedColumns[columnIndex].columnSavedCount += 1;
        this.selectedColumns[columnIndex].columnSaved = true;
        this.selectedColumns[columnIndex].isEnebleColumnEditing = false;
        this.selectedColumns[columnIndex].clickedContinueOne = true;
        this.disableAddNewColumnButton = false;
        this.selectedColumns[columnIndex].validationInProgress = false
      }
    },
    async validateTimestampsInputs(validationInput, columnIndex) {
      const selectionValidity = await DataImportService.validateUploadStep2Selections(validationInput)
      if(selectionValidity.responseStatus === ResponseStatus.ERROR || selectionValidity.serverError) { 
        if (selectionValidity.serverError) {
          this.selectedColumns[columnIndex].validationError.displayServerError = true;
        }
        // revert column saved count if its an error
        this.selectedColumns[columnIndex].columnSavedCount -= 1;

        // reset Main Timestamp data point errors
        this.selectedColumns[columnIndex].validationError.errors = []

        // reset Additional Timestamp data points errors
        if(this.selectedColumns[columnIndex].timeStampsList.length > 0) {
          this.selectedColumns[columnIndex].timeStampsList.forEach(dataPoint => {
            dataPoint.validationError.errors = []
          });
        }

        // reset Grouping Column data point errors
        if(this.selectedColumns[columnIndex].groupSelector.length > 0) {
          this.selectedColumns[columnIndex].groupSelector[0].validationError.errors = []
        }

        // assign errors into correct Item Battery Data Point
        for(let i=0; i < selectionValidity.errors?.length; i++) {
          if(selectionValidity.errors[i].dataPoint === 0) {
            this.selectedColumns[columnIndex].validationError.isDisplayValidationError = true
            this.selectedColumns[columnIndex].validationError.errors.push(
              {
                validationErrorCode: selectionValidity.errors[i].errorCode,
                validationErrorMessage: getErrorMessageByErrorCode(selectionValidity.errors[i].errorCode)
              }
            )
          } else if(selectionValidity.errors[i].dataPoint === 99) {
            this.selectedColumns[columnIndex].groupSelector[0].validationError.isDisplayValidationError = true
            this.selectedColumns[columnIndex].groupSelector[0].validationError.errors.push(
              {
                validationErrorCode: selectionValidity.errors[i].errorCode,
                validationErrorMessage: getErrorMessageByErrorCode(selectionValidity.errors[i].errorCode)
              }
            )
          } else {
            this.selectedColumns[columnIndex].timeStampsList[selectionValidity.errors[i].dataPoint-1].validationError.isDisplayValidationError = true
            this.selectedColumns[columnIndex].timeStampsList[selectionValidity.errors[i].dataPoint-1].validationError.errors.push(
              {
                validationErrorCode: selectionValidity.errors[i].errorCode,
                validationErrorMessage: getErrorMessageByErrorCode(selectionValidity.errors[i].errorCode)
              }
            )
          }
        }

        this.selectedColumns[columnIndex].validationInProgress = false
      }

      if(selectionValidity.responseStatus === ResponseStatus.SUCCESS) {
        this.selectedColumns[columnIndex].columnSavedCount += 1;
        this.selectedColumns[columnIndex].columnSaved = true;
        this.selectedColumns[columnIndex].isEnebleColumnEditing = false;
        this.selectedColumns[columnIndex].clickedContinueOne = true;
        this.disableAddNewColumnButton = false;
        this.selectedColumns[columnIndex].validationInProgress = false
      }
    },
    clickRemoveMainTimestampColumn(index) {
      // 1. Reset Selected Column Variables
      this.selectedColumns[index].datasetColumnIndex = null
      this.selectedColumns[index].validationError.isDisplayValidationError = false
      this.selectedColumns[index].validationError.errors = []

      // 2. Push column back to Working Meta Data Array
      if (this.selectedColumns[index].datasetColumnName && !this.workingMetaDataArray.includes(this.selectedColumns[index].datasetColumnName)) {
        this.workingMetaDataArray.push(this.selectedColumns[index].datasetColumnName);
      }

      // 3. Reset Selected Column datasetColumnName
      this.selectedColumns[index].datasetColumnName = null

      // 4. Sort Workign Metadata array
      let getSortedMetaDataArrayFromFunction = sortMetaDataArray(
        this.workingMetaDataArray
      );
      this.workingMetaDataArray = [...getSortedMetaDataArrayFromFunction];
    },
    async validateOpenEndedInputs(validationInput, columnIndex) {
      const selectionValidity = await DataImportService.validateUploadStep2Selections(validationInput)
      if(selectionValidity.responseStatus === ResponseStatus.ERROR || selectionValidity.serverError) {
        if (selectionValidity.serverError) {
          this.selectedColumns[columnIndex].validationError.displayServerError = true;
        }
        // revert column saved count if its an error
        this.selectedColumns[columnIndex].columnSavedCount -= 1;

        // reset Main OES data point errors
        this.selectedColumns[columnIndex].validationError.errors = []

        // reset Additional OES data points errors
        if(this.selectedColumns[columnIndex].openEndedList.length > 0) {
          this.selectedColumns[columnIndex].openEndedList.forEach(dataPoint => {
            dataPoint.validationError.errors = []
          });
        }

        // assign errors into correct OES Data Point
        for(let i=0; i < selectionValidity.errors?.length; i++) {
          if(selectionValidity.errors[i].dataPoint === 0) {
            this.selectedColumns[columnIndex].validationError.isDisplayValidationError = true
            this.selectedColumns[columnIndex].validationError.errors.push(
              {
                validationErrorCode: selectionValidity.errors[i].errorCode,
                validationErrorMessage: getErrorMessageByErrorCode(selectionValidity.errors[i].errorCode)
              }
            )
          } else {
            this.selectedColumns[columnIndex].openEndedList[selectionValidity.errors[i].dataPoint-1].validationError.isDisplayValidationError = true
            this.selectedColumns[columnIndex].openEndedList[selectionValidity.errors[i].dataPoint-1].validationError.errors.push(
              {
                validationErrorCode: selectionValidity.errors[i].errorCode,
                validationErrorMessage: getErrorMessageByErrorCode(selectionValidity.errors[i].errorCode)
              }
            )
          }
        }

        this.selectedColumns[columnIndex].validationInProgress = false;
      }

      if(selectionValidity.responseStatus === ResponseStatus.SUCCESS) {
        this.selectedColumns[columnIndex].columnSavedCount += 1;
        this.selectedColumns[columnIndex].columnSaved = true;
        this.selectedColumns[columnIndex].isEnebleColumnEditing = false;
        this.selectedColumns[columnIndex].clickedContinueOne = true;
        this.disableAddNewColumnButton = false;
        this.selectedColumns[columnIndex].validationInProgress = false
      }

    },
    clickRemoveMainOpenEndedColumn(index){
      // 1. Reset Selected Column Variables
      this.selectedColumns[index].datasetColumnIndex = null
      this.selectedColumns[index].validationError.isDisplayValidationError = false
      this.selectedColumns[index].validationError.errors = []

      // 2. Push column back to Working Meta Data Array
      if (this.selectedColumns[index].datasetColumnName && !this.workingMetaDataArray.includes(this.selectedColumns[index].datasetColumnName)) {
        this.workingMetaDataArray.push(this.selectedColumns[index].datasetColumnName);
      }

      // 3. Reset Selected Column datasetColumnName
      this.selectedColumns[index].datasetColumnName = null

      // 4. Sort Workign Metadata array
      let getSortedMetaDataArrayFromFunction = sortMetaDataArray(
        this.workingMetaDataArray
      );
      this.workingMetaDataArray = [...getSortedMetaDataArrayFromFunction];

      this.checkOESQuestionTitles()
    },
    clickRemoveAdditionalOpenEndedColumn(index, openEndedIndex) {
      // 1. Reset Selected Column Variables
      this.selectedColumns[index].openEndedList[openEndedIndex].datasetColumnIndex = null
      this.selectedColumns[index].openEndedList[openEndedIndex].validationError.isDisplayValidationError = false
      this.selectedColumns[index].openEndedList[openEndedIndex].validationError.errors = []

      // 2. Push column back to Working Meta Data Array
      if (this.selectedColumns[index].openEndedList[openEndedIndex].datasetColumnName && !this.workingMetaDataArray.includes(this.selectedColumns[index].datasetColumnName)) {
        this.workingMetaDataArray.push(this.selectedColumns[index].openEndedList[openEndedIndex].datasetColumnName);
      }

      // 3. Reset Selected Column datasetColumnName
      this.selectedColumns[index].openEndedList[openEndedIndex].datasetColumnName = null

      // 4. Sort Workign Metadata array
      let getSortedMetaDataArrayFromFunction = sortMetaDataArray(
        this.workingMetaDataArray
      );
      this.workingMetaDataArray = [...getSortedMetaDataArrayFromFunction];

      this.checkOESQuestionTitles()
    },
    async validateProjectiveQuestionInputs(validationInput, columnIndex) {
      const selectionValidity = await DataImportService.validateUploadStep2Selections(validationInput)

      if (selectionValidity.responseStatus === ResponseStatus.ERROR || selectionValidity.serverError) {
        if (selectionValidity.serverError) {
          this.selectedColumns[columnIndex].validationError.displayServerError = true;
        }        
        // revert column saved count if its an error
        this.selectedColumns[columnIndex].columnSavedCount -= 1;

        // reset vote data point errors
        this.selectedColumns[columnIndex].validationError.errors = []

        // reset prediction data points errors
        if(this.selectedColumns[columnIndex].predictionsList.length > 0) {
          this.selectedColumns[columnIndex].predictionsList.forEach(dataPoint => {
            dataPoint.validationError.errors = []
          });
        }

        // assign errors into correct prediction data point
        for(let i=0; i < selectionValidity.errors?.length; i++) {
          if(selectionValidity.errors[i].dataPoint === 99) {
            this.selectedColumns[columnIndex].validationError.isDisplayValidationError = true;
            this.selectedColumns[columnIndex].validationError.errors.push(
              {
                validationErrorCode: selectionValidity.errors[i].errorCode,
                validationErrorMessage: getErrorMessageByErrorCode(selectionValidity.errors[i].errorCode)
              }
            )
          } else {
            this.selectedColumns[columnIndex].predictionsList.forEach(prediction => {
              if(selectionValidity.errors[i].errorCode && (prediction.answer === selectionValidity.errors[i].dataPoint)) {
                prediction.validationError.isDisplayValidationError = true;
                prediction.validationError.errors.push(
                  {
                    validationErrorCode: selectionValidity.errors[i].errorCode,
                    validationErrorMessage: getErrorMessageByErrorCode(selectionValidity.errors[i].errorCode)
                  }
                )
              }
            })
          }
        }

        this.selectedColumns[columnIndex].validationInProgress = false;
      }

      if(selectionValidity.responseStatus === ResponseStatus.SUCCESS) {
        this.selectedColumns[columnIndex].columnSavedCount += 1;
        this.selectedColumns[columnIndex].columnSaved = true;
        this.selectedColumns[columnIndex].isEnebleColumnEditing = false;
        this.selectedColumns[columnIndex].clickedContinueOne = true;
        this.disableAddNewColumnButton = false;
        this.selectedColumns[columnIndex].validationInProgress = false
      }
    },
    clickRemoveVoteColumn(index) {
      // 1. Reset Selected Column Variables
      this.selectedColumns[index].datasetColumnIndex = null
      this.selectedColumns[index].validationError.isDisplayValidationError = false
      this.selectedColumns[index].validationError.errors = []
      this.selectedColumns[index].clickedContinueOne = false
      this.selectedColumns[index].isAnswerListHavingAnError = false


      // 2. Push column back to Working Meta Data Array
      if (this.selectedColumns[index].datasetColumnName && !this.workingMetaDataArray.includes(this.selectedColumns[index].datasetColumnName)) {
        this.workingMetaDataArray.push(this.selectedColumns[index].datasetColumnName);
      }

      // 3. Reset Selected Column datasetColumnName
      this.selectedColumns[index].datasetColumnName = null

      // 4. Push prediction columns back to Working Meta Data Array
      if(this.selectedColumns[index].predictionsList.length > 0) {
        for(let j=0; j < this.selectedColumns[index].predictionsList.length; j++) {
          if(this.selectedColumns[index].predictionsList[j].datasetColumnName && !this.workingMetaDataArray.includes(this.selectedColumns[index].predictionsList[j].datasetColumnName)) {
            this.workingMetaDataArray.push(this.selectedColumns[index].predictionsList[j].datasetColumnName);
          }
        }
      }

      // 5. Reset Selected Prediction Column datasetColumnName
      this.selectedColumns[index].predictionsList = []
      this.selectedColumns[index].answersList = []

      // 6. Sort Workign Metadata array
      let getSortedMetaDataArrayFromFunction = sortMetaDataArray(
        this.workingMetaDataArray
      );
      this.workingMetaDataArray = [...getSortedMetaDataArrayFromFunction];
    },
    clickResetKeywordsForMainOpenEndedColumn (index) {
      this.selectedColumns[index].keywords = []
    },
    clickResetKeywordsForAdditionalOpenEndedColumn (index, openEndedIndex) {
      this.selectedColumns[index].openEndedList[openEndedIndex].keywords = []
    },
    getTransationByErrorCode(errorCode) {
      return getErrorMessageByErrorCode(errorCode)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('uploadProcessStep2.pageTitle')" :items="items" />

    <!-- Loading -->
    <div v-if="this.isDisplayLoading" class="row mb-3 mt-3">
      <div class="col-md-12 text-center">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <div v-else>
      <!-- instrcutions -->
      <uploadInstructionCard />

      <!-- Selected Columns -->
      <div class="row" v-if="this.selectedColumns.length > 0">
        <div
          class="col-md-12 mb-3"
          v-for="(column, columnIndex) in this.selectedColumns"
          :key="columnIndex"
        >
          <div
            class="card"
            :style="[
              column.isEnebleColumnEditing
                ? { background: '#FFFFFF' }
                : { background: '#edfaee' }
            ]"
          >
            <div class="card-body">
              <!-- Column Header -->
              <div class="row">
                <!-- Column Type -->
                <div class="col-md-6">
                  <b-badge style="font-size: 12px" variant="primary">
                    <span v-if="column.columnType === columnTypesEnum.PROJECTIVE_QUESTION">
                      {{ $t("uploadProcessStep2.columnTypes.projectiveQuestion") }}
                    </span>
                    <span v-if="column.columnType === columnTypesEnum.RESPONDENT_ID">
                      {{ $t("uploadProcessStep2.columnTypes.dataRecordID") }}
                    </span>
                    <span v-if="column.columnType === columnTypesEnum.TIMESTAMP">
                      {{ $t("uploadProcessStep2.columnTypes.timeStamp") }}
                    </span>
                    <span v-if="column.columnType === columnTypesEnum.ITEMBATTERY">
                      {{ $t("uploadProcessStep2.columnTypes.itemBattery") }}
                    </span>
                    <span v-if="column.columnType === columnTypesEnum.OPEN_ENDED">
                      {{ $t("uploadProcessStep2.columnTypes.openEnded") }}
                    </span>
                  </b-badge>
                  <i
                    v-if="!column.isEnebleColumnEditing"
                    class="ml-2 bx bxs-check-circle text-success bx-tada"
                    style="font-size: 20px"
                  ></i>
                </div>

                <!-- Actions for Column -->
                <div class="col-md-6">
                  <div class="float-right">
                    <i
                      v-if="
                        column.columnSaved &&
                        column.columnType !== 'RESPONDENT_ID'
                      "
                      class="bx bxs-message-square-edit text-warning pointer"
                      style="font-size: 25px"
                      @click="editSelectedColumn(columnIndex)"
                    ></i>
                    <i
                      v-else
                      class="bx bxs-message-square-x text-danger pointer"
                      style="font-size: 25px"
                      @click="removeSelectedColumn(columnIndex)"
                    ></i>
                  </div>
                </div>
              </div>

              <!-- General -->
              <div class="row mt-3">
                <!-- Selected Column Type -->
                <div class="col-md-4">
                  <div class="form-group">
                    <label>
                      {{
                        $t(
                          "uploadProcessStep2.form.general.selectColumnType.label"
                        )
                      }}
                      <span class="text-danger">*</span>
                    </label>

                    <select
                      v-if="column.columnType"
                      class="form-control"
                      style="
                        background: #f5f5f5;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                      "
                    >
                      <option selected>
                        {{
                          getTranslationByName(column.columnType, columnTypes)
                        }}
                      </option>
                    </select>

                    <multiselect
                      v-else
                      v-model="column.columnTypeObject"
                      :options="columnTypes"
                      :placeholder="
                        $t(
                          'uploadProcessStep2.form.general.selectColumnType.placeholder'
                        )
                      "
                      :custom-label="columnTypeOptions"
                      track-by="name"
                      :searchable="false"
                      label="name"
                      @input="selectColumnType(columnIndex)"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ getTranslationByKey(option.name) }}
                      </template>
                    </multiselect>
                  </div>
                </div>

                <!-- DATA_RECORD_ID -->
                <div class="col-md-8" v-if="column.columnType && column.columnType === columnTypesEnum.RESPONDENT_ID">
                  <div class="row">
                    <!-- Specify data Record ID column -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="row">
                          <div class="col-md-8">
                            {{ $t("uploadProcessStep2.form.general.selectColumn.label") }}
                            <span class="text-danger">*</span>
                          </div>
                          <div class="col-md-4 text-right">
                            <b-badge v-if="column.datasetColumnName && column.isEnebleColumnEditing" variant="light" class="pointer" style="font-size:12px" @click="removeSpecifiedDataRecordIDColumn(columnIndex)">
                              <i class="bx bx-sync pointer mr-1" ></i> {{ $t("uploadProcessStep2.form.general.resetButton")}}
                            </b-badge>
                          </div>
                        </label>

                        <select
                          v-if="column.datasetColumnName"
                          :disabled="column.datasetColumnName"
                          class="form-control"
                          style="
                            background: #f5f5f5;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                          "
                          :style="[column.validationError.isDisplayValidationError ? { border: '1px solid #f46a6a' } : { border: none }]"
                        >
                          <option>{{ column.datasetColumnName }}</option>
                        </select>

                        <multiselect
                          v-else
                          v-model="column.datasetColumnName"
                          :options="workingMetaDataArray"
                          @input="selectDatasetColumn(columnIndex)"
                          :placeholder="
                            $t(
                              'uploadProcessStep2.form.general.selectColumn.placeholder'
                            )
                          "
                        ></multiselect>
                      </div>
                    </div>

                    <!-- Save button -->
                    <div class="col-md-6 align-self-center" v-if="!column.columnSaved && column.datasetColumnName">
                      <b-button
                        v-if="!column.validationInProgress"
                        class="mt-2"
                        type="submit"
                        variant="primary"
                        @click="handleClickOnSaveColumn(columnIndex)"
                      >
                        {{ $t("uploadProcessStep2.form.general.saveButton") }}
                        <i class="bx bx-check text-success bx-tada"></i>
                      </b-button>
                      <div class="mt-3" v-else>
                        <i class='mr-2 bx bx-loader-circle bx-spin' ></i> {{$t("uploadProcessStep2.form.general.validationInProgress")}}
                      </div>
                    </div>
                  </div>

                  <!-- Validation Error for Data Record ID -->
                  <div class="row" v-if="column.validationError.isDisplayValidationError">
                    <div class="col-md-12" v-for="(error, errorIndex) in column.validationError.errors" :key="errorIndex">
                      <div class="text-danger"> * {{ getTransationByErrorCode(error.validationErrorCode) }} </div>
                    </div>
                  </div>

                  <div class="row" v-if="column.validationError.displayServerError">
                    <div class="col-md-12">
                      <div class="text-danger">
                      {{ getTransationByErrorCode(errorCodesEnum.SERVER_ERROR) }}
                    </div>
                    </div>
                  </div>
                </div>

                <!-- TIMESTAMPS -->
                <div class="col-md-8" v-if="column.columnType && column.columnType === columnTypesEnum.TIMESTAMP">
                  <div class="row">
                    <!-- Specify Mian Timestamp column -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="row">
                          <div class="col-md-8">
                            {{ $t("uploadProcessStep2.form.general.selectColumn.label") }}
                            <span class="text-danger">*</span>
                          </div>
                          <div class="col-md-4 text-right">
                            <b-badge v-if="column.datasetColumnName && column.isEnebleColumnEditing" variant="light" class="pointer" style="font-size:12px" @click="clickRemoveMainTimestampColumn(columnIndex)">
                              <i class="bx bx-sync pointer mr-1" ></i> {{ $t("uploadProcessStep2.form.general.resetButton")}}
                            </b-badge>
                          </div>
                        </label>

                        <select
                          v-if="column.datasetColumnName"
                          :disabled="column.datasetColumnName"
                          class="form-control"
                          style="
                            background: #f5f5f5;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                          "
                          :style="[column.validationError.isDisplayValidationError ? { border: '1px solid #f46a6a' } : { border: none }]"
                        >
                          <option>{{ column.datasetColumnName }}</option>
                        </select>

                        <multiselect
                          v-else
                          v-model="column.datasetColumnName"
                          :options="workingMetaDataArray"
                          @input="selectDatasetColumn(columnIndex)"
                          :placeholder="
                            $t(
                              'uploadProcessStep2.form.general.selectColumn.placeholder'
                            )
                          "
                        ></multiselect>
                      </div>
                    </div>

                    <!-- Continue Button - for Mian Timestamp -->
                    <div class="col-md-6 align-self-center" v-if="!column.columnSaved && column.datasetColumnName && !column.clickedContinueOne">
                      <b-button
                        class="mt-2"
                        type="submit"
                        variant="primary"
                        @click="clickedContinueOneTimestamp(columnIndex)"
                      >
                        {{
                          $t("uploadProcessStep2.form.general.continueButton")
                        }}
                        <i class="bx bx-right-arrow-alt text-success bx-tada"></i>
                      </b-button>
                    </div>
                  </div>

                  <!-- Validation Error for Main TIMESTAMP -->
                  <div class="row" v-if="column.validationError.isDisplayValidationError">
                    <div class="col-md-12" v-for="(error, errorIndex) in column.validationError.errors" :key="errorIndex">
                      <div class="text-danger"> * {{ getTransationByErrorCode(error.validationErrorCode) }} </div>
                    </div>
                  </div>
                </div>

                <!-- PROJECTIVE_QUESTION -->
                <div class="col-md-8" v-if="column.columnType && column.columnType === columnTypesEnum.PROJECTIVE_QUESTION">
                  <div class="row">
                    <!-- Vote -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="row">
                          <div class="col-md-8">
                            {{ $t("uploadProcessStep2.form.general.selectColumn.label") }}
                            <span class="text-danger">*</span>
                          </div>
                          <div class="col-md-4 text-right">
                            <b-badge v-if="column.datasetColumnName && column.isEnebleColumnEditing" variant="light" class="pointer" style="font-size:12px" @click="clickRemoveVoteColumn(columnIndex)">
                              <i class="bx bx-sync pointer mr-1" ></i> {{ $t("uploadProcessStep2.form.general.resetButton")}}
                            </b-badge>
                          </div>
                        </label>

                        <select
                          v-if="column.datasetColumnName"
                          :disabled="column.datasetColumnName"
                          class="form-control"
                          style="
                            background: #f5f5f5;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                          "
                          :style="[column.validationError.isDisplayValidationError ? { border: '1px solid #f46a6a' } : { border: none }]"
                        >
                          <option>{{ column.datasetColumnName }}</option>
                        </select>

                        <multiselect
                          v-else
                          v-model="column.datasetColumnName"
                          :options="workingMetaDataArray"
                          @input="selectDatasetColumn(columnIndex)"
                          :placeholder="
                            $t(
                              'uploadProcessStep2.form.general.selectColumn.placeholder'
                            )
                          "
                        ></multiselect>
                      </div>

                      <!-- Validation Error -->
                      <div class="row" v-if="column.validationError.isDisplayValidationError">
                        <div class="col-md-12" v-for="(error, errorIndex) in column.validationError.errors" :key="errorIndex">
                          <div class="text-danger"> * {{ getTransationByErrorCode(error.validationErrorCode) }} </div>
                        </div>
                      </div>
                    </div>

                    <!-- Continue Button -->
                    <div class="col-md-6 align-self-center" v-if="!column.columnSaved && column.datasetColumnName && !column.clickedContinueOne">
                      <b-button
                        class="mt-2"
                        type="submit"
                        variant="primary"
                        @click="clickedContinueOneQuestion(columnIndex)"
                      >
                        {{
                          $t("uploadProcessStep2.form.general.continueButton")
                        }}
                        <i
                          class="bx bx-right-arrow-alt text-success bx-tada"
                        ></i>
                      </b-button>
                    </div>
                  </div>
                  
                </div>
                
                <!-- Checkboxes for OES -->
                <div class="col-md-4 mt-3 ml-3" v-if="column.columnType && column.columnType === columnTypesEnum.OPEN_ENDED">
                  <!-- Context Check for OPEN_ENDED -->
                  <b-form-checkbox
                    v-model="isEnableWrongTopicCheck"
                    :disabled="!column.isEnebleColumnEditing"
                    name="check-button"
                    switch
                    class="mt-2"
                  >
                    <label>
                      <span class="font-weight-600">
                        {{ $t("uploadProcessStep2.form.OES.wrongTopic.checkbox.label") }}
                      </span>
                      <span>
                        <i
                          v-b-popover.hover="propoverMethodWrongTopicEnable"
                          class="ml-2 bx bxs-info-circle text-info"
                          style="cursor: pointer"
                        ></i
                      ></span>
                    </label>
                  </b-form-checkbox>
            
                  <!-- Duplicate Check for OPEN_ENDED -->
                  <b-form-checkbox
                    v-model="enableDuplicateChecks"
                    :disabled="!column.isEnebleColumnEditing"
                    name="check-button"
                    switch
                    class=""
                  >
                    <label>
                      <span class="font-weight-600">
                        {{ $t("uploadProcessStep2.form.OES.duplicateChecks.checkBox") }}
                      </span>
                      <span>
                        <i
                          v-b-popover.hover="popoverMethodDuplicateChecks"
                          class="ml-2 bx bxs-info-circle text-info"
                          style="cursor: pointer"
                        ></i
                      ></span>
                    </label>
                  </b-form-checkbox>
                </div>
                
              </div>

              <!-- OPEN_ENDED -->
              <div class="mt-3" v-if="column.columnType === columnTypesEnum.OPEN_ENDED">
                <!-- Main OPEN_ENDED -->
                <div>
                  <div class="row mb-2">
                    <!-- Select Column -->
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="row">
                          <div class="col-md-8">
                            {{ $t("uploadProcessStep2.form.OES.additionalOpenEndedColumn.label") }}
                            <span class="text-danger">*</span>
                          </div>
                          <div class="col-md-4 text-right">
                            <b-badge v-if="column.datasetColumnName && column.isEnebleColumnEditing" variant="light" class="pointer" style="font-size:12px" @click="clickRemoveMainOpenEndedColumn(columnIndex)">
                              <i class="bx bx-sync pointer mr-1" ></i> {{ $t("uploadProcessStep2.form.general.resetButton")}}
                            </b-badge>
                          </div>
                        </label>

                        <select
                          v-if="column.datasetColumnName"
                          :disabled="column.datasetColumnName"
                          class="form-control"
                          style="
                            background: #f5f5f5;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                          "
                          :style="[column.validationError.isDisplayValidationError ? { border: '1px solid #f46a6a' } : { border: none }]"
                        >
                          <option>{{ column.datasetColumnName }}</option>
                        </select>

                        <multiselect
                          v-else
                          v-model="column.datasetColumnName"
                          :options="workingMetaDataArray"
                          @input="selectDatasetColumn(columnIndex)"
                          :placeholder="
                            $t(
                              'uploadProcessStep2.form.general.selectColumn.placeholder'
                            )
                          "
                        ></multiselect>

                        <!-- Validation Error for main OPEN_ENDED -->
                        <div class="mt-2" v-if="column.validationError.isDisplayValidationError">
                          <div v-for="(error, errorIndex) in column.validationError.errors" :key="errorIndex">
                            <div class="text-danger"> * {{ getTransationByErrorCode(error.validationErrorCode) }} </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <!-- OES Question Title -->
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>
                          {{ $t("uploadProcessStep2.form.OES.questionTitle.label") }}
                          <span class="text-danger">*</span>
                          <span>
                            <i
                              v-b-popover.hover="propoverMethodOESQuestion"
                              class="ml-2 bx bxs-info-circle text-info"
                              style="cursor: pointer"
                            ></i
                          ></span>
                          <span>
                            <p
                              v-if="column.questionTitle"
                              class="badge position-absolute ml-2"
                              :class="{
                                'bg-light':
                                  column.questionTitle.length !==
                                  oesQuestionTitleCharLimit,
                                'bg-danger':
                                  column.questionTitle.length ===
                                  oesQuestionTitleCharLimit
                              }"
                            >
                              {{ column.questionTitle.length }} /
                              {{ oesQuestionTitleCharLimit }}
                            </p>
                          </span>
                        </label>
                        <input
                          v-if="column.isEnebleColumnEditing"
                          v-model="column.questionTitle"
                          id="questionTitle"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              column.questionTitle &&
                              column.questionTitle.length >
                                oesQuestionTitleCharLimit
                          }"
                          name="questionTitle"
                          :placeholder="$t('uploadProcessStep2.form.OES.questionTitle.placeholder')"
                          @input="checkOESQuestionTitles"
                        />
                        <select
                          v-if="!column.isEnebleColumnEditing"
                          class="form-control"
                          :disabled="column.questionTitle"
                          style="
                            background: #f5f5f5;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                          "
                        >
                          <option>{{ column.questionTitle }}</option>
                        </select>
                      </div>
                    </div>

                    <!-- Keywords -->
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="row">
                          <div class="col-md-8">
                            {{ $t( "uploadProcessStep2.form.OES.wrongTopic.keywords.label" )}}
                            <span>
                              <i
                                v-b-popover.hover="propoverMethodOESQKeywords"
                                class="ml-1 bx bxs-info-circle text-info"
                                style="cursor: pointer"
                              ></i
                            ></span>
                          </div>
                          <div class="col-md-4 text-right">
                            <b-badge v-if="column.keywords.length > 0 && column.isEnebleColumnEditing" variant="light" class="pointer" style="font-size:12px" @click="clickResetKeywordsForMainOpenEndedColumn(columnIndex)">
                              <i class="bx bx-sync pointer mr-1" ></i> {{ $t("uploadProcessStep2.form.general.resetButton")}}
                            </b-badge>
                          </div>
                        </label>
                        <vue-tags-input
                          :disabled="!column.isEnebleColumnEditing || !isEnableWrongTopicCheck"
                          v-model="column.keywordsTag"
                          :tags="column.keywords"
                          :placeholder="$t('uploadProcessStep2.form.OES.wrongTopic.keywords.placeholder')"
                          @tags-changed="newTags => keywordsGetChangeForFirstOES(newTags, columnIndex)"
                          style="max-width: none; width 100%"
                          :style="[
                            !column.isEnebleColumnEditing || !isEnableWrongTopicCheck
                              ? { background: '#f5f5f5' }
                              : { background: '#ffffff' }
                          ]"
                          :max-tags="15"
                        />
                      </div>
                    </div>

                    <!-- OES Language Selection -->
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>
                          {{
                            $t(
                              "uploadProcessStep2.form.OES.selectExpectedLanguage.label"
                            )
                          }}
                          <span>
                            <i
                              v-b-popover.hover="
                                propoverMethodOESExpectedLanguages
                              "
                              class="ml-2 bx bxs-info-circle text-info"
                              style="cursor: pointer"
                            ></i
                          ></span>
                        </label>
                        <multiselect
                          v-if="!column.isEnebleColumnEditing"
                          v-model="column.languagesToCheck"
                          :multiple="true"
                          :options="allLanguages"
                          :placeholder="
                            $t(
                              'uploadProcessStep2.form.OES.selectExpectedLanguage.placeholder'
                            )
                          "
                          track-by="code"
                          :searchable="true"
                          :disabled="true"
                          label="code"
                          :custom-label="customLabelForLanguage"
                          :show-labels="false"
                        >
                          <template slot="singleLabel" slot-scope="{ option }">
                            {{ option.code }} - {{ option.name }}
                          </template>
                          <template slot="option" slot-scope="{ option }">
                            {{ option.code }} - {{ option.name }}
                          </template>
                        </multiselect>
                        <multiselect
                          v-else
                          v-model="column.languagesToCheck"
                          :multiple="true"
                          :options="allLanguages"
                          :placeholder="
                            $t(
                              'uploadProcessStep2.form.OES.selectExpectedLanguage.placeholder'
                            )
                          "
                          track-by="code"
                          :searchable="true"
                          label="code"
                          :custom-label="customLabelForLanguage"
                          :show-labels="false"
                        >
                          <template slot="singleLabel" slot-scope="{ option }">
                            {{ option.code }} - {{ option.name }}
                          </template>
                          <template slot="option" slot-scope="{ option }">
                            {{ option.code }} - {{ option.name }}
                          </template>
                        </multiselect>
                      </div>
                    </div>
                  </div>

                  <!-- Continue Button - OPEN_ENDED -->
                  <div class="row">
                    <div
                      class="col-md-12 text-left"
                      v-if="column.datasetColumnName && !column.clickedContinueOne"
                    >
                      <div class="form-group">
                        <b-button
                          class="mt-4"
                          type="submit"
                          variant="primary"
                          :disabled="
                            !column.questionTitle ||
                            !OESAllQuestionTitlesLengthValid
                          "
                          @click="clickedContinueOneOpenEnded(columnIndex)"
                        >
                          {{
                            $t("uploadProcessStep2.form.general.continueButton")
                          }}
                          <i
                            class="bx bx-right-arrow-alt text-success bx-tada"
                          ></i>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Additional OPEN_ENDED -->
                <div v-if="(column.isEnebleColumnEditing && column.clickedContinueOne) ||  (!column.isEnebleColumnEditing && column.openEndedList.length > 0)">
                  <!-- Title For Additional OPEN_ENDED -->
                  <div class="row mb-3">
                    <div class="col-md-12">
                      <hr />
                      <label>
                        {{ $t("uploadProcessStep2.form.OES.additionalOpenEndedColumn.title")}}
                      </label>
                    </div>
                  </div>

                  <!-- Additional OPEN_ENDED Questions -->
                  <div class="p-3 mb-4" v-for="(openEndedColumn, openEndedIndex) in column.openEndedList" :key="openEndedIndex" style="border: 1px solid #e3e3e3">
                    <div class="row mb-2">
                      <!-- Select Column -->
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="row">
                            <div class="col-md-8">
                              {{ $t("uploadProcessStep2.form.OES.additionalOpenEndedColumn.label") }}
                              <span class="text-danger">*</span>
                            </div>
                            <div class="col-md-4 text-right">
                              <b-badge v-if="openEndedColumn.datasetColumnName && column.isEnebleColumnEditing" variant="light" class="pointer" style="font-size:12px" @click="clickRemoveAdditionalOpenEndedColumn(columnIndex, openEndedIndex)">
                                <i class="bx bx-sync pointer mr-1" ></i> {{ $t("uploadProcessStep2.form.general.resetButton")}}
                              </b-badge>
                            </div>
                          </label>

                          <select
                            v-if="openEndedColumn.datasetColumnName"
                            class="form-control"
                            :disabled="openEndedColumn.datasetColumnName"
                            style="
                              background: #f5f5f5;
                              -webkit-appearance: none;
                              -moz-appearance: none;
                            "
                            :style="[openEndedColumn.validationError.isDisplayValidationError ? { border: '1px solid #f46a6a' } : { border: none }]"
                          >
                            <option>
                              {{ openEndedColumn.datasetColumnName }}
                            </option>
                          </select>

                          <multiselect
                            v-else
                            v-model="openEndedColumn.datasetColumnName"
                            :options="workingMetaDataArray"
                            @input="
                              selectAdditionalOpenEndedColumn(
                                columnIndex,
                                openEndedIndex
                              )
                            "
                            :placeholder="
                              $t(
                                'uploadProcessStep2.form.OES.additionalOpenEndedColumn.placeholder'
                              )
                            "
                          ></multiselect>

                          <!-- Validation Error for additional OPEN_ENDED -->
                          <div class="mt-2" v-if="openEndedColumn.validationError.isDisplayValidationError">
                            <div v-for="(error, errorIndex) in openEndedColumn.validationError.errors" :key="errorIndex">
                              <div class="text-danger"> * {{ getTransationByErrorCode(error.validationErrorCode) }} </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Remove Adition OES button -->
                      <div class="col-md-8 text-right">
                        <i
                          v-if="column.isEnebleColumnEditing"
                          class="bx bxs-x-square text-danger pointer"
                          style="font-size: 25px"
                          @click="
                            removeAdditionalOpenEndedColumn(columnIndex, openEndedIndex)
                          "
                        ></i>
                      </div>
                    </div>

                    <div class="row">
                      <!-- Question Title -->
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>
                            {{
                              $t(
                                "uploadProcessStep2.form.OES.questionTitle.label"
                              )
                            }}
                            <span class="text-danger">*</span>
                            <span>
                              <i
                                v-b-popover.hover.html="
                                  propoverMethodOESQuestion
                                "
                                class="ml-2 bx bxs-info-circle text-info"
                                style="cursor: pointer"
                              ></i
                            ></span>
                            <span>
                              <p
                                v-if="openEndedColumn.questionTitle"
                                class="badge position-absolute ml-2"
                                :class="{
                                  'bg-light':
                                    openEndedColumn.questionTitle.length !==
                                    oesQuestionTitleCharLimit,
                                  'bg-danger':
                                    openEndedColumn.questionTitle.length ===
                                    oesQuestionTitleCharLimit
                                }"
                              >
                                {{ openEndedColumn.questionTitle.length }} /
                                {{ oesQuestionTitleCharLimit }}
                              </p>
                            </span>
                          </label>
                          <input
                            v-model="openEndedColumn.questionTitle"
                            id="questionTitle"
                            class="form-control"
                            :class="{
                              'is-invalid':
                                openEndedColumn.questionTitle &&
                                openEndedColumn.questionTitle.length >
                                  oesQuestionTitleCharLimit
                            }"
                            :disabled="!column.isEnebleColumnEditing"
                            name="questionTitle"
                            @input="checkOESQuestionTitles"
                            :placeholder="$t('uploadProcessStep2.form.OES.questionTitle.placeholder')"
                          />
                        </div>
                      </div>

                      <!-- Keywords -->
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="row">
                            <div class="col-md-9">
                              <span class="font-weight-600">
                                {{ $t("uploadProcessStep2.form.OES.wrongTopic.keywords.label") }}
                              </span>
                              <span>
                                <i
                                  v-b-popover.hover="propoverMethodOESQKeywords"
                                  class="ml-1 bx bxs-info-circle text-info"
                                  style="cursor: pointer"
                                ></i
                              ></span>
                            </div>
                            <div class="col-md-3 text-right">
                              <b-badge v-if="openEndedColumn.keywords.length > 0 && column.isEnebleColumnEditing" variant="light" class="pointer" style="font-size:12px" @click="clickResetKeywordsForAdditionalOpenEndedColumn(columnIndex, openEndedIndex)">
                                <i class="bx bx-sync pointer mr-1" ></i> {{ $t("uploadProcessStep2.form.general.resetButton")}}
                              </b-badge>
                            </div>
                          </label>
                          <vue-tags-input
                            style="max-width: none; width 100%"
                            :disabled="!column.isEnebleColumnEditing || !isEnableWrongTopicCheck"
                            v-model="openEndedColumn.keywordsTag"
                            :tags="openEndedColumn.keywords"
                            :placeholder="$t('uploadProcessStep2.form.OES.wrongTopic.keywords.placeholder')"
                            @tags-changed="newTags => keywordsGetChangeOtherOES(newTags, columnIndex, openEndedIndex)"
                            :style="[
                              !column.isEnebleColumnEditing || !isEnableWrongTopicCheck
                                ? { background: '#f5f5f5' }
                                : { background: '#ffffff' }
                            ]"
                            :max-tags="15"
                          />
                        </div>
                      </div>

                      <!-- Language -->
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>
                            {{
                              $t(
                                "uploadProcessStep2.form.OES.selectExpectedLanguage.label"
                              )
                            }}
                            <span>
                              <i
                                v-b-popover.hover="
                                  propoverMethodOESExpectedLanguages
                                "
                                class="ml-2 bx bxs-info-circle text-info"
                                style="cursor: pointer"
                              ></i></span
                          ></label>

                          <multiselect
                            v-if="!column.isEnebleColumnEditing"
                            v-model="openEndedColumn.languagesToCheck"
                            :multiple="true"
                            :options="allLanguages"
                            :placeholder="
                              $t(
                                'uploadProcessStep2.form.general.selectColumnType.placeholder'
                              )
                            "
                            track-by="code"
                            :searchable="true"
                            :disabled="true"
                            label="code"
                            :custom-label="customLabelForLanguage"
                            :show-labels="false"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                            >
                              {{ option.code }} - {{ option.name }}
                            </template>
                            <template slot="option" slot-scope="{ option }">
                              {{ option.code }} - {{ option.name }}
                            </template>
                          </multiselect>
                          <multiselect
                            v-else
                            v-model="openEndedColumn.languagesToCheck"
                            :multiple="true"
                            :options="allLanguages"
                            :placeholder="
                              $t(
                                'uploadProcessStep2.form.general.selectColumnType.placeholder'
                              )
                            "
                            track-by="code"
                            :searchable="true"
                            label="code"
                            :custom-label="customLabelForLanguage"
                            :show-labels="false"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                            >
                              {{ option.code }} - {{ option.name }}
                            </template>
                            <template slot="option" slot-scope="{ option }">
                              {{ option.code }} - {{ option.name }}
                            </template>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Add Aditional OPEN_ENDED Question -->
                  <div class="row mt-3">
                    <div class="col-md-4" v-if="column.isEnebleColumnEditing && !maxOESQuestionsReached">
                      <div
                        class="p-3 mt-2 pointer"
                        style="border: 1px solid #f5f5f5"
                        @click="clickAddNewOpenEndedColumn(columnIndex)"
                      >
                        <i class="bx bx-plus-medical bx-tada"></i>
                        {{
                          $t(
                            "uploadProcessStep2.form.OES.additionalOpenEndedColumn.button"
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>

                <!--  Save Button -->
                <div class="row" v-if="column.clickedContinueOne">
                  <div class="col-md-12" v-if="!column.columnSaved">
                    <div class="d-flex align-items-center">
                      <b-button
                        v-if="!column.validationInProgress"
                        class="mt-3"
                        type="submit"
                        variant="primary"
                        :disabled="
                          !OESAllQuestionTitlesThere ||
                          !OESAllQuestionTitlesLengthValid ||
                          !OESAllQuestionSpecifedColumns
                        "
                        @click="handleClickOnSaveColumn(columnIndex)"
                      >
                        <span>
                          {{ $t("uploadProcessStep2.form.general.saveButton") }}
                          <i class="bx bx-check text-success bx-tada"></i>
                        </span>
                      </b-button>
                      <div class="mt-3" v-else>
                        <i class='mr-2 bx bx-loader-circle bx-spin' ></i> {{$t("uploadProcessStep2.form.general.validationInProgress")}}
                      </div>
                      <div v-if="column.validationError.displayServerError" class="text-danger mt-3 ml-3">
                        {{ getTransationByErrorCode(errorCodesEnum.SERVER_ERROR) }}
                      </div> 
                    </div>                   
                  </div>                  
                </div>
              </div>

              <!-- ITEMBATTERY -->
              <div v-if="column.columnType && column.columnType === columnTypesEnum.ITEMBATTERY">
                <!-- Main ITEMBATTERY -->
                <div>
                  <div class="row mt-3">
                    <!-- Specify column for Main ITEMBATTERY Range Start-->
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>
                          {{ $t("uploadProcessStep2.form.IBS.selectStartColumn.label")}}
                          <span class="text-danger">*</span>
                        </label>

                        <select
                          v-if="column.datasetColumnName"
                          :disabled="column.datasetColumnName"
                          class="form-control"
                          style="
                            background: #f5f5f5;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                          "
                          :style="[column.validationError.isDisplayValidationError ? { border: '1px solid #f46a6a' } : { border: none }]"
                        >
                          <option>{{ column.datasetColumnName }}</option>
                        </select>

                        <multiselect
                          v-else
                          v-model="column.datasetColumnName"
                          :options="workingMetaDataArray"
                          @input="selectDatasetColumn(columnIndex)"
                          :placeholder="
                            $t(
                              'uploadProcessStep2.form.general.selectColumn.placeholder'
                            )
                          "
                        ></multiselect>
                      </div>
                    </div>

                    <!-- Specify column for Main ITEMBATTERY Range End-->
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>
                          {{ $t( "uploadProcessStep2.form.IBS.selectEndColumn.label" ) }}
                          <span class="text-danger">*</span>
                        </label>

                        <select
                          v-if="column.itemBatteryEndRangeName"
                          :disabled="column.itemBatteryEndRangeName"
                          class="form-control"
                          style="
                            background: #f5f5f5;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                          "
                          :style="[column.validationError.isDisplayValidationError ? { border: '1px solid #f46a6a' } : { border: none }]"
                        >
                          <option>{{ column.itemBatteryEndRangeName }}</option>
                        </select>

                        <multiselect
                          v-else
                          v-model="column.itemBatteryEndRangeName"
                          :options="workingMetaDataArray"
                          :allow-empty="false"
                          @input="selectDatasetColumn(columnIndex)"
                          :placeholder="
                            $t(
                              'uploadProcessStep2.form.IBS.selectEndColumn.placeholder'
                            )
                          "
                        ></multiselect>

                        <div style="color: #f46a6a" v-if="column.endRangeError">
                          {{ $t("uploadProcessStep2.form.IBS.selectEndColumn.error") }}
                        </div>
                      </div>
                    </div>

                    <!-- Pattern Recognition for GQS -->
                    <div class="col-md-3">
                      <div class="custom-control custom-checkbox pt-2 mt-4">
                        <b-form-checkbox
                          v-model="enablePatternCheckMain"
                          :disabled="!column.isEnebleColumnEditing"
                          name="check-button"
                          switch
                          class=""
                        >
                          <label>
                            <span class="font-weight-600">
                              {{ $t("uploadProcessStep2.form.IBS.patternCheck.checkBox") }}
                            </span>
                            <span>
                              <i
                                v-b-popover.hover="popoverMethodPatternChecks"
                                class="ml-2 bx bxs-info-circle text-info"
                                style="cursor: pointer"
                              ></i
                            ></span>
                          </label>
                        </b-form-checkbox> 
                      </div>                  
                    </div>

                    <!-- Remove Button for Main ITEMBATTERY -->
                    <div class="col-md-1 text-left" v-if="column.datasetColumnName && column.itemBatteryEndRangeName && column.isEnebleColumnEditing">
                      <div class="form-group">
                        <i
                          class="bx bxs-x-square text-danger pointer pt-4 mt-2"
                          style="font-size: 25px"
                          @click="clickRemoveMainIB(columnIndex)"
                        ></i>
                      </div>
                    </div>            
                    
                    <!-- Continue Button - Step 1 for ITEMBATTERY -->
                    <div class="col-md-4 text-left" v-if="column.datasetColumnName && column.itemBatteryEndRangeName && !column.clickedContinueOne">
                      <div class="form-group">
                        <b-button
                          class="mt-4"
                          type="submit"
                          variant="primary"
                          @click="clickedContinueOneItemBattery(columnIndex)"
                        >
                          {{
                            $t("uploadProcessStep2.form.general.continueButton")
                          }}
                          <i
                            class="bx bx-right-arrow-alt text-success bx-tada"
                          ></i>
                        </b-button>
                      </div>
                    </div>
                  </div>

                  <!-- Validation Error for main ITEMBATTERY -->
                  <div class="row" v-if="column.validationError.isDisplayValidationError">
                    <div class="col-md-12" v-for="(error, errorIndex) in column.validationError.errors" :key="errorIndex">
                      <div class="text-danger"> * {{ getTransationByErrorCode(error.validationErrorCode) }} </div>
                    </div>
                  </div>
                </div>
                
                <!-- Additional ITEMBATTERY -->
                <div v-if="(column.clickedContinueOne && column.isEnebleColumnEditing) || (!column.isEnebleColumnEditing && column.itemBatteryRanges.length > 0)">
                  <!-- Title For Additional ITEMBATTERIES -->
                  <div class="row">
                    <div class="col-md-12">
                      <hr />
                      <label>
                        {{ $t("uploadProcessStep2.form.IBS.additionalItemRange.title")}}
                      </label>
                    </div>
                  </div>

                  <!-- Display Additional ITEMBATTERIES -->
                  <div class="row">
                    <!-- Aditional ITEMBATTERY -->
                    <div class="col-md-12" v-for="(IBcolumn, IBIndex) in column.itemBatteryRanges" :key="IBIndex">
                      <div class="form-group">
                        <div class="row">
                          <!-- Aditional ITEMBATTERY Range Start -->
                          <div class="col-md-4">
                            <label>
                              {{
                                $t(
                                  "uploadProcessStep2.form.IBS.selectStartColumn.label"
                                )
                              }}
                            </label>
                            
                            <select
                              v-if="IBcolumn.rangeStartName"
                              class="form-control"
                              :disabled="IBcolumn.rangeStartName"
                              style="
                                background: #f5f5f5;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                              "
                              :style="[IBcolumn.validationError.isDisplayValidationError ? { border: '1px solid #f46a6a' } : { border: none }]"
                            >
                              <option>{{ IBcolumn.rangeStartName }}</option>
                            </select>
                            
                            <multiselect
                              v-else
                              v-model="IBcolumn.rangeStartName"
                              :options="workingMetaDataArray"
                              @input="
                                selectAdditionalIBRange(
                                  columnIndex,
                                  IBIndex,
                                  true
                                )
                              "
                              :placeholder="
                                $t(
                                  'uploadProcessStep2.form.IBS.selectStartColumn.placeholder'
                                )
                              "
                            ></multiselect>
                          </div>

                          <!-- Aditional ITEMBATTERY Range End -->
                          <div class="col-md-4">
                            <label> {{ $t("uploadProcessStep2.form.IBS.selectEndColumn.label")}}</label>
                            
                            <select
                              v-if="IBcolumn.rangeEndName"
                              class="form-control"
                              :disabled="IBcolumn.rangeEndName"
                              style="
                                background: #f5f5f5;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                              "
                              :style="[IBcolumn.validationError.isDisplayValidationError ? { border: '1px solid #f46a6a' } : { border: none }]"
                            >
                              <option>{{ IBcolumn.rangeEndName }}</option>
                            </select>
                            
                            <multiselect
                              v-else
                              v-model="IBcolumn.rangeEndName"
                              :options="workingMetaDataArray"
                              @input="
                                selectAdditionalIBRange(
                                  columnIndex,
                                  IBIndex,
                                  false
                                )
                              "
                              :placeholder="
                                $t(
                                  'uploadProcessStep2.form.IBS.selectEndColumn.placeholder'
                                )
                              "
                            ></multiselect>

                            <div style="color: #f46a6a" v-if="IBcolumn.endRangeError">
                              {{ $t("uploadProcessStep2.form.IBS.selectEndColumn.error") }}
                            </div>
                          </div>

                          <!-- Pattern Recognition for GQS for Additional ITEMBATTERY -->
                          <div class="col-md-3">
                            <div class="custom-control custom-checkbox pt-2 mt-4">
                              <b-form-checkbox
                                v-model="enablePatternCheckAdditional[IBIndex]"
                                :disabled="!column.isEnebleColumnEditing"
                                name="check-button"
                                switch
                                class=""
                              >
                                <label>
                                  <span class="font-weight-600">
                                    {{ $t("uploadProcessStep2.form.IBS.patternCheck.checkBox") }}
                                  </span>
                                  <span>
                                    <i
                                      v-b-popover.hover="popoverMethodPatternChecks"
                                      class="ml-2 bx bxs-info-circle text-info"
                                      style="cursor: pointer"
                                    ></i
                                  ></span>
                                </label>
                              </b-form-checkbox>
                            </div>                  
                          </div>

                           <!-- Aditional ITEMBATTERY Remove -->
                           <div class="col-md-1" v-if="column.isEnebleColumnEditing">
                            <i
                              class="bx bxs-x-square text-danger pointer pt-4 mt-2"
                              style="font-size: 25px"
                              @click="
                                removeAdditionalIBRangeFull(
                                  columnIndex,
                                  IBIndex
                                )
                              "
                            ></i>
                          </div>
                        </div>

                        <!-- Validation Error for additional ITEMBATTERY -->
                        <div class="row mt-3" v-if="IBcolumn.validationError.isDisplayValidationError">
                          <div class="col-md-12" v-for="(error, errorIndex) in IBcolumn.validationError.errors" :key="errorIndex">
                            <div class="text-danger"> * {{ getTransationByErrorCode(error.validationErrorCode) }} </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Add Aditional ITEMBATTERY -->
                    <div class="col-md-8" v-if="column.isEnebleColumnEditing">
                      <div
                        class="p-3 mt-2 pointer"
                        style="border: 1px solid #f5f5f5"
                        @click="clickAddAdditionalRange(columnIndex)"
                      >
                        <i class="bx bx-plus-medical bx-tada"></i>
                        {{
                          $t(
                            "uploadProcessStep2.form.IBS.additionalItemRange.button"
                          )
                        }}
                      </div>
                    </div>
                  </div>

                  <!-- Save Button for ITEMBATTERY -->
                  <div class="row">
                    <div class="col-md-12" v-if="column.isEnebleColumnEditing && !column.columnSaved">
                      <div class="d-flex align-items-center">
                        <b-button
                          v-if="!column.validationInProgress"
                          class="mt-3"
                          type="submit"
                          variant="primary"
                          @click="handleClickOnSaveColumn(columnIndex)"
                          :disabled="!column.datasetColumnName || !column.itemBatteryEndRangeName"
                        >
                          <span>
                            {{ $t("uploadProcessStep2.form.general.saveButton") }}
                            <i class="bx bx-check text-success bx-tada"></i>
                          </span>
                        </b-button>
                        <div class="mt-3" v-else>
                          <i class='mr-2 bx bx-loader-circle bx-spin' ></i> {{$t("uploadProcessStep2.form.general.validationInProgress")}}
                        </div>
                        <div v-if="column.validationError.displayServerError" class="text-danger mt-3 ml-3">
                          {{ getTransationByErrorCode(errorCodesEnum.SERVER_ERROR) }}
                        </div>                         
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Additional TIMESTAMPS Columns & GROUPING_COLUMN -->
              <div v-if="column.columnType && column.columnType === columnTypesEnum.TIMESTAMP && column.clickedContinueOne">
                <!-- Display Aditional TIMESTAMPS -->
                <div class="row mt-3" v-if="column.isEnebleColumnEditing || (!column.isEnebleColumnEditing && column.timeStampsList.length > 0)">
                  <!-- Title For Additional TIMESTAMPS -->
                  <div class="col-md-12">
                    <hr />
                    <label>
                      {{ $t("uploadProcessStep2.form.TS.additionalTimeColumn.title")}}
                    </label>
                  </div>

                  <!-- Additional TIMESTAMPS -->
                  <div
                    class="col-md-4 mb-3"
                    v-for="(timeStampColumn, timeStampIndex) in column.timeStampsList"
                    :key="timeStampIndex"
                  >
                    <div class="form-group">
                      <label>
                        {{ $t("uploadProcessStep2.form.TS.additionalTimeColumn.label") }}
                        <span
                          v-if="column.isEnebleColumnEditing"
                          style="cursor: pointer; font-size: 15px"
                          @click="
                            removeAdditionalTimeStampColumn(
                              columnIndex,
                              timeStampIndex
                            )
                          "
                        >
                          <i class="bx bxs-x-circle text-danger"></i>
                        </span>
                      </label>
                      <select
                        v-if="timeStampColumn.datasetColumnName"
                        class="form-control"
                        :disabled="timeStampColumn.datasetColumnName"
                        style="
                          background: #f5f5f5;
                          -webkit-appearance: none;
                          -moz-appearance: none;
                        "
                        :style="[timeStampColumn.validationError.isDisplayValidationError ? { border: '1px solid #f46a6a' } : { border: none }]"
                      >
                        <option>
                          {{ timeStampColumn.datasetColumnName }}
                        </option>
                      </select>
                      <multiselect
                        v-else
                        v-model="timeStampColumn.datasetColumnName"
                        :options="workingMetaDataArray"
                        @input="
                          selectAdditionalTimeStampColumn(
                            columnIndex,
                            timeStampIndex
                          )
                        "
                        :placeholder="
                          $t(
                            'uploadProcessStep2.form.TS.additionalTimeColumn.placeholder'
                          )
                        "
                      ></multiselect>
                    </div>

                    <!-- Validation Error -->
                    <div class="row mt-3" v-if="timeStampColumn.validationError.isDisplayValidationError">
                      <div class="col-md-12" v-for="(error, errorIndex) in timeStampColumn.validationError.errors" :key="errorIndex">
                        <div class="text-danger"> * {{ getTransationByErrorCode(error.validationErrorCode) }} </div>
                      </div>
                    </div>
                  </div>

                  <!-- Add New TIMESTAMP -->
                  <div class="col-md-4" v-if="column.isEnebleColumnEditing">
                    <div
                      class="p-3 mt-3 pointer"
                      style="border: 1px solid #f5f5f5"
                      @click="clickAddNewTimeStampColumn(columnIndex)"
                    >
                      <i class="bx bx-plus-medical bx-tada"></i>
                      {{
                        $t(
                          "uploadProcessStep2.form.TS.additionalTimeColumn.button"
                        )
                      }}
                    </div>
                  </div>
                </div>

                <!-- GROUPING_COLUMN -->
                <div class="row mt-3" v-if="column.isEnebleColumnEditing || (!column.isEnebleColumnEditing && column.groupSelector.length > 0)">
                  <!-- Title For GROUPING_COLUMN -->
                  <div class="col-md-12">
                    <hr />
                    <label>
                      {{ $t("uploadProcessStep2.form.TS.groupSelector.title") }}
                    </label>
                    <p> {{ $t( "uploadProcessStep2.form.TS.groupSelector.description") }}</p>
                  </div>

                  <!-- Group Selector List -->
                  <div
                    class="col-md-4"
                    v-for="(groupSelectorColumn, groupSelectorIndex) in column.groupSelector"
                    :key="groupSelectorIndex"
                  >
                    <div class="form-group">
                      <label>
                        {{ $t("uploadProcessStep2.form.TS.groupSelector.label") }}
                        <span
                          v-if="column.isEnebleColumnEditing"
                          style="cursor: pointer; font-size: 15px"
                          @click="
                            removeGroupSelectorColumn(
                              columnIndex,
                              groupSelectorIndex
                            )
                          "
                        >
                          <i class="bx bxs-x-circle text-danger"></i>
                        </span>
                      </label>
                      <select
                        v-if="groupSelectorColumn.datasetColumnName"
                        class="form-control"
                        :disabled="groupSelectorColumn.datasetColumnName"
                        style="
                          background: #f5f5f5;
                          -webkit-appearance: none;
                          -moz-appearance: none;
                        "
                        :style="[groupSelectorColumn.validationError.isDisplayValidationError ? { border: '1px solid #f46a6a' } : { border: none }]"
                      >
                        <option>
                          {{ groupSelectorColumn.datasetColumnName }}
                        </option>
                      </select>
                      <multiselect
                        v-else
                        v-model="groupSelectorColumn.datasetColumnName"
                        :options="workingMetaDataArray"
                        @input="
                          selectGroupSelectorColumn(
                            columnIndex,
                            groupSelectorIndex
                          )
                        "
                        :placeholder="
                          $t(
                            'uploadProcessStep2.form.TS.groupSelector.placeholder'
                          )
                        "
                      ></multiselect>
                    </div>

                    <!-- Validation Error -->
                    <div class="row mt-3" v-if="groupSelectorColumn && groupSelectorColumn.validationError.isDisplayValidationError">
                      <div class="col-md-12" v-for="(error, errorIndex) in groupSelectorColumn.validationError.errors" :key="errorIndex">
                        <div class="text-danger"> * {{ getTransationByErrorCode(error.validationErrorCode) }} </div>
                      </div>
                    </div>
                  </div>
                  
                  <!-- Add Group Selector -->
                  <div class="col-md-4">
                    <div
                      v-if="
                        column.isEnebleColumnEditing &&
                        column.groupSelector.length === 0
                      "
                      class="p-3 mt-2 pointer"
                      style="border: 1px solid #f5f5f5"
                      @click="clickAddGroupSelectionColumn(columnIndex)"
                    >
                      <i class="bx bx-plus-medical bx-tada"></i>
                      {{
                        $t(
                          "uploadProcessStep2.form.TS.groupSelector.addGroupButton"
                        )
                      }}
                    </div>
                  </div>
                </div>

                <!-- Save Button-->
                <div class="row">
                  <div class="col-md-12 mt-3" v-if="column.isEnebleColumnEditing && !column.columnSaved">
                    <div class="d-flex align-items-center">
                      <b-button
                        v-if="!column.validationInProgress"
                        class="mt-3"
                        type="submit"
                        variant="primary"
                        @click="handleClickOnSaveColumn(columnIndex)"
                        :disabled="!column.datasetColumnName"
                      >
                        <span>
                          {{ $t("uploadProcessStep2.form.general.saveButton") }}
                          <i class="bx bx-check text-success bx-tada"></i>
                        </span>
                      </b-button>
                      <div class="mt-3" v-else>
                        <i class='mr-2 bx bx-loader-circle bx-spin' ></i> {{$t("uploadProcessStep2.form.general.validationInProgress")}}
                      </div>
                      <div v-if="column.validationError.displayServerError" class="text-danger mt-3 ml-3">
                        {{ getTransationByErrorCode(errorCodesEnum.SERVER_ERROR) }}
                      </div>                    
                    </div>
                  </div>
                </div>
              </div>

              <!-- Prediction for PROJECTIVE_QUESTION -->
              <div v-if="column.columnType && column.columnType === columnTypesEnum.PROJECTIVE_QUESTION && column.clickedContinueOne && column.isAllAnswersGet && !column.isAnswerListHavingAnError">
                <!-- Title For Prediction -->
                <div class="row">
                  <div class="col-md-12">
                    <hr />
                    <label>
                      {{ $t( "uploadProcessStep2.form.PQ.answerOptions.title" )}}
                    </label>
                  </div>
                </div>
                
                <!-- Prediction Columns-->
                <div class="row mt-3">
                  <div
                    class="col-md-4 mb-3"
                    v-for="( answer, answerIndex) in column.answersList"
                    :key="answerIndex"
                  >
                    <div class="row pr-2">
                      <!-- Answer -->
                      <div class="col-md-5">
                        <label>
                          {{
                            $t(
                              "uploadProcessStep2.form.PQ.answerOptions.answerLabel"
                            )
                          }}
                        </label>
                        <input
                          v-model="column.answersList[answerIndex]"
                          id="answer"
                          type="number"
                          class="form-control"
                          name="answer"
                          :disabled="true"
                          style="background: #f5f5f5"
                        />
                      </div>

                      <!-- Prediction -->
                      <div class="col-md-7">
                        <div class="form-group">
                          <label class="row">
                            <div class="col-md-6">
                              {{ $t("uploadProcessStep2.form.PQ.answerOptions.prediction.label") }}
                            </div>
                            <div class="col-md-6 text-right">
                              <b-badge v-if="column.predictionsList[answerIndex].datasetColumnName && column.isEnebleColumnEditing" variant="light" class="pointer" style="font-size:12px" @click="editSelectedPrediction(columnIndex,answerIndex)">
                                <i class="bx bx-sync pointer mr-1" ></i> {{ $t("uploadProcessStep2.form.general.resetButton")}}
                              </b-badge>
                            </div>
                          </label>
                          <select
                            v-if="
                              column.predictionsList[answerIndex]
                                .datasetColumnName
                            "
                            v-model="
                              column.predictionsList[answerIndex]
                                .datasetColumnName
                            "
                            class="form-control"
                            :disabled="
                              column.predictionsList[answerIndex]
                                .datasetColumnName
                            "
                            style="
                              background: #f5f5f5;
                              -webkit-appearance: none;
                              -moz-appearance: none;
                            "
                            :style="[column.predictionsList[answerIndex].validationError.isDisplayValidationError ? { border: '1px solid #f46a6a' } : { border: none }]"
                          >
                            <option>
                              {{
                                column.predictionsList[answerIndex]
                                  .datasetColumnName
                              }}
                            </option>
                          </select>
                          <multiselect
                            v-else
                            v-model="
                              column.predictionsList[answerIndex]
                                .datasetColumnName
                            "
                            :options="workingMetaDataArray"
                            :placeholder="
                              $t(
                                'uploadProcessStep2.form.PQ.answerOptions.prediction.placeholder'
                              )
                            "
                            @input="
                              selectPredictionForAnswer(
                                columnIndex,
                                answerIndex
                              )
                            "
                            :disabled="!column.isEnebleColumnEditing"
                          ></multiselect>
                        </div>
                      </div>
                    </div>

                    <!-- Validation Error -->
                    <div class="row" v-if="column.predictionsList[answerIndex].validationError.isDisplayValidationError">
                      <div class="col-md-12" v-for="(error, errorIndex) in column.predictionsList[answerIndex].validationError.errors" :key="errorIndex">
                        <div class="text-danger"> * {{ getTransationByErrorCode(error.validationErrorCode) }} </div>
                      </div>
                    </div>
                  </div>

                  <!-- New Answer Option -->
                  <div
                    class="col-md-4"
                    v-if="column.isEnebleColumnEditing"
                  >
                    <div
                      class="p-3 mt-3 pointer"
                      style="border: 1px solid #f5f5f5"
                      @click="clickAddNewAnswerOption(columnIndex)"
                    >
                      <i class="bx bx-plus-medical bx-tada"></i>
                      {{
                        $t(
                          "uploadProcessStep2.form.PQ.answerOptions.addNewAnswerButton"
                        )
                      }}
                    </div>
                  </div>
                </div>
                
                <!-- Continue Button -->
                <div
                  class="mt-3"
                  v-if="
                    column.isAllAnswersGet &&
                    !column.clickedContinueTwo &&
                    column.savedPredictionCount > 1
                  "
                >
                  <b-button
                    class="mt-2"
                    type="submit"
                    variant="primary"
                    @click="
                      handleClickOnContinuePhaseTwo(columnIndex)
                    "
                  >
                    {{
                      $t(
                        "uploadProcessStep2.form.general.continueButton"
                      )
                    }}
                    <i
                      class="bx bx-right-arrow-alt text-success bx-tada"
                    ></i>
                  </b-button>
                </div>

                <hr v-if="column.clickedContinueTwo" />

                <!-- Quality Score Selection for Questions -->
                <div
                  class="mt-4"
                  v-if=" column.columnType === 'PROJECTIVE_QUESTION' && column.clickedContinueTwo"
                >
                  <div class="form-group">
                    <label>
                      {{ $t( "uploadProcessStep2.form.PQ.selectQualitySocre.title") }}
                      <span class="text-danger">*</span></label
                    >
                    <div class="row mt-3">
                      <!-- Quality score Selections-->
                      <div class="col-md-12">
                        <div class="row">
                          <!-- PS -->
                          <div class="col-md-6">
                            <div class="custom-control custom-checkbox">
                              <input
                                :disabled="!column.isEnebleColumnEditing"
                                type="checkbox"
                                class="custom-control-input"
                                :id="
                                  'PSQualityScore' + column.datasetColumnIndex
                                "
                                v-model="column.qualityScores.PS"
                              />

                              <label
                                class="custom-control-label"
                                :for="
                                  'PSQualityScore' + column.datasetColumnIndex
                                "
                              >
                                <span class="font-weight-normal">
                                  {{
                                    $t(
                                      "uploadProcessStep2.form.PQ.selectQualitySocre.PS.title"
                                    )
                                  }}
                                </span>
                              </label>
                              <i
                                class="bx bx-info-circle text-info ml-1 pointer"
                                @click="handleClickOnPSInfo()"
                              ></i>
                            </div>
                            <ul v-if="showPSDescription" class="mt-2">
                              <li>
                                {{
                                  $t(
                                    "uploadProcessStep2.form.PQ.selectQualitySocre.PS.description1"
                                  )
                                }}
                              </li>
                              <li>
                                {{
                                  $t(
                                    "uploadProcessStep2.form.PQ.selectQualitySocre.PS.description2"
                                  )
                                }}
                              </li>
                            </ul>
                          </div>

                          <!-- CS -->
                          <div class="col-md-6">
                            <div class="custom-control custom-checkbox">
                              <input
                                :disabled="!column.isEnebleColumnEditing"
                                type="checkbox"
                                class="custom-control-input"
                                :id="
                                  'CSQualityScore' + column.datasetColumnIndex
                                "
                                v-model="column.qualityScores.CS"
                              />
                              <label
                                class="custom-control-label"
                                :for="
                                  'CSQualityScore' + column.datasetColumnIndex
                                "
                              >
                                <span class="font-weight-normal">
                                  {{
                                    $t(
                                      "uploadProcessStep2.form.PQ.selectQualitySocre.CS.title"
                                    )
                                  }}
                                </span>
                              </label>
                              <i
                                class="bx bx-info-circle text-info ml-1 pointer"
                                @click="handleClickOnCSInfo()"
                              ></i>
                            </div>
                            <ul v-if="showCSDescription" class="mt-2">
                              <li>
                                {{
                                  $t(
                                    "uploadProcessStep2.form.PQ.selectQualitySocre.CS.description1"
                                  )
                                }}
                              </li>
                              <li>
                                {{
                                  $t(
                                    "uploadProcessStep2.form.PQ.selectQualitySocre.CS.description2"
                                  )
                                }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <!-- Optional Quality score Selections-->
                      <div class="col-md-12 mt-3">
                        <div class="row">
                          <!-- IS -->
                          <div class="col-md-6">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :id="
                                  'ISQualityScore' + column.datasetColumnIndex
                                "
                                v-model="column.qualityScores.IS"
                                :disabled="
                                  !column.isEnebleColumnEditing ||
                                  column.qualityScores.SDS
                                "
                              />
                              <label
                                class="custom-control-label"
                                :for="
                                  'ISQualityScore' + column.datasetColumnIndex
                                "
                              >
                                <span class="font-weight-normal">
                                  {{
                                    $t(
                                      "uploadProcessStep2.form.PQ.selectQualitySocre.IS.title"
                                    )
                                  }}
                                </span>
                              </label>
                              <i
                                class="bx bx-info-circle text-info ml-1"
                                @click="handleClickOnISInfo()"
                              ></i>
                            </div>
                            <ul v-if="showISDescription" class="mt-2">
                              <li>
                                {{
                                  $t(
                                    "uploadProcessStep2.form.PQ.selectQualitySocre.IS.description1"
                                  )
                                }}
                              </li>
                              <li>
                                {{
                                  $t(
                                    "uploadProcessStep2.form.PQ.selectQualitySocre.IS.description2"
                                  )
                                }}
                              </li>
                              <li>
                                {{
                                  $t(
                                    "uploadProcessStep2.form.PQ.selectQualitySocre.IS.description3"
                                  )
                                }}
                              </li>
                            </ul>
                          </div>

                          <!-- SDS -->
                          <div class="col-md-6">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :id="
                                  'SDSQualityScore' +
                                  column.datasetColumnIndex
                                "
                                v-model="column.qualityScores.SDS"
                                :disabled="
                                  !column.isEnebleColumnEditing ||
                                  column.qualityScores.IS
                                "
                              />
                              <label
                                class="custom-control-label"
                                :for="
                                  'SDSQualityScore' +
                                  column.datasetColumnIndex
                                "
                              >
                                <span class="font-weight-normal">
                                  {{
                                    $t(
                                      "uploadProcessStep2.form.PQ.selectQualitySocre.SDS.title"
                                    )
                                  }}
                                </span>
                              </label>
                              <i
                                class="bx bx-info-circle text-info ml-1 pointer"
                                @click="handleClickOnSDSInfo()"
                              ></i>
                            </div>
                            <ul v-if="showSDSDescription" class="mt-2">
                              <li>
                                {{
                                  $t(
                                    "uploadProcessStep2.form.PQ.selectQualitySocre.SDS.description1"
                                  )
                                }}
                              </li>
                              <li>
                                {{
                                  $t(
                                    "uploadProcessStep2.form.PQ.selectQualitySocre.SDS.description2"
                                  )
                                }}
                              </li>
                              <li>
                                {{
                                  $t(
                                    "uploadProcessStep2.form.PQ.selectQualitySocre.SDS.description3"
                                  )
                                }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Save Button for PROJECTIVE_QUESTION -->
                <div
                  class="row"
                  v-if="
                    column.columnType === 'PROJECTIVE_QUESTION' &&
                    column.clickedContinueTwo &&
                    (column.qualityScores.IS ||
                      column.qualityScores.PS ||
                      column.qualityScores.SDS ||
                      column.qualityScores.CS)
                  "
                >
                  <div class="col-md-12 mt-3" v-if="!column.columnSaved">
                    <div class="d-flex align-items-center">
                      <b-button
                        class="mt-3"
                        v-if="!column.validationInProgress"
                        type="submit"
                        variant="primary"
                        @click="handleClickOnSaveColumn(columnIndex)"
                      >
                        <span>
                          {{ $t("uploadProcessStep2.form.general.saveButton") }}
                          <i class="bx bx-check text-success bx-tada"></i>
                        </span>
                      </b-button>
                      <div class="mt-3" v-else>
                        <i class='mr-2 bx bx-loader-circle bx-spin' ></i> {{$t("uploadProcessStep2.form.general.validationInProgress")}}
                      </div>
                      <div v-if="column.validationError.displayServerError" class="text-danger mt-3 ml-3">
                        {{ getTransationByErrorCode(errorCodesEnum.SERVER_ERROR) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- Add Column button -->
      <div class="row justify-content-center mb-5">
        <div class="col-md-12">
          <b-button
            variant="success"
            class="btn-block p-3"
            @click="addNewColumnButton()"
            :disabled="disableAddNewColumnButton"
          >
            {{ $t("uploadProcessStep2.form.general.addColumnButton") }}
            <i class="bx bx-plus-medical bx-tada ml-1"></i>
          </b-button>
        </div>
      </div>

      <!--Button Container -->
      <div
        class="fixed-bottom static-buttons"
        v-bind:class="[isExpanded ? 'expanded' : 'collapsed']"
      >
        <div class="card-body col-md-12 row">
          <div class="col-md-2 text-left">
            <b-button
              variant="primary"
              @click="clickedBackButton()"
              class="mr-2"
              :disabled="disableAddNewColumnButton"
            >
              <i class="mdi mdi-chevron-left"></i>
              {{ $t("uploadProcessStep2.form.general.backButton") }}
            </b-button>
          </div>
          <div class="col-md-8">
          </div>
          <div class="col-md-2 text-right">
            <b-button
              variant="primary"
              @click="preConditionForNextButton(true)"
              class="mr-2"
              :disabled="disableNextStepButton || disableAddNewColumnButton"
            >
              {{ $t("uploadProcessStep2.form.general.nextStepButton") }}
              <i class="mdi mdi-chevron-right"></i>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
input:disabled {
  background-color: #f5f5f5;
}

.pointer {
  cursor: pointer;
}

.answer-card {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
}

.qs-label {
  border: solid 0.08em #eee;
  border-radius: 5px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
}

.qs-success-label {
  border: solid 0.08em #eee;
  border-radius: 5px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  color: white;
  background-color: #34c38f;
}

.static-buttons {
  z-index: 1;
  margin: 0;
  background-color: white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.collapsed {
  padding-left: 75px;
}

.expanded {
  padding-left: 250px;
}
</style>
