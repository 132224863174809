const ColumnTypes = {
  PROJECTIVE_QUESTION: "PROJECTIVE_QUESTION",
  RESPONDENT_ID: "RESPONDENT_ID",
  WEIGHTING_FACTOR: "WEIGHTING_FACTOR",
  FILTER: "FILTER",
  OPEN_ENDED: "OPEN_ENDED",
  TIMESTAMP: "TIMESTAMP",
  GROUP_SELECTOR: "GROUP_SELECTOR",
  ITEMBATTERY: "ITEMBATTERY",
  NONE: "NONE"
};

export default ColumnTypes;
